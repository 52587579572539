<template>
  <div>
    <div v-for="wallet of injectedWallets" :key="wallet.name"
         class="py-2 border-bottom bg-hover cursor-pointer d-flex flex-row align-items-center justify-content-between"
         @click="onClickInjectedWallet(wallet)">
      <div class="flex-shrink-0 mr-3">
        <img :src="wallet.icon" style="width: 28px; height: 28px; object-fit: contain;" :alt="wallet.displayName">
      </div>
      <div class="flex-grow-1 min-width-0">
        <div class="text-secondary overflow-hidden text-overflow-ellipsis">
          {{ wallet.label }}
        </div>
        <div v-if="wallet.address" class="text-monospace text-break">{{ wallet.address }}</div>
        <div v-else>
          <span class="text-primary">Connect</span>
        </div>
      </div>
      <div class="flex-shrink-0">

      </div>
    </div>
    <div v-for="wallet of privateKeyWallets" :key="wallet.address"
         class="py-2 border-bottom bg-hover cursor-pointer d-flex flex-row align-items-center justify-content-between"
         @click="pickWallet(wallet)">
      <div class="flex-grow-1 min-width-0">
        <div class="text-secondary overflow-hidden text-overflow-ellipsis">
          {{ wallet.label }}
        </div>
        <div class="text-monospace text-break">{{ wallet.address }}</div>
      </div>
      <div class="flex-shrink-0">

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as web3Service from "@/services/web3Service";
  import * as constants from "@/constants";

  export default {
    name: "ChangeWalletModal",
    props: {
      modalId: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: false
      }
    },
    emits: ["pickWallet"],
    data() {
      return {
        injectedWallets: [],
        privateKeyWallets: [],
      };
    },
    mounted() {
      this.privateKeyWallets = privateKeyWalletService.getWallets().filter(wallet => wallet.type === "solana");
      this.fillInInjectedWalletAddress();
    },
    methods: {
      async fillInInjectedWalletAddress() {
        const injectedProviders = web3Service.getAllSolanaInjectedProviders();
        for (const name in injectedProviders) {
          const provider = injectedProviders[name];
          if (!provider.publicKey && !provider.isConnected && ["phantom", "backpack", "okx"].includes(name)) {
            try {
              await provider.connect({ onlyIfTrusted: true });
            } catch (e) {}
          }
          this.injectedWallets.push({
            injected: true,
            name,
            label: constants.walletNames[name],
            icon: constants.walletIcons[name],
            address: provider.publicKey?.toString(),
            provider
          });
        }
      },
      async onClickInjectedWallet(wallet) {
        if (wallet.address) {
          this.pickWallet(wallet);
        } else {
          try {
            await wallet.provider.connect();
            wallet.address = wallet.provider.publicKey.toString();
          } catch (e) {}
        }
      },
      pickWallet(wallet) {
        if (wallet.injected) {
          localStorage.setItem("solanaInjectedWallet", wallet.name);
        }
        this.$emit("pickWallet", wallet);
      }
    }
  };

</script>
