<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Label">
      <b-form-input type="text" v-model="label" minlength="0" maxlength="200"></b-form-input>
    </b-form-group>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";

  export default {
    name: "EditPrivateKeyWalletLabelModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "wallet"],
    emits: ["done"],

    data() {
      return {
        label: "",
      };
    },

    mounted() {
      this.label = this.wallet.label;
    },

    methods: {
      beforeSubmit() {
        this.label = this.label.trim();
      },

      submitForm() {
        privateKeyWalletService.editWalletLabel(this.wallet.type, this.wallet.address, this.label);
        this.$bvModal.hide(this.modalId);
        this.done();
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
