<template>
  <div class="mb-3 text-info border border-info d-flex flex-row align-items-center px-1 py-1">
    <div class="flex-grow-1 text-break">
      <span v-if="status === 'Landed'">Bundle landed successfully </span>
      <span v-else-if="status === 'Failed'" class="text-danger text-break">{{ errorMsg }} </span>
      <span v-else>
        <b-spinner small /> Processing bundle...
      </span>
      ·
      <a :href="'https://explorer.jito.wtf/bundle/'+bundleId" target="_blank">View bundle</a>
      ·
      <a :href="'https://solscan.io/tx/'+confirmationStrategy.signature" target="_blank">View tx</a>
    </div>
    <div class="cursor-pointer p-1" @click="onClickClose"><b-icon-x-circle/></div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import * as jitoBundleService from "@/services/jitoBundleService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import type {TransactionConfirmationStrategy} from "@solana/web3.js";

  export default {
    name: "JitoBundleStatusBanner",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["bundleId", "confirmationStrategy"],
    emits: ["onClickClose"],

    data() {
      return {
        errorMsg: "",
        status: "",
        isDestroyed: false,
      };
    },

    mounted() {
      this.process();
    },

    methods: {
      async process() {
        while (!this.isDestroyed && !["Landed", "Failed"].includes(this.status)) {
          try {
            this.status = await jitoBundleService.getBundleStatus(this.bundleId);
            if (this.status === "Failed") {
              this.errorMsg = "Bundle failed";
            } else if (this.status !== "Landed") {
              const bundleError = await jitoBundleService.getBundleError(this.bundleId);
              if (bundleError) {
                this.status = "Failed";
                this.errorMsg = bundleError.errorDetails;
              }
            }

          } catch (e) {

          }
        }
      },

      onClickClose() {
        this.$emit("onClickClose", this.status);
      },
    },

    unmounted() {
      this.isDestroyed = true;
      console.log("SolanaTxStatusBanner destroy");
    }
  };
</script>
