<template>
  <Header></Header>
  <RouterView/>
  <Footer></Footer>

  <b-modal :id="loadingModalId"
           hide-header hide-footer
           centered size="sm" dialog-class="loading-modal-dialog"
           no-close-on-esc no-close-on-backdrop
           no-fade>
    <div class="py-3 text-center">
      <b-spinner variant="light" style="width: 3rem; height: 3rem;"/>
    </div>
  </b-modal>
</template>

<style lang="scss">

</style>

<script setup lang="ts">
  import { RouterLink, RouterView } from 'vue-router';
  import { ref, reactive, onMounted, provide, getCurrentInstance } from 'vue';
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import _ from "lodash";

  const loadingModalId = "modal-loading";
  const vm = getCurrentInstance().proxy;

  function toastError(e) {
    const message = e.response?.data?.message || e.message;
    vm.$bvToast.toast(message, {
      toaster: "b-toaster-bottom-right",
      title: "Error",
      autoHideDelay: 7500,
      variant: "danger",
      headerClass: "bg-danger text-white"
    });
  }

  function toastSuccess(title: string, message: string) {
    return vm.$bvToast.toast(message, {
      toaster: "b-toaster-bottom-right",
      title,
      autoHideDelay: 7500,
      variant: "success",
      headerClass: "bg-info text-white"
    });
  }

  function toastSuccessDelay(message: string, title?: string, delay?: number) {
    title = title || "Alert";
    if (_.isNil(delay)) delay = 300;

    return setTimeout(() => toastSuccess(title, message), delay);
  }

  function showLoading() {
    vm.$bvModal.show(loadingModalId);
  }

  function hideLoading() {
    vm.$bvModal.hide(loadingModalId);
  }

  provide("toastError", toastError);
  provide("toastSuccess", toastSuccess);
  provide("toastSuccessDelay", toastSuccessDelay);
  provide("showLoading", showLoading);
  provide("hideLoading", hideLoading);

</script>
