<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Label">
      <b-form-input type="text" v-model="formInput.label" minlength="0" maxlength="100"></b-form-input>
    </b-form-group>
    <b-form-group label="Address">
      <b-form-input type="text" class="text-monospace" v-model="formInput.address" minlength="0" maxlength="200" required></b-form-input>
    </b-form-group>
    <b-form-group label="Memo/tag" v-if="shouldShowMemoField">
      <b-form-input type="text" class="text-monospace" v-model="formInput.memo" minlength="0" maxlength="200"></b-form-input>
    </b-form-group>
    <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as savedWalletsService from "@/services/savedWalletsService";

  export default {
    name: "SaveWalletModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "prefillAddress", "prefillMemo", "type"],
    emits: ["done"],

    data() {
      return {
        formInput: {
          label: "",
          address: "",
          memo: "",
        },

        formErrorMsg: "",
      };
    },

    computed: {
      shouldShowMemoField() {
        return !this.type || this.type === "all";
      }
    },

    mounted() {
      this.formInput.address = this.prefillAddress;
      this.formInput.memo = this.prefillMemo;
    },

    methods: {
      beforeSubmit() {
        for (const k in this.formInput) {
          this.formInput[k] = this.formInput[k].trim();
        }
      },

      submitForm() {
        try {
          savedWalletsService.addSavedWallet2(this.formInput, this.type);
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.message;
        }
      },

      done() {
        this.$emit("done", this.formInput);
      }
    }
  };
</script>
