import { ref, reactive, computed, watch } from "vue";
import { init, useOnboard } from "@web3-onboard/vue";
import injectedModule from "@web3-onboard/injected-wallets";
import * as constants from "@/constants";
import {ethers} from "ethers";

const injected = injectedModule();

export default init({
  appMetadata: {
    name: location.hostname,
    description: location.hostname
  },
  wallets: [injected],
  chains: Object.keys(constants.evmRpcUrlByChain).map(chainId => ({
    id: +chainId,
    rpcUrl: constants.evmRpcUrlByChain[chainId],
    label: constants.chainNames[chainId],
    token: constants.nativeAssetTicker[chainId],
  })),
  connect: {
    autoConnectLastWallet: true,
  },
  disableFontDownload: true,
  accountCenter: {
    desktop: {
      enabled: false,
    },
    mobile: {
      enabled: false,
    }
  },
});


const onboardComposable = useOnboard();

export const walletAddress = computed(() => {
  const address = onboardComposable.connectedWallet.value?.accounts?.[0].address;
  return address ? ethers.getAddress(address) : "";
});

export const chainId = computed(() => +onboardComposable.connectedWallet.value?.chains?.[0].id);

export const browserProvider = computed(() => {
  const connectedWallet = onboardComposable.connectedWallet.value;
  const provider = connectedWallet?.provider;
  return provider ? new ethers.BrowserProvider(provider) : undefined;
});

export async function waitForBrowserProvider(): Promise<ethers.BrowserProvider> {
  if (browserProvider.value) {
    return browserProvider.value;
  } else {
    return new Promise(resolve => {
      const stopWatch = watch(browserProvider, value => {
        if (value) {
          stopWatch();
          resolve(value);
        }
      });
    });
  }
}
