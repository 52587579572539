<template>
  <main role="main">
    <audio ref="notifyPndAudioEl" preload="auto" src="/sound/oneplus_meet.mp3"></audio>
    <audio ref="notifyNewsAudioEl" preload="auto" src="/sound/percussion.mp3"></audio>
    <div class="text-center border-bottom">
      <span v-if="!canPlayAudio" class="text-danger">⚠️ Audio autoplay is disabled. Click anywhere to allow</span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between px-3 py-1 border-bottom">
      <h4 class="m-0">Feed</h4>
      <div>
        <span class="text-secondary mr-2">Status: {{ statusText }}</span>
        <b-button variant="link" class="text-decoration-none" v-b-modal:feed-filter-modal>
          <b-icon-filter/> Filters
        </b-button>
      </div>
    </div>
    <b-form-row class="m-0 overflow-hidden" style="height: calc(100vh - 104px);">
      <b-col lg="6" class="h-100 border-right p-0" style="overflow-y: scroll;">
        <div v-if="pndFeedItems.length === 0" class="text-center text-secondary py-5">
          PND items will appear here
        </div>
        <div v-for="{data, stubId, displayDateTime, displayDateTimeFull, highlight} in pndFeedItems" :key="stubId"
             class="px-3 py-2 border-bottom" :class="{ 'bg-highlight': highlight }">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <div>
              <img :src="data.exchangeLogo" style="width: 24px; height: 24px;" class="mr-1" :alt="data.exchangeName">
              <span class="mr-2">{{ data.exchangeName }}</span>
              <a :href="data.webTradeUrl" target="_blank">{{ data.baseAsset.toUpperCase() }}<template v-if="data.exchange === 'binance'"> / {{ data.quoteAsset }}</template></a>
              <span :class="{ 'text-danger': data.diffPercentage < 0, 'text-success': data.diffPercentage > 0 }">
                {{ data.diffPercentage.toFixed(2) }}%
              </span>
              <span>{{ data.directionText }}</span>
            </div>
            <div>
              <small class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</small>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div style="min-width: 200px;" class="mr-3">
              <span class="text-secondary">Last: </span>
              <span class="text-monospace">{{ data.displayPrice }}</span>
            </div>
            <div>
              <span class="text-secondary">Vol 5m: </span>
              <span class="text-monospace">{{ data.display5mVol }}</span>
            </div>
          </div>
          <div style="white-space: pre-wrap;">{{ data.depositWithdrawStatusText }}</div>
        </div>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block h-100 border-right p-0" style="overflow-y: scroll;">
        <div v-if="rightColItems.length === 0" class="text-center text-secondary py-5">
          Other CEX updates will appear here
        </div>
        <div v-for="{event, data, stubId, displayDateTime, displayDateTimeFull, highlight} in rightColItems" :key="stubId"
             class="d-flex flex-row px-3 py-2 border-bottom" :class="{ 'bg-highlight': highlight }">
          <div class="flex-shrink-0 mr-2">
            <img :src="data.exchangeLogo" style="width: 36px; height: 36px;" :alt="data.exchangeName">
          </div>
          <div class="flex-grow-1">
            <template v-if="event === 'cexAnnouncement'">
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div class="text-secondary">{{ data.exchangeName }} annoucement</div>
                <div><small class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</small></div>
              </div>
              <div><a :href="data.url" target="_blank">{{ data.title }}</a></div>
            </template>
            <template v-if="event === 'newCexAsset'">
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span class="text-secondary">New coin: </span>
                  {{ data.asset.toUpperCase() }}
                  <span class="text-secondary">on </span>
                  {{ data.exchangeName }}
                </div>
                <div><small class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</small></div>
              </div>
              <div v-if="data.name && data.name.toLowerCase() !== data.asset.toLowerCase()">
                <span class="text-secondary">Name: </span> {{ data.name }}
              </div>
              <div v-if="data.networksText">
                <span class="text-secondary">Networks: </span> {{ data.networksText }}
              </div>
              <template v-if="data.possibleMatches && data.possibleMatches.length">
                <div>Possible matches:</div>
                <div v-for="(match, i) in data.possibleMatches" :key="match.source + ':' + match.id">
                  <span class="text-secondary">{{ i + 1 }}. </span>
                  <template v-if="match.source === 'coingecko'">
                    <a :href="'https://www.coingecko.com/en/coins/' + match.id" target="_blank">{{ match.name }}</a>
                    <span class="text-secondary"> (coingecko)</span>
                  </template>
                  <template v-if="match.source === 'coinmarketcap'">
                    <a :href="'https://coinmarketcap.com/currencies/' + match.id" target="_blank">{{ match.name }}</a>
                    <span class="text-secondary"> (coinmarketcap)</span>
                  </template>
                </div>
              </template>
            </template>
            <template v-if="event === 'dwStatusChange'">
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div class="text-secondary">{{ data.exchangeName }} deposit/withdraw status change</div>
                <div><small class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</small></div>
              </div>
              <div v-for="assetObj in data.diffsByAssetArr" :key="assetObj.asset">
                <div>{{ assetObj.asset }}</div>
                <div v-for="diff in assetObj.diffs" :key="diff.network + '-' + diff.direction">
                  {{ diff.network }}
                  <span class="text-secondary">{{ diff.direction }}: </span>
                  <template v-if="diff.enabled">✅ enabled</template>
                  <template v-else>🚫 disabled</template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-col>
    </b-form-row>
<!--    <b-form-row class="px-3 py-2 border-bottom">
      <b-col cols="1">Ts</b-col>
      <b-col cols="2">Market</b-col>
      <b-col cols="1" class="text-right">Change</b-col>
      <b-col cols="2" class="text-right">Last</b-col>
      <b-col cols="2" class="text-right pr-4">Vol 5m</b-col>
      <b-col cols="3">Networks</b-col>
      <b-col cols="1">Actions</b-col>
    </b-form-row>
    <b-form-row v-for="{data, stubId, displayDateTime, displayDateTimeFull} in pndFeedItems" :key="stubId" class="px-3 py-2 border-bottom">
      <b-col cols="1">
        <span class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</span>
      </b-col>
      <b-col cols="2">
        <img :src="data.exchangeLogo" style="width: 24px; height: 24px;" class="mr-1" :alt="data.exchangeName">
        <span class="mr-2">{{ data.exchangeName }}</span>
        <a :href="data.webTradeUrl" target="_blank">{{ data.baseAsset.toUpperCase() }}<template v-if="data.exchange === 'binance'"> / {{ data.quoteAsset }}</template></a>
      </b-col>
      <b-col cols="1" class="text-right">

      </b-col>
      <b-col cols="2" class="text-right">
        <span class="text-monospace">{{ data.displayPrice }}</span>
      </b-col>
      <b-col cols="2" class="text-right pr-4">
        <span class="text-monospace">{{ data.display5mVol }}</span>
      </b-col>
      <b-col cols="3">
        <small style="white-space: pre-wrap;">{{ data.depositWithdrawStatusText }}</small>
      </b-col>
    </b-form-row>-->
<!--    <div v-for="{event, data, stubId, displayDateTime, displayDateTimeFull} in pndFeedItems" :key="stubId"
         class="px-3 py-2 border-bottom d-flex flex-row align-items-center">
      <div class="flex-shrink-0">
        <b-badge class="mr-2">{{ event }}</b-badge>
      </div>
      <div class="flex-grow-1">
        <template v-if="event === 'pnd'">
          <img :src="data.exchangeLogo" style="width: 24px; height: 24px;" class="mr-1" :alt="data.exchangeName">
          <span class="mr-2">{{ data.exchangeName }}</span>
          <span v-html="data.text"></span>
        </template>
      </div>
      <div class="flex-shrink-0">
        <span class="text-secondary" v-b-tooltip.hover :title="displayDateTimeFull">{{ displayDateTime }}</span>
      </div>
    </div>-->
    <b-modal id="feed-filter-modal" title="Filters" size="lg" hide-footer no-fade>
      <FeedSettingsModal :feed-settings="feedSettings" modal-id="feed-filter-modal" @done="doneChangeSettings"/>
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>
.bg-highlight {
  background-color: lightyellow;
}
</style>

<script lang="ts">
  import _ from "lodash";
  import io, {Socket} from "socket.io-client";
  import * as authService from "@/services/authService";
  import * as constants from "@/constants";
  import FeedSettingsModal from "@/components/FeedSettingsModal.vue";
  import {DateTime} from "luxon";
  import BigNumber from "bignumber.js";

  const maxFeedArrayLength = 500;

  const numberFormatVolUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  const numberFormatVolBtc = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  });

  export default {
    name: "Feed",
    components: {FeedSettingsModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],

    data() {
      return {
        feedSettings: {
          pndExchanges: ["binance", "huobi", "okx", "kucoin", "mexc", "bybit", "upbit"],
          pndQuoteAssets: ["USDT", "BTC"],
          pndMinChangePercent: 5,
          pndMin5mVolume: 1000,
          cexUpdateExchanges: ["binance", "huobi", "okx", "kucoin", "mexc", "bybit"],
          cexUpdateTypes: ["cexAnnouncement", "newCexAsset", "dwStatusChange"],
        },
        canPlayAudio: true,
        socket: null as Socket | null,
        statusText: "connecting...",
        pndFeedItems: [],
        rightColItems: [],
      };
    },

    mounted() {
      document.title = "Feed";
      Notification.requestPermission();
      this.loadSettings();
      this.setupSocket();
      this.checkAudioPlayPermission();
      // this.feedTestItems();
    },

    methods: {
      checkAudioPlayPermission() {
        if (window["canPlayAudio"]) {
          this.canPlayAudio = true;
        } else {
          this.canPlayAudio = false;
          addEventListener("click", () => {
            this.canPlayAudio = true;
          }, { once: true });
        }
      },

      feedTestItems() {
        const datetime = DateTime.now();
        this.rightColItems.push(
          {
            event: "cexAnnouncement",
            data: {
              "exchange": "mexc",
              "id": "17827791510634",
              "title": "[Initial Listing]  MEXC Kickstarter - Vote SolarX(SOLARX) to Win Free 128,205 SOLARX & 25,000 USDT Airdrops!",
              "url": "https://www.mexc.com/support/articles/17827791510634",
              "alertMessageId": null,
              "datetime": 1695112217000,
              "createdAt": "2023-09-19T07:30:36.000Z"
            },
          },
          {
            event: "dwStatusChange",
            data: {
              "exchange": "binance",
              diffsByAssetArr: [
                {
                  asset: "1INCH",
                  diffs: [
                    {
                      "network": "BSC",
                      "direction": "deposit",
                      "enabled": true
                    }
                  ]
                }
              ]
            }
          },
          {
            event: "newCexAsset",
            data: {
              "exchange": "kucoin",
              "asset": "PYUSD",
              "name": "PayPal USD",
              "createdAt": "2023-09-20T08:52:25.000Z",
              "updatedAt": "2023-09-20T08:52:25.000Z",
              networksText: "eth",
              "networks": [
                {
                  "network": "eth",
                  "chainId": 1,
                  "name": "ERC20",
                  "isDefault": true,
                  "depositEnabled": false,
                  "withdrawEnabled": false,
                  "withdrawAmountStep": 0.000001,
                  "hasMemo": false,
                  "withdrawFee": 0.01,
                  "withdrawMin": 0,
                  "withdrawMax": 100000000000000000
                }
              ],
              "possibleMatches": [
                {
                  "source": "coingecko",
                  "id": "paypal-usd",
                  "symbol": "PYUSD",
                  "name": "PayPal USD"
                },
                {
                  "source": "coinmarketcap",
                  "id": "paypal-usd",
                  "symbol": "PYUSD",
                  "name": "PayPal USD"
                }
              ]
            }
          }
        );
        for (const item of this.rightColItems) {
          item.stubId = crypto.randomUUID();
          item.displayDateTime = datetime.toFormat("HH:mm:ss");
          item.displayDateTimeFull = datetime.toFormat("yyyy-MM-dd HH:mm:ss.SSS");

          const { data } = item;
          if (data.exchange) {
            data.exchangeName = constants.exchangeNames[data.exchange];
            data.exchangeLogo = constants.EXCHANGE_LOGO[data.exchange];
          }
        }
      },

      loadSettings() {
        try {
          const savedSettingsStr = localStorage.getItem("feed");
          if (savedSettingsStr) {
            this.feedSettings = JSON.parse(savedSettingsStr);
            if (!Array.isArray(this.feedSettings.cexUpdateExchanges)) {
              this.feedSettings.cexUpdateExchanges = ["binance", "huobi", "okx", "kucoin", "mexc", "bybit"];
            }
            if (!Array.isArray(this.feedSettings.cexUpdateTypes)) {
              this.feedSettings.cexUpdateTypes = ["cexAnnouncement", "newCexAsset", "dwStatusChange"];
            }
          }
        } catch (e) {}
      },

      setupSocket() {
        this.socket = io("https://arb-trader.coinmatic.app/feed", {
          transports: ["websocket"]
        });
        this.socket.on("connect", async () => {
          const auth = await authService.refreshAuthIfNecessary();
          this.socket.emit("authentication", auth.message, auth.signature);
        });
        this.socket.on("disconnect", () => {
          this.statusText = "disconnected";
        });
        this.socket.onAny((event, data) => {
          if (event === "authentication" && typeof data === "object") {
            this.statusText = "connected";
          }
          if (data.exchange) {
            data.exchangeName = constants.exchangeNames[data.exchange];
            data.exchangeLogo = constants.EXCHANGE_LOGO[data.exchange];
          }
          if (event === "pnd") {
            if (this.shouldShowPndItem(data)) {
              this.$refs.notifyPndAudioEl.play();
              if (data.exchange === "upbit") {
                data.displayPrice = BigNumber(data.last).precision(6).toFixed();
              } else {
                data.displayPrice = BigNumber(data.last).toFixed();
              }
              data.display5mVol = numberFormatVolUsd.format(data.totalQuoteVol);

              let directionText = "";
              if (data.diffPercentage > 0) {
                directionText = "💹";
              } else if (data.diffPercentage < 0) {
                directionText = "🔻";
              }
              directionText = _.repeat(directionText, Math.ceil(Math.abs(data.diffPercentage) / 10));
              data.directionText = directionText;

              data.depositWithdrawStatusText = data.networks?.map(chainDetails => {
                const {network, depositEnabled, withdrawEnabled} = chainDetails;
                let statusText = "deposit + withdraw";

                if (depositEnabled && withdrawEnabled) {
                  statusText = "deposit + withdraw";
                } else if (depositEnabled && !withdrawEnabled) {
                  statusText = "⚠️ deposit only";
                } else if (!depositEnabled && withdrawEnabled) {
                  statusText = "⚠️ withdraw only";
                } else if (!depositEnabled && !withdrawEnabled) {
                  statusText = "🚫 disabled";
                }

                return `${network}: ${statusText}`;
              }).join("\n");

              const datetime = DateTime.now();
              const item = {
                event,
                data,
                stubId: crypto.randomUUID(),
                displayDateTime: datetime.toFormat("HH:mm:ss"),
                displayDateTimeFull: datetime.toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                highlight: true
              };
              this.pndFeedItems.unshift(item);
              this.deleteOverflowItems();

              setTimeout(() => { item.highlight = false; }, 15000);

              // prepare notification
              const doc = new DOMParser().parseFromString(data.text,"text/html");
              const notificationText = doc.body.textContent.replace(/\s+/g, " ").trim();
              const notification = new Notification(`${constants.exchangeNames[data.exchange]} PND`, {
                body: notificationText,
                icon: data.exchangeLogo
              });
              notification.addEventListener("click", event => {
                if (data.webTradeUrl) {
                  event.preventDefault();
                  open(data.webTradeUrl, "_blank");
                }
              });
            }

          } else {
            if (this.shouldShowCexUpdateItem(event, data)) {
              if (event === "cexAnnouncement") {
                this.$refs.notifyNewsAudioEl.play();
                const datetime = DateTime.fromISO(data.createdAt);
                const item = {
                  event,
                  data,
                  stubId: crypto.randomUUID(),
                  displayDateTime: datetime.toFormat("HH:mm:ss"),
                  displayDateTimeFull: datetime.toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                  highlight: true
                };
                this.rightColItems.unshift(item);
                this.deleteOverflowItems();

                setTimeout(() => { item.highlight = false; }, 60000);

                const notification = new Notification(`${data.exchangeName} announcement`, {
                  body: data.title,
                  icon: data.exchangeLogo,
                });
                notification.addEventListener("click", event => {
                  if (data.url) {
                    event.preventDefault();
                    open(data.url, "_blank");
                  }
                });

              } else if (event === "newCexAsset") {
                this.$refs.notifyNewsAudioEl.play();
                if (Array.isArray(data.networks)) {
                  data.networksText = data.networks.map(networkObj => networkObj.network).join(", ");
                }
                const datetime = DateTime.fromISO(data.createdAt);
                const item = {
                  event,
                  data,
                  stubId: crypto.randomUUID(),
                  displayDateTime: datetime.toFormat("HH:mm:ss"),
                  displayDateTimeFull: datetime.toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                  highlight: true
                };
                this.rightColItems.unshift(item);
                this.deleteOverflowItems();

                setTimeout(() => { item.highlight = false; }, 60000);

                // prepare notification
                const messageTextLines = [];
                if (data.name?.toLowerCase() !== data.asset.toLowerCase()) {
                  messageTextLines.push("Name: " + data.name);
                }
                if (data.networksText) {
                  messageTextLines.push("Networks: " + data.networksText);
                }
                if (data.possibleMatches?.length) {
                  messageTextLines.push("Possible matches:");
                  for (let i = 0; i < data.possibleMatches.length; i++) {
                    const match = data.possibleMatches[i];
                    messageTextLines.push(`${i+1}. ${match.name} (${match.source})`);
                  }
                }
                const messageText = messageTextLines.join("\n");
                const notification = new Notification(`New coin: ${data.asset.toUpperCase()} on ${data.exchangeName}`, {
                  body: messageText,
                  icon: data.exchangeLogo
                });

              } else if (event === "dwStatusChange") {
                this.$refs.notifyNewsAudioEl.play();
                const diffsByAsset = _.groupBy(data.diffs, "asset");
                data.diffsByAssetArr = Object.keys(diffsByAsset).map(asset => ({
                  asset,
                  diffs: diffsByAsset[asset]
                }));

                const datetime = DateTime.now();
                const item = {
                  event,
                  data,
                  stubId: crypto.randomUUID(),
                  displayDateTime: datetime.toFormat("HH:mm:ss"),
                  displayDateTimeFull: datetime.toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                  highlight: true
                };
                this.rightColItems.unshift(item);
                this.deleteOverflowItems();

                setTimeout(() => { item.highlight = false; }, 60000);

                // prepare notification
                const messageTextLines = [];
                for (const assetObj of data.diffsByAssetArr) {
                  messageTextLines.push(assetObj.asset);
                  for (const diff of assetObj.diffs) {
                    messageTextLines.push(`${diff.network} ${diff.direction}: ${diff.enabled ? "✅enabled" : "🚫disabled"}`);
                  }
                  messageTextLines.push(``);
                }
                const messageText = messageTextLines.join("\n").trim();
                const notification = new Notification(`${data.exchangeName} deposit/withdraw status change`, {
                  body: messageText,
                  icon: data.exchangeLogo
                });
              }
            }
          }
        });
      },

      shouldShowPndItem(data) {
        if (!this.feedSettings.pndExchanges.includes(data.exchange)) return false;
        if (data.exchange === "binance" && !this.feedSettings.pndQuoteAssets.includes(data.quoteAsset)) return false;
        if (Math.abs(data.diffPercentage) < this.feedSettings.pndMinChangePercent) return false;
        if (data.totalQuoteVol < this.feedSettings.pndMin5mVolume) return false;

        return true;
      },

      shouldShowCexUpdateItem(event, data) {
        if (!this.feedSettings.cexUpdateTypes.includes(event)) return false;
        if (!this.feedSettings.cexUpdateExchanges.includes(data.exchange)) return false;

        return true;
      },

      deleteOverflowItems() {
        const arrays = [this.pndFeedItems, this.rightColItems];
        for (const array of arrays) {
          const feedItemsOverflowCount = array.length - maxFeedArrayLength;
          if (feedItemsOverflowCount > 0) {
            array.splice(maxFeedArrayLength, feedItemsOverflowCount);
          }
        }
      },

      doneChangeSettings() {
        this.loadSettings();
      }
    },

    unmounted() {
      this.socket?.removeAllListeners();
      this.socket?.close();
    }
  };
</script>
