<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Account</b-col>
      <b-col cols="8">
        <b-form-select :options="cexAccountOptions" v-model="cexAccountId" required @change="onSelectAccount"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Market</b-col>
      <b-col cols="8">
        <v-select class="vs-normalizer" :hidden="!!selectedMarket"
                  :getOptionKey="m => m.symbol" label="symbol"
                  :filter="marketSelectDropdownFilter"
                  :options="cexMarkets"
                  :clearSearchOnBlur="() => false"
                  v-on:option:selecting="onPickMarket">
          <template v-slot:search="{attributes, events}">
            <input v-bind="attributes" v-on="events"
                   :value="searchMarket"
                   @input="searchMarket = $event.target.value"
                   class="form-control" autocomplete="off" minlength="2" maxlength="100"/>
          </template>
          <template v-slot:option="{ baseAsset, quoteAsset }">
            <div class="py-1">{{ baseAsset.toUpperCase() }} / {{ quoteAsset.toUpperCase() }}</div>
          </template>
        </v-select>
        <div v-if="selectedMarket" class="border rounded d-flex flex-row align-items-center" style="padding: 6px 12px;">
          <div class="flex-grow-1 min-width-0">
            {{ selectedMarket.baseAsset.toUpperCase() }} / {{ selectedMarket.quoteAsset.toUpperCase() }}
          </div>
          <div class="flex-shrink-0 text-secondary cursor-pointer" @click="clearSelectedMarket">
            <b-icon-x-circle/>
          </div>
        </div>
        <div class="small text-secondary" style="white-space: pre">{{ baseAssetInfoText }}</div>
      </b-col>
    </b-row>
<!--    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Trigger balance</b-col>
      <b-col cols="8">
        <b-input-group>
          <b-form-input type="number" required :min="triggerBalanceMin" :step="triggerBalanceStep" v-model="triggerBalance" autocomplete="off"></b-form-input>
          <b-input-group-append v-if="selectedMarket">
            <b-input-group-text>{{ selectedMarket.baseAsset }}</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
        <b-form-text>Place <span class="text-danger">SELL</span> order when available balance exceeds this amount</b-form-text>
      </b-col>
    </b-row>-->
    <b-row class="mb-3">
      <b-col cols="4" class="text-right">Type</b-col>
      <b-col cols="8">
        <b-form-radio-group :options="typeOptions" v-model="type"></b-form-radio-group>
        <b-form-text v-if="selectedMarket">
          Place <span class="text-danger">{{ type.toUpperCase() }} SELL</span> order when
          <strong>{{ selectedMarket.baseAsset.toUpperCase() }}</strong>
          available balance exceeds
          <strong>{{ displayMinNotional }}</strong>
          equivalent
        </b-form-text>
      </b-col>
    </b-row>
<!--    <b-row class="mb-3">
      <b-col cols="4" class="text-right">Side</b-col>
      <b-col cols="8">
        <b-form-radio-group :options="sideOptions" v-model="side"></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="4" class="text-right">Amount</b-col>
      <b-col cols="8">
        <b-form-radio-group :options="amountTypeOptions" v-model="amountType"></b-form-radio-group>
      </b-col>
    </b-row>-->
    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Price</b-col>
      <b-col cols="8">
        <b-input-group>
          <b-form-input v-if="type === 'limit'" type="number" required :min="priceMin" :step="priceStep" v-model="price" autocomplete="off"></b-form-input>
          <b-form-input v-if="type === 'market'" type="text" value="market price" readonly></b-form-input>
          <b-input-group-append v-if="selectedMarket">
            <b-input-group-text>{{ selectedMarket.quoteAsset.toUpperCase() }}</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Timeout</b-col>
      <b-col cols="8">
        <b-input-group>
          <b-form-input type="number" required min="1" max="1440" step="0.1" v-model="timeoutMins" />
          <b-input-group-append >
            <b-input-group-text>minutes</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div v-if="formErrorMsg" class="text-danger text-center">{{ formErrorMsg }}</div>
    <div class="text-center mt-4">
      <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Place order</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as marketDataService from "@/services/marketDataService";
  import * as cexBalanceWatchOrderService from "@/services/cexBalanceWatchOrderService";
  import * as utils from "@/utils";
  import BigNumber from "bignumber.js";

  export default {
    name: "NewCexBalanceWatchOrderModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "p_evmAddress", "p_baseAsset", "p_quoteAsset"],
    emits: ["done"],

    data() {
      return {
        cexAccounts: [],
        cexAccountOptions: [
          { value: 0, text: "Loading accounts...", disabled: true }
        ],
        cexAccountId: 0,

        cexMarkets: [],
        searchMarket: "",
        selectedMarket: null,

        baseAssetDetails: null,

        // triggerBalance: 0,

        typeOptions: [
          { value: "limit", text: "Limit" },
          { value: "market", text: "Market" },
        ],
        type: "limit",

        /*sideOptions = [
          { value: "buy", text: "Buy" },
          { value: "sell", text: "Sell" },
        ];
        side = "sell";

        amountTypeOptions = [
          { value: "percentage", text: "Percentage" },
          { value: "fixed", text: "Fixed" },
        ];
        amountType = "percentage";*/

        price: 0,
        timeoutMins: 15,

        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    computed: {
      baseAssetInfoText() {
        if (this.baseAssetDetails) {
          return this.baseAssetDetails.networks.map(chain => {
            const { network, name, depositEnabled, withdrawEnabled } = chain;
            let statusText = "deposit + withdraw";

            if (depositEnabled && withdrawEnabled) {
              statusText = "deposit + withdraw";
            } else if (depositEnabled && !withdrawEnabled) {
              statusText = "⚠️ deposit only";
            } else if (!depositEnabled && withdrawEnabled) {
              statusText = "⚠️ withdraw only";
            } else if (!depositEnabled && !withdrawEnabled) {
              statusText = "🚫 disabled";
            }

            let displayNetworkName = network;
            if (this.baseAssetDetails.exchange === "huobi") {
              displayNetworkName = name;
            }

            return `${displayNetworkName}: ${statusText}`;
          }).join("\n");
        }
        return undefined;
      },

      triggerBalanceMin() {
        return this.selectedMarket?.amountStep || 1;
      },

      triggerBalanceStep() {
        return this.selectedMarket?.amountStep || 1;
      },

      displayMinNotional() {
        const mul = this.type === "limit" ? 1 : 1.5;
        const amountStr = BigNumber(this.selectedMarket.minNotional).multipliedBy(mul).toFixed();
        return `${amountStr} ${this.selectedMarket.quoteAsset.toUpperCase()}`;
      },

      priceMin() {
        return this.selectedMarket?.priceStep || 0;
      },

      priceStep() {
        return this.selectedMarket?.priceStep || 0.01;
      },
    },

    mounted() {
      this.setup();
    },

    methods: {
      async setup() {
        this.loadSettings();

        this.cexAccounts = await cexAccountService.getAll();
        this.cexAccountOptions = this.cexAccounts.map(it => ({
          value: it.id,
          text: it.label || utils.getShortenedAddress(it.evmAddress),
          disabled: false
        }));

        if (!this.cexAccountOptions.length) {
          this.cexAccountOptions = [
            { value: null, text: "No accounts", disabled: true }
          ];
        } else {
          if (this.p_evmAddress) {
            this.cexAccountId = 0; // previously assigned from saved settings, need to reset it
            const matchAcc = this.cexAccounts.find(it =>
              it.id.toString() === this.p_evmAddress ||
              it.evmAddress.toLowerCase() === this.p_evmAddress.toLowerCase()
            );
            if (matchAcc) {
              this.cexAccountId = matchAcc.id;
            }
          } else if (!this.cexAccountId) {
            this.cexAccountId = this.cexAccountOptions[0].value;
          }
        }

        this.onSelectAccount();
      },

      async onSelectAccount() {
        // reset value
        this.cexMarkets = [];
        this.clearSelectedMarket();

        if (this.cexAccountId) {
          const cexAccount = this.cexAccounts.find(it => it.id === this.cexAccountId);
          this.cexMarkets = await marketDataService.getCexMarkets({ exchange: cexAccount.exchange });
          if (this.p_baseAsset) {
            const tryQuoteAssets = ["USDT", "USDC", "FDUSD", "BTC", "ETH", "BNB"];
            if (this.p_quoteAsset) {
              tryQuoteAssets.unshift(this.p_quoteAsset);
            }

            for (const tryQuoteAsset of tryQuoteAssets) {
              const matchMarket = this.cexMarkets.find(it =>
                it.baseAsset.toUpperCase() === this.p_baseAsset.toUpperCase() &&
                it.quoteAsset.toUpperCase() === tryQuoteAsset.toUpperCase()
              );
              if (matchMarket) {
                this.selectedMarket = matchMarket;
                this.fetchBaseAssetDetails();
                break;
              }
            }
          }
        }
      },

      marketSelectDropdownFilter(options: any[], search: string) {
        if (!search) return [];
        search = utils.sanitizeSearchText(search).toLowerCase().replace(/[^a-z]/g, "");
        return options.filter(market => market.symbol.toLowerCase().replace(/[^a-z]/g, "").startsWith(search));
      },

      onPickMarket(market: any) {
        this.selectedMarket = market;
        this.fetchBaseAssetDetails();
      },

      clearSelectedMarket() {
        this.searchMarket = "";
        this.selectedMarket = null;
        this.baseAssetDetails = null;
      },

      async fetchBaseAssetDetails() {
        const fetchCexAsset = marketDataService.getCexAssets({
          exchange: this.selectedMarket.exchange,
          asset: this.selectedMarket.baseAsset
        });
        const fetchTicker = marketDataService.getTickers(this.selectedMarket.exchange, this.selectedMarket.symbol);

        this.baseAssetDetails = (await fetchCexAsset)[0];
        this.price = (await fetchTicker)[0].close;
      },

      beforeSubmit() {

      },

      async submitForm() {
        if (!this.selectedMarket) {
          this.formErrorMsg = "Please select market";
          return;
        }

        try {
          this.formErrorMsg = "";
          this.isSubmitting = true;
          await cexBalanceWatchOrderService.create({
            cexAccountId: this.cexAccountId,
            asset: this.selectedMarket.baseAsset,
            // triggerBalance: +this.triggerBalance,
            symbol: this.selectedMarket.symbol,
            type: this.type,
            price: this.type === "limit"? this.price : undefined,
            timeoutMins: +this.timeoutMins
          });
          this.$bvModal.hide(this.modalId);
          this.done();
          this.toastSuccessDelay("Order placed");

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
          this.saveSettings();
        }
      },

      loadSettings() {
        try {
          const settings = JSON.parse(localStorage.getItem("cexBalanceWatchOrder"));
          this.cexAccountId = settings.cexAccountId;
          this.type = settings.type;
          this.timeoutMins = settings.timeoutMins;

        } catch (e) {

        }
      },

      saveSettings() {
        localStorage.setItem("cexBalanceWatchOrder", JSON.stringify({
          cexAccountId: this.cexAccountId,
          timeoutMins: +this.timeoutMins,
          type: this.type
        }));
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
