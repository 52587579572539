<template>
  <div>
    <div v-for="savedWallet of savedWallets" :key="savedWallet.address"
         class="py-2 border-bottom bg-hover cursor-pointer d-flex flex-row align-items-center justify-content-between"
         @click="pickWallet(savedWallet)">
      <div class="flex-grow-1 min-width-0">
        <div class="text-secondary overflow-hidden text-overflow-ellipsis">{{ savedWallet.label }}</div>
        <div class="text-monospace text-break">{{ savedWallet.address }}</div>
        <div v-if="savedWallet.memo">
          <span class="text-secondary">Memo: </span>
          <span class="text-monospace">{{ savedWallet.memo }}</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <b-button variant="link" size="sm" class="text-danger" @click="onClickDelete($event, savedWallet)">
          <b-icon-trash/>
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as savedWalletsService from "@/services/savedWalletsService";

  export default {
    name: "AddressBookModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "type"],
    emits: ["pickWallet"],

    data() {
      return {
        savedWallets: [] as savedWalletsService.SavedWallet[],
      };
    },

    mounted() {
      this.savedWallets = savedWalletsService.getAllSavedWallets2(this.type);
    },

    methods: {
      onClickDelete(event: Event, savedWallet: savedWalletsService.SavedWallet) {
        console.log("AddressBookModal onClickDelete", event, savedWallet);
        event.stopPropagation();
        savedWalletsService.deleteSavedWallet(savedWallet.address, savedWallet.memo);

        this.savedWallets = savedWalletsService.getAllSavedWallets2(this.type);
      },

      pickWallet(savedWallet: savedWalletsService.SavedWallet) {
        this.$emit("pickWallet", savedWallet);
      },
    }
  };
</script>
