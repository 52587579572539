<template>
  <main role="main">
    <b-container class="py-3">
      <section class="mb-4">
        <div>Notification API</div>
        <audio ref="notifyArbitrageAudioEl" preload="auto" src="/sound/percussion.mp3"></audio>
        <div>
          <b-button variant="link" @click="requestNotificationPermission">Request permission</b-button>
        </div>
        <div>
          <b-form-checkbox class="d-inline-block mr-3" v-model="playSound">Play sound</b-form-checkbox>
          <b-form-checkbox class="d-inline-block" v-model="delaySend">Delay 5s</b-form-checkbox>
          <b-button variant="link" @click="sendTestNotification">Send test notification</b-button>
        </div>
      </section>
      <section>
        <div>Audio API</div>
        <div>
          <div class="d-inline-block" v-for="(sound, i) in sounds" :key="sound.name">
            <audio ref="soundAudioEl" preload="auto" :src="sound.path"></audio>
            <b-button variant="link" @click="$refs.soundAudioEl[i].play()">{{ sound.name }}</b-button>
          </div>
        </div>
      </section>
    </b-container>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import * as constants from "@/constants";

  export default {
    name: "Debug",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],

    data() {
      return {
        playSound: false,
        delaySend: false,
        sounds: [
          { name: "oneplus_meet", path: "/sound/oneplus_meet.mp3"},
          { name: "percussion", path: "/sound/percussion.mp3"},
        ]
      };
    },

    mounted() {
      document.title = "Debug";
    },

    methods: {
      requestNotificationPermission() {
        Notification.requestPermission();
      },

      doSendTestNotification() {
        if (this.playSound) {
          this.$refs.notifyArbitrageAudioEl.play();
        }
        const notification = new Notification("test notification", {
          icon: constants.NETWORK_LOGO[1],
          body: "The time is " + new Date().toString()
        });
        notification.addEventListener("click", () => {
          open("/");
        });
      },

      sendTestNotification() {
        if (this.delaySend) {
          setTimeout(() => this.doSendTestNotification(), 5000);
          this.toastSuccessDelay("will send after 5s");
        } else {
          this.doSendTestNotification();
        }
      },
    }
  };

</script>
