<template>
  <div>
    <b-form-group>
      <b-form-radio-group :options="accountTypeOptions" v-model="accountType" required @change="onChangeAccountType"/>
    </b-form-group>
    <template v-if="accountType === 'cex'">
      <div class="mb-3 d-flex flex-row align-items-center">
        <label class="m-0 flex-shrink-0" style="width: 80px;">Account</label>
        <b-form-select class="flex-grow-1" :options="cexAccounts" text-field="label" value-field="id" v-model="cexAccountId" @change="onSelectCexAccount" required/>
      </div>
      <div class="mb-3">
        <label class="m-0" style="width: 80px;">Type</label>
        <b-form-radio-group class="d-inline-block" :options="cexTypeOptions" v-model="cexType" @change="onSelectCexAccount" />
      </div>
      <div class="d-flex flex-row align-items-center">
        <label class="m-0 flex-shrink-0" style="width: 80px;">Market</label>
        <div class="flex-grow-1">
          <v-select class="vs-normalizer" :hidden="!!cexSymbol"
                    :getOptionKey="m => m.symbol" label="symbol"
                    :filter="cexMarketSelectDropdownFilter"
                    :options="cexMarkets"
                    :clearSearchOnBlur="() => false"
                    v-on:option:selecting="onPickCexMarket">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events"
                     class="form-control" autocomplete="off" minlength="2" maxlength="100"/>
            </template>
            <template v-slot:option="{ symbol, baseAsset, quoteAsset }">
              <div class="py-1">
                {{ cexType === "futures" ? symbol : baseAsset + " / " + quoteAsset }}
              </div>
            </template>
          </v-select>
          <div v-if="cexSymbol" class="border rounded d-flex flex-row align-items-center"
               style="padding: 6px 12px;">
            <div class="flex-grow-1 min-width-0">
              {{ cexType === "futures" ? cexSymbol : cexBaseAsset + " / " + cexQuoteAsset }}
            </div>
            <div class="flex-shrink-0 text-secondary cursor-pointer" @click="clearSelectedCexMarket">
              <b-icon-x-circle/>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <b-form-group label="Wallet">
        <b-form-select :options="walletOptions" v-model="walletAddress" required @change="onChangeWallet" />
      </b-form-group>
      <div>
        <label>Select tokens</label>
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div :id="'hedging-'+side+'-swap-input'" @click="onClickInputAsset" class="asset-btn">
            <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
              <img v-if="swapInput.logo" :src="swapInput.logo" class="w-100 h-100">
            </div>
            <div class="overflow-hidden">
              <div class="overflow-hidden text-overflow-ellipsis">{{ swapInput.symbol }}</div>
              <div class="overflow-hidden text-overflow-ellipsis text-secondary">
                <small>{{ swapInput.name }}</small>
              </div>
            </div>
          </div>
          <b-tooltip v-if="swapInput.address && !swapInput.isNative" :target="'hedging-'+side+'-swap-input'">
            <a class="small text-light" :href="getExplorerUrl(chainId, 'token', swapInput.address)" target="_blank">
              {{ swapInput.address }}
            </a>
          </b-tooltip>
          <div class="text-center" style="width: 10%;">
            <b-icon-arrow-right />
          </div>
          <div :id="'hedging-'+side+'-swap-output'" @click="onClickOutputAsset" class="asset-btn">
            <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
              <img v-if="swapOutput.logo" :src="swapOutput.logo" class="w-100 h-100">
            </div>
            <div class="overflow-hidden">
              <div class="overflow-hidden text-overflow-ellipsis">{{ swapOutput.symbol }}</div>
              <div class="overflow-hidden text-overflow-ellipsis text-secondary">
                <small>{{ swapOutput.name }}</small>
              </div>
            </div>
          </div>
          <b-tooltip v-if="swapOutput.address && !swapOutput.isNative" :target="'hedging-'+side+'-swap-output'">
            <a class="small text-light" :href="getExplorerUrl(chainId, 'token', swapOutput.address)" target="_blank">
              {{ swapOutput.address }}
            </a>
          </b-tooltip>
        </div>
      </div>
    </template>
    <b-modal :id="pickTokenModalId" title="Select a token" hide-footer no-fade no-close-on-backdrop>
      <PickTokenModal
        :chainId="chainId"
        :walletAddress="walletAddress"
        :stableCoinsOnly="pickTokenModalIsStableCoinMode"
        @pickToken="onPickToken" />
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
  .asset-btn {
    border: 1px solid #dee2e6;
    flex-shrink: 0;
    width: 45%;
    line-height: 1.1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: #e8e8e8;
    }
  }
</style>

<script lang="ts">
  import _ from "lodash";
  import * as web3Service from "@/services/web3Service";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import PickTokenModal from "@/components/PickTokenModal.vue";
  import * as customTokensService from "@/services/customTokensService";
  import * as marketDataService from "@/services/marketDataService";
  import BigNumber from "bignumber.js";

  const sampleSwapSide = {
    name: "",
    address: "",
    isNative: false,
    decimals: 0,
    symbol: "",
    logo: "",
    walletBalance: "0",
    walletDisplayBalance: "0",
    displayAmount: "",
    displayPriceUsd: "--",
    displayPriceOther: "--",
  };
  const supportExchanges = ["binance", "bybit"];
  const priceMultipleTickerPattern = /^10{3,}/;

  export default {
    name: "SetupHedgingSide",
    components: {PickTokenModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading", "getExplorerUrl", "getShortenedAddress"],
    props: ["side"],

    data() {
      return {
        accountType: "",
        accountTypeOptions: [
          // { value: "evm", text: "EVM" },
          { value: "solana", text: "Solana" },
          { value: "cex", text: "CEX" },
        ],

        chainId: constants.CHAIN_ID_SOLANA,
        walletAddress: "",
        walletOptions: [],

        swapInput: utils.jsonClone(sampleSwapSide),
        swapOutput: utils.jsonClone(sampleSwapSide),

        pickingTokenMode: "input" as "input" | "output",

        cexAccountId: 0,
        cexAccExchange: "",
        cexAccounts: [],
        cexType: "",
        cexTypeOptions: [
          { value: "spot", text: "Spot" },
          { value: "futures", text: "Futures" },
        ],
        cexMarketExchange: "",
        cexSearchMarket: "",
        cexMarkets: [],
        cexSymbol: "",
        cexBaseAsset: "",
        cexQuoteAsset: "",
        cexPriceMultiplier: 1,
        cexPriceStep: 0,
        cexAmountStep: 0,
      };
    },

    computed: {
      pickTokenModalId() {
        return "pick-swap-token-modal-hedging-" + this.side;
      },
      pickTokenModalIsStableCoinMode() {
        return (this.side === "buy") === (this.pickingTokenMode === "input");
      },
    },

    mounted() {
      this.setup();
    },

    methods: {
      async setup() {
        const accounts = await cexAccountService.getAll();
        this.cexAccounts = accounts.filter(a => supportExchanges.includes(a.exchange));

        const savedSettings = JSON.parse(localStorage.getItem("hedging2-" + this.side));
        if (savedSettings) {
          Object.assign(this, _.pick(savedSettings, [
            "accountType",
            "chainId",
            "walletAddress",
            "cexAccountId",
            "cexType",
          ]));
          const swapPickProps = ["name", "address", "isNative", "decimals", "symbol", "logo"];
          Object.assign(this.swapInput, _.pick(savedSettings.swapInput, swapPickProps));
          Object.assign(this.swapOutput, _.pick(savedSettings.swapOutput, swapPickProps));

          if (this.accountType === "cex") {
            await this.onSelectCexAccount();
            const market = this.cexMarkets.find(m => m.symbol === savedSettings.cexSymbol);
            if (market) {
              this.onPickCexMarket(market);
            }
          } else {
            this.walletOptions = privateKeyWalletService.getWallets()
              .filter(w => w.type === this.accountType)
              .map(w => ({ text: w.label? `${w.label} (${w.address})` : w.address, value: w.address }));
            this.onChangeWallet();
            if (this.accountType === "solana") {
              const inputTokenMetadataP = web3Service.getSolanaTokenMetadata(this.swapInput.address);
              const outputTokenMetadataP = web3Service.getSolanaTokenMetadata(this.swapOutput.address);

              _.assignWith(this.swapInput, await inputTokenMetadataP, (objectValue, sourceValue) => sourceValue || objectValue);
              _.assignWith(this.swapOutput, await outputTokenMetadataP, (objectValue, sourceValue) => sourceValue || objectValue);

            } else {

            }
          }
        }
      },

      onChangeAccountType() {

      },

      async onChangeWallet() {
        console.log("onChangeSolanaWallet", this.walletAddress);
        if (this.accountType === "solana") {
          web3Service.getSolanaWalletTokenBalances(this.walletAddress);
        }
      },

      onClickInputAsset() {
        this.pickingTokenMode = "input";
        this.$bvModal.show(this.pickTokenModalId);
      },

      onClickOutputAsset() {
        this.pickingTokenMode = "output";
        this.$bvModal.show(this.pickTokenModalId);
      },

      onPickToken(tokenObj: any) {
        if (this.pickingTokenMode === "input") {
          if (tokenObj.address === this.swapOutput.address) {

          } else {
            this.swapInput.name = tokenObj.name;
            this.swapInput.address = tokenObj.address;
            this.swapInput.isNative = tokenObj.isNative;
            this.swapInput.decimals = tokenObj.decimals;
            this.swapInput.symbol = tokenObj.symbol;
            this.swapInput.logo = tokenObj.logo;
            this.swapInput.walletBalance = tokenObj.balance;
            this.swapInput.walletDisplayBalance = tokenObj.displayBalance;
          }

        } else if (this.pickingTokenMode === "output") {
          if (tokenObj.address === this.swapInput.address) {

          } else {
            this.swapOutput.name = tokenObj.name;
            this.swapOutput.address = tokenObj.address;
            this.swapOutput.isNative = tokenObj.isNative;
            this.swapOutput.decimals = tokenObj.decimals;
            this.swapOutput.symbol = tokenObj.symbol;
            this.swapOutput.logo = tokenObj.logo;
            this.swapOutput.walletBalance = tokenObj.balance;
            this.swapOutput.walletDisplayBalance = tokenObj.displayBalance;
          }
        }

        this.swapInput.displayPriceUsd = "--";
        this.swapInput.displayPriceOther = "--";
        this.swapOutput.displayPriceUsd = "--";
        this.swapOutput.displayPriceOther = "--";

        this.$bvModal.hide(this.pickTokenModalId);
      },

      async onSelectCexAccount() {
        // reset value
        this.cexMarkets = [];
        this.clearSelectedCexMarket();

        if (this.cexAccountId) {
          const cexAccount = this.cexAccounts.find(it => it.id === this.cexAccountId);
          this.cexAccExchange = cexAccount.exchange;
          this.cexMarketExchange = cexAccount.exchange;
          if (this.cexType === "futures") {
            this.cexMarketExchange += "-futures";
          }
          const markets = await marketDataService.getCexMarkets({ exchange: this.cexMarketExchange });
          this.cexMarkets = markets.filter(m =>
            !utils.isLeveragedTokenTicker(m.baseAsset) &&
            constants.stableCoinSymbols.includes(m.quoteAsset.toUpperCase())
          );
        }
      },

      cexMarketSelectDropdownFilter(options: any[], search: string) {
        if (!search) return [];
        search = utils.sanitizeSearchText(search).toLowerCase().replace(/[^a-z]/g, "");
        return options.filter(market => market.symbol.toLowerCase().replace(/[^a-z]/g, "").startsWith(search));
      },

      onPickCexMarket(market) {
        this.cexSymbol = market.symbol;
        this.cexBaseAsset = market.baseAsset;
        this.cexQuoteAsset = market.quoteAsset;
        if (market.symbol.startsWith("1000")) {
          this.cexPriceMultiplier = +market.symbol.match(priceMultipleTickerPattern)[0];
          this.cexPriceStep = BigNumber(market.priceStep).div(this.cexPriceMultiplier).toNumber();
          this.cexAmountStep = BigNumber(market.amountStep).multipliedBy(this.cexPriceMultiplier).toNumber();
        } else {
          this.cexPriceMultiplier = 1;
          this.cexPriceStep = market.priceStep;
          this.cexAmountStep = market.amountStep;
        }
      },

      clearSelectedCexMarket() {
        this.cexSearchMarket = "";
        this.cexSymbol = "";
        this.cexBaseAsset = "";
        this.cexQuoteAsset = "";
        this.cexPriceStep = 0;
        this.cexAmountStep = 0;
      },

      getFormData() {
        return utils.jsonClone(_.pick(this, [
          "accountType",
          "chainId",
          "walletAddress",
          "walletOptions",
          "swapInput",
          "swapOutput",
          "cexAccountId",
          "cexAccExchange",
          "cexAccounts",
          "cexType",
          "cexMarketExchange",
          "cexSearchMarket",
          "cexMarkets",
          "cexSymbol",
          "cexBaseAsset",
          "cexQuoteAsset",
          "cexPriceMultiplier",
          "cexPriceStep",
          "cexAmountStep"
        ]));
      },

      getReversedSideFormData() {
        const formData = this.getFormData();
        const tmp = formData.swapInput;
        formData.swapInput = formData.swapOutput;
        formData.swapOutput = tmp;
        return formData;
      },

      setFormData(formData) {
        Object.assign(this, utils.jsonClone(formData));
      },

      saveSettings() {
        const ret = utils.jsonClone<any>(_.pick(this, [
          "accountType",
          "chainId",
          "walletAddress",
          "cexAccountId",
          "cexType",
          "cexSymbol",
        ]));
        const swapPickProps = ["name", "address", "isNative", "decimals", "symbol", "logo"];
        ret.swapInput = _.pick(this.swapInput, swapPickProps);
        ret.swapOutput = _.pick(this.swapOutput, swapPickProps);
        localStorage.setItem("hedging2-" + this.side, JSON.stringify(ret));
      },

      isValid() {
        if (this.accountType === "cex") {
          return this.cexAccountId && this.cexSymbol;
        } else {
          return this.walletAddress && this.swapInput.address && this.swapOutput.address;
        }
      }
    }
  };
</script>
