<template>
  <div>
    <div class="text-monospace small text-break" style="white-space: pre-wrap">{{ rawDataText }}</div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";

  export default {
    name: "DepositAddressDetailsModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "addressDetails"],
    emits: ["done"],

    computed: {
      rawDataText() {
        try {
          return JSON.stringify(this.addressDetails.rawData, null, 2);

        } catch (e) {
          return this.addressDetails.rawData;
        }
      }
    }
  };
</script>
