<template>
  <b-row no-gutters class="bg-highlight-hover px-2 py-1">
    <b-col cols="7">
      <div class="d-flex flex-row align-items-center">
        <img class="img-exchange" :src="exchangeLogoSrc">
        <div>
          <div>
            <small class="text-secondary">
              {{ exchangeName }}
              <template v-if="type === 'dex'">
                ·
                <span class="cursor-pointer" @click="copyTokenAddress">
                  <b-icon-files /> {{ tokenAddressShortened }}
                </span>
              </template>
            </small>
          </div>
          <template v-if="showAllPrices">
            <div class="text-success">{{ displayBuyPrice }}</div>
            <div>{{ displayIndexPrice }}</div>
            <div class="text-danger">{{ displaySellPrice }}</div>
          </template>
          <div v-else :class="priceTextClass">{{ displayPrice }}</div>
        </div>
      </div>
    </b-col>
    <b-col cols="5">
      <div><small class="text-secondary">{{ displayVolLiqType }}</small></div>
      <div>{{ volLiqDisplayValue }}</div>
      <template v-if="displayFundingRate && showAllPrices">
        <div><small class="text-secondary">Funding / Countdown</small></div>
        <div>
          <span class="mr-3" :class="displayFundingRateTextClass">{{ displayFundingRate }}%</span>
          <span>{{ displayFundingCountdown }}</span>
        </div>
      </template>
    </b-col>
    <b-col>
      <small v-if="type === 'dex'" class="text-secondary">
        <router-link v-if="solanaSwapRouterLinkObject" class="text-secondary" :to="solanaSwapRouterLinkObject">Swap</router-link>
        <a v-else-if="tronSwapUrl" class="text-secondary" :href="tronSwapUrl" target="_blank">Swap</a>
        <a v-else-if="tonSwapUrl" class="text-secondary" :href="tonSwapUrl" target="_blank">Swap</a>
        <router-link v-else class="text-secondary" :to="swapRouterLinkObject">Swap</router-link> ·
        <a class="text-secondary" :href="explorerUrl" target="_blank">Explorer</a>
        <template v-if="dexScreenerUrl && dexToolsUrl">
          ·
          <a :href="dexScreenerUrl" target="_blank" class="text-secondary">DEXScreener</a> ·
          <a :href="dexToolsUrl" target="_blank" rel="noopener noreferrer" class="text-secondary">DEXTools</a>
        </template>
      </small>
      <small v-if="type === 'cex'" class="text-secondary">
        <a class="text-secondary" :href="cexTradeUrl" target="_blank">Trade</a>
        <template v-if="!item.exchange.endsWith('-futures')">
          ·
          <span class="cursor-pointer underline-hover" @click="onClickDeposit">Deposit ({{ depositText }})</span> ·
          <span class="cursor-pointer underline-hover" @click="onClickWithdraw">Withdraw ({{ withdrawText }})</span>
        </template>
      </small>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
  .img-exchange {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }
  .bg-highlight-hover:hover {
    background-color: lightyellow;
  }
</style>

<script lang="ts">
  import _ from "lodash";
  import { DateTime } from "luxon";
  import * as constants from "@/constants";
  import BigNumber from "bignumber.js";
  import * as marketDataService from "@/services/marketDataService";
  import * as utils from "@/utils";

  const volNumberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const futuresAssetSymbolOverride = {
    "mexc-futures": {
      "STEPN": "GMT" // Futures is GMT, spot is STEPN
    }
  };

  export default {
    name: "ArbitrageSubItem",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["coingeckoId", "item", "type", "priceType", "showAllPrices"],
    emits: ["onClickWithdraw", "onClickDeposit"],

    data() {
      return {
        fundingCountdownIntervalHandler: undefined,
        displayFundingCountdown: ""
      };
    },

    computed: {
      exchangeName() {
        if (this.type === "dex") {
          return constants.chainNames[this.item.chainId];
        } else if (this.type === "cex") {
          return constants.exchangeNames[this.item.exchange];
        }
        return undefined;
      },
      exchangeLogoSrc() {
        if (this.type === "dex") {
          return constants.NETWORK_LOGO[this.item.chainId];
        } else if (this.type === "cex") {
          return constants.EXCHANGE_LOGO[this.item.exchange];
        }
        return undefined;
      },

      priceTextClass() {
        if (this.priceType === "trade") {
          if (this.item.buyHere) {
            return "text-success";
          } else if (this.item.sellHere) {
            return "text-danger";
          }
        }
        return undefined;
      },
      displayPrice() {
        if (this.priceType === "trade") {
          if (this.item.buyHere && this.item.priceBuying) {
            return BigNumber(this.item.priceBuying).precision(6).toFixed();
          } else if (this.item.sellHere && this.item.priceSelling) {
            return BigNumber(this.item.priceSelling).precision(6).toFixed();
          }
        }
        return BigNumber(this.item.price).precision(6).toFixed();
      },
      displayBuyPrice() {
        if (this.item.priceBuying) {
          return BigNumber(this.item.priceBuying).precision(6).toFixed();
        }
        return undefined;
      },
      displayIndexPrice() {
        return BigNumber(this.item.price).precision(6).toFixed();
      },
      displaySellPrice() {
        if (this.item.priceSelling) {
          return BigNumber(this.item.priceSelling).precision(6).toFixed();
        }
        return undefined;
      },

      displayVolLiqType() {
        if (this.type === "dex") {
          return "Liquidity";
        } else if (this.type === "cex") {
          if (this.item.depthN2 && this.item.depthP2) {
            if (this.item.buyHere) {
              return "+2% depth";
            } else if (this.item.sellHere) {
              return "-2% depth";
            } else {
              return "±2% depth";
            }
          } else {
            return "24h volume";
          }
        }
        return undefined;
      },
      volLiqDisplayValue() {
        if (this.type === "dex") {
          return volNumberFormatUsd.format(this.item.liquidity);
        } else if (this.type === "cex") {
          let val;
          if (this.item.depthN2 && this.item.depthP2) {
            if (this.item.buyHere) {
              val = this.item.depthP2;
            } else if (this.item.sellHere) {
              val = this.item.depthN2;
            } else {
              val = Math.min(this.item.depthN2, this.item.depthP2);
            }
          } else {
            val = this.item.volume24h;
          }
          return volNumberFormatUsd.format(val);
        }
        return undefined;
      },

      displayFundingRate() {
        const { exchange, nextFundingRate } = this.item;
        if (this.type === "cex" && exchange.endsWith("-futures")) {
          return BigNumber(nextFundingRate).multipliedBy(100).toFixed(4);
        }
        return undefined;
      },
      displayFundingRateTextClass() {
        const { exchange, nextFundingRate } = this.item;
        if (this.type === "cex" && exchange.endsWith("-futures")) {
          const nextFundingRateBN = BigNumber(nextFundingRate);
          if (nextFundingRateBN.gt(0)) {
            return "text-success";
          } else if (nextFundingRateBN.lt(0)) {
            return "text-danger";
          }
        }
        return undefined;
      },

      cexTradeUrl() {
        if (this.type === "cex") {
          const { exchange, asset } = this.item;
          if (exchange.endsWith("-futures")) {
            const futuresAsset = futuresAssetSymbolOverride[exchange]?.[asset] || asset;
            if (exchange === "binance-futures") {
              return `https://www.binance.com/en/futures/${futuresAsset.toUpperCase()}USDT`;
            } else if (exchange === "huobi-futures") {
              return `https://www.huobi.com/en-us/futures/linear_swap/exchange#contract_code=${futuresAsset.toUpperCase()}-USDT`;
            } else if (exchange === "okx-futures") {
              return `https://www.okx.com/trade-swap/${futuresAsset.toLowerCase()}-usdt-swap`;
            } else if (exchange === "kucoin-futures") {
              return `https://www.kucoin.com/futures/trade/${futuresAsset.toUpperCase()}USDTM`;
            } else if (exchange === "mexc-futures") {
              return `https://futures.mexc.com/exchange/${futuresAsset.toUpperCase()}_USDT`;
            } else if (exchange === "gate-futures") {
              return `https://www.gate.io/futures/USDT/${futuresAsset.toUpperCase()}_USDT`;
            } else if (exchange === "bybit-futures") {
              return `https://www.bybitglobal.com/trade/usdt/${futuresAsset.toUpperCase()}USDT`;
            } else if (exchange === "bitget-futures") {
              return `https://www.bitget.com/futures/usdt/${futuresAsset.toUpperCase()}USDT`;
            }

          } else {
            if (exchange === "binance") {
              return `https://www.binance.com/en/trade/${asset}_USDT`;
            } else if (exchange === "huobi") {
              return `https://www.huobi.com/en-us/exchange/${asset.toLowerCase()}_usdt`;
            } else if (exchange === "okx") {
              return `https://www.okx.com/trade-spot/${asset.toLowerCase()}-usdt`;
            } else if (exchange === "kucoin") {
              return `https://www.kucoin.com/trade/${asset}-USDT`;
            } else if (exchange === "mexc") {
              return `https://www.mexc.com/exchange/${asset}_USDT`;
            } else if (exchange === "gate") {
              return `https://www.gate.io/trade/${asset}_USDT`;
            } else if (exchange === "bybit") {
              return `https://www.bybitglobal.com/trade/spot/${asset}/USDT`;
            } else if (exchange === "bitget") {
              return `https://www.bitget.com/spot/${asset}USDT`;
            }
          }
        }
        return undefined;
      },

      explorerUrl() {
        if (this.type === "dex") {
          return utils.getExplorerUrl(this.item.chainId, "token", this.item.address);
        }
        return undefined;
      },
      dexScreenerUrl() {
        if (this.type === "dex") {
          const { chainId, address } = this.item;
          const dexScreenerChainId = constants.dexScreenerChainIds[chainId];
          if (dexScreenerChainId) {
            return `https://dexscreener.com/${dexScreenerChainId}/${address}`;
          }
        }
        return undefined;
      },
      dexToolsUrl() {
        if (this.type === "dex") {
          const { chainId, address } = this.item;
          const dexToolsChainId = constants.dexToolsChainIds[chainId];
          if (dexToolsChainId) {
            return `https://www.dextools.io/app/en/${dexToolsChainId}/pair-explorer/${address}`;
          }
        }
        return undefined;
      },

      tokenAddressShortened() {
        if (this.type === "dex") {
          return this.item.address.slice(0, 6) + "…" + this.item.address.slice(-4);
        }
        return undefined;
      },

      depositText() {
        if (this.type === "cex" && !this.item.exchange.endsWith("-futures")) {
          const networks = this.item.networks;
          const knownNetworks = [];
          const unknownNetworks = [];
          for (const n of networks) {
            if (n.depositEnabled) {
              if (n.chainId) {
                knownNetworks.push(n);
              } else {
                unknownNetworks.push(n);
              }
            }
          }
          const textParts = knownNetworks.map(n => constants.chainNamesShort[n.chainId]);
          if (unknownNetworks.length) {
            textParts.push(unknownNetworks.length + " more");
          }
          if (textParts.length) {
            return textParts.join(", ");
          } else {
            return "disabled";
          }
        }
        return undefined;
      },
      withdrawText() {
        if (this.type === "cex" && !this.item.exchange.endsWith("-futures")) {
          const networks = this.item.networks;
          const knownNetworks = [];
          const unknownNetworks = [];
          for (const n of networks) {
            if (n.withdrawEnabled) {
              if (n.chainId) {
                knownNetworks.push(n);
              } else {
                unknownNetworks.push(n);
              }
            }
          }
          const textParts = knownNetworks.map(n => constants.chainNamesShort[n.chainId]);
          if (unknownNetworks.length) {
            textParts.push(unknownNetworks.length + " more");
          }
          if (textParts.length) {
            return textParts.join(", ");
          } else {
            return "disabled";
          }
        }
        return undefined;
      },

      swapRouterLinkObject() {
        if (this.type === "dex") {
          return {
            path: "/arbitrage-focus",
            query: {
              coingeckoId: this.coingeckoId,
              chainId: this.item.chainId,
              side: this.item.buyHere ? "buy" : "sell"
            }
          };
        }
        return undefined;
      },
      solanaSwapRouterLinkObject() {
        if (this.type === "dex") {
          const {chainId, address, buyHere} = this.item;
          if (chainId === constants.CHAIN_ID_SOLANA) {
            return {
              path: "/arbitrage-focus-solana",
              query: {
                coingeckoId: this.coingeckoId,
                side: this.item.buyHere ? "buy" : "sell"
              }
            };
          }
        }
        return undefined;
      },
      tonSwapUrl() {
        if (this.type === "dex") {
          const {chainId, address, buyHere} = this.item;
          if (chainId === constants.CHAIN_ID_TON) {
            if (buyHere) {
              return `https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=USD%E2%82%AE&tt=${address}&fa=5000`;
            } else {
              return `https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&tt=USD%E2%82%AE&ft=${address}`;
            }
          }
        }
        return undefined;
      },
      tronSwapUrl() {
        if (this.type === "dex") {
          const {chainId, address, buyHere} = this.item;
          if (chainId === constants.CHAIN_ID_TRON) {
            return `https://sun.io`;
          }
        }
        return undefined;
      }
    },

    watch: {
      item: {
        immediate: true,
        deep: true,
        handler() {
          if (this.item.exchange?.endsWith("-futures")) {
            this.refreshDisplayFundingCountdown();
            clearInterval(this.fundingCountdownIntervalHandler);
            this.fundingCountdownIntervalHandler = setInterval(this.refreshDisplayFundingCountdown, 500);
          }
        }
      }
    },

    methods: {
      refreshDisplayFundingCountdown() {
        const diff = DateTime.fromISO(this.item.nextFundingTime).diffNow();
        if (diff.valueOf() >= 0) {
          this.displayFundingCountdown = diff.toFormat("hh:mm:ss");
        }
      },

      async copyTokenAddress() {
        if (this.type === "dex") {
          await navigator.clipboard.writeText(this.item.address);
          this.toastSuccess("Address copied", this.item.address);
        }
      },

      onClickWithdraw() {
        this.$emit("onClickWithdraw", this.item);
        // console.log("ArbitrageSubItem onClickWithdraw", this.item);
      },

      onClickDeposit() {
        this.$emit("onClickDeposit", this.item);
        // console.log("ArbitrageSubItem onClickDeposit", this.item);
      }
    },

    unmounted() {
      clearInterval(this.fundingCountdownIntervalHandler);
    }
  };
</script>
