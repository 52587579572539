<template>
  <main role="main">
    <b-container class="py-3">
      <b-row>
        <b-col lg="5" class="d-flex flex-row justify-content-center">
          <div>
            <label class="text-success">Buy / Long</label>
            <div class="mb-3">
              <label style="width: 80px;">Account</label>
              <b-form-select
                :options="cexAccounts"
                text-field="label"
                value-field="id"
                v-model="acc1.cexAccountId"
                required
                @change="onSelectAccountAcc1"
                :disabled="doneSetup"
                style="width: 300px;"/>
            </div>
            <div class="mb-3">
              <label class="m-0" style="width: 80px;">Type</label>
              <b-form-radio-group class="d-inline-block" :options="typeOptions" v-model="acc1.type"
                                  @change="onSelectAccountAcc1" :disabled="doneSetup"></b-form-radio-group>
            </div>
            <div class="mb-3 d-flex flex-row">
              <label class="flex-shrink-0 mt-2" style="width: 80px;">Market</label>
              <div style="width: 300px;">
                <div>
                  <v-select class="vs-normalizer" :hidden="!!acc1.symbol"
                            :getOptionKey="m => m.symbol" label="symbol"
                            :filter="marketSelectDropdownFilter"
                            :options="acc1.markets"
                            :clearSearchOnBlur="() => false"
                            v-on:option:selecting="onPickMarketAcc1">
                    <template v-slot:search="{attributes, events}">
                      <input v-bind="attributes" v-on="events"
                             :value="acc1.searchMarket"
                             @input="acc1.searchMarket = $event.target.value"
                             class="form-control" autocomplete="off" minlength="2" maxlength="100"/>
                    </template>
                    <template v-slot:option="{ baseAsset, quoteAsset }">
                      <div class="py-1">
                        {{ acc1.type === "futures" ? baseAsset + quoteAsset + " Perpetual" : baseAsset + " / " + quoteAsset }}
                      </div>
                    </template>
                  </v-select>
                  <div v-if="acc1.symbol" class="border rounded d-flex flex-row align-items-center"
                       style="padding: 6px 12px;">
                    <div class="flex-grow-1 min-width-0">
                      {{ acc1.type === "futures" ? acc1.baseAsset + acc1.quoteAsset + " Perpetual" : acc1.baseAsset + " / " + acc1.quoteAsset }}
                    </div>
                    <div v-if="!doneSetup" class="flex-shrink-0 text-secondary cursor-pointer" @click="clearSelectedMarketAcc1">
                      <b-icon-x-circle/>
                    </div>
                  </div>
                </div>
                <div>
                  <b-button variant="link" class="p-0" size="sm" :disabled="doneSetup" @click="onClickPriceMultiplier(acc1)">
                    Price multiplier: {{ acc1.symbol && acc1.priceMultiplier || "--" }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="2" class="d-flex flex-row align-items-center justify-content-center">
          <b-button variant="link" @click="onClickReverseSides" :disabled="reverseButtonDisabled" title="Reverse">
            <b-icon-arrow-left-right />
          </b-button>
        </b-col>
        <b-col lg="5" class="d-flex flex-row justify-content-center">
          <div>
            <label class="text-danger">Sell / Short</label>
            <div class="mb-3">
              <label style="width: 80px;">Account</label>
              <b-form-select
                :options="cexAccounts"
                text-field="label"
                value-field="id"
                v-model="acc2.cexAccountId"
                required
                @change="onSelectAccountAcc2"
                :disabled="doneSetup"
                style="width: 300px;"/>
            </div>
            <div class="mb-3">
              <label class="m-0" style="width: 80px;">Type</label>
              <b-form-radio-group class="d-inline-block" :options="typeOptions" v-model="acc2.type"
                                  @change="onSelectAccountAcc2" :disabled="doneSetup"></b-form-radio-group>
            </div>
            <div class="mb-3 d-flex flex-row">
              <label class="flex-shrink-0 mt-2" style="width: 80px;">Market</label>
              <div style="width: 300px;">
                <div>
                  <v-select class="vs-normalizer" :hidden="!!acc2.symbol"
                            :getOptionKey="m => m.symbol" label="symbol"
                            :filter="marketSelectDropdownFilter"
                            :options="acc2.markets"
                            :clearSearchOnBlur="() => false"
                            v-on:option:selecting="onPickMarketAcc2">
                    <template v-slot:search="{attributes, events}">
                      <input v-bind="attributes" v-on="events"
                             :value="acc2.searchMarket"
                             @input="acc2.searchMarket = $event.target.value"
                             class="form-control" autocomplete="off" minlength="2" maxlength="100"/>
                    </template>
                    <template v-slot:option="{ baseAsset, quoteAsset }">
                      <div class="py-1">
                        {{ acc2.type === "futures" ? baseAsset + quoteAsset + " Perpetual" : baseAsset + " / " + quoteAsset }}
                      </div>
                    </template>
                  </v-select>
                  <div v-if="acc2.symbol" class="border rounded d-flex flex-row align-items-center"
                       style="padding: 6px 12px;">
                    <div class="flex-grow-1 min-width-0">
                      {{ acc2.type === "futures" ? acc2.baseAsset + acc2.quoteAsset + " Perpetual" : acc2.baseAsset + " / " + acc2.quoteAsset }}
                    </div>
                    <div v-if="!doneSetup" class="flex-shrink-0 text-secondary cursor-pointer" @click="clearSelectedMarketAcc2">
                      <b-icon-x-circle/>
                    </div>
                  </div>
                </div>
                <div>
                  <b-button variant="link" class="p-0" size="sm" :disabled="doneSetup" @click="onClickPriceMultiplier(acc2)">
                    Price multiplier: {{ acc2.symbol && acc2.priceMultiplier || "--" }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="!doneSetup" class="text-center">
        <b-button variant="primary" :disabled="doneSetupBtnDisabled" v-b-modal:cex-hedging-confirm-market-modal>OK</b-button>
      </div>
      <template v-if="doneSetup">
        <section>
          <b-form @keydown.enter.prevent @submit.prevent="onSubmitHedgingParamsForm">
            <b-row class="mb-3">
              <b-col lg="5">
                <div v-if="acc1.marketExchange === 'kucoin-futures'" class="d-flex flex-row align-items-center justify-content-center mb-3">
                  <label style="width: 80px;">Leverage</label>
                  <b-form-spinbutton style="width: 300px;" v-model="acc1.leverage" min="1" max="100" :disabled="isHedgingParamLocked" />
                </div>
                <div class="d-flex flex-row justify-content-center">
                  <label style="width: 80px;">Options</label>
                  <div style="width: 300px;">
                    <b-form-radio-group v-if="acc1.marketExchange === 'okx-futures'" :options="marginModeOptions" :disabled="isHedgingParamLocked" v-model="acc1.marginMode" />
                    <b-form-checkbox v-if="acc1.type === 'futures'" v-model="acc1.reduceOnly" :disabled="isHedgingParamLocked">Reduce only</b-form-checkbox>
                    <b-form-checkbox v-model="acc1.dryRun" :disabled="isHedgingParamLocked">Dry run</b-form-checkbox>
                  </div>
                </div>
              </b-col>
              <b-col lg="2"></b-col>
              <b-col lg="5">
                <div v-if="acc2.marketExchange === 'kucoin-futures'" class="d-flex flex-row align-items-center justify-content-center mb-3">
                  <label style="width: 80px;">Leverage</label>
                  <b-form-spinbutton style="width: 300px;" v-model="acc2.leverage" min="1" max="100" :disabled="isHedgingParamLocked" />
                </div>
                <div class="d-flex flex-row justify-content-center">
                  <label style="width: 80px;">Options</label>
                  <div style="width: 300px;">
                    <b-form-radio-group v-if="acc2.marketExchange === 'okx-futures'" :options="marginModeOptions" :disabled="isHedgingParamLocked" v-model="acc2.marginMode" />
                    <b-form-checkbox v-if="acc2.type === 'futures'" v-model="acc2.reduceOnly" :disabled="isHedgingParamLocked">Reduce only</b-form-checkbox>
                    <b-form-checkbox v-model="acc2.dryRun" :disabled="isHedgingParamLocked">Dry run</b-form-checkbox>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="d-flex flex-row align-items-end mb-3">
              <div class="mr-3 mb-0 flex-shrink-0" style="width: 200px;">
                <label class="mb-1">Amount {{ displayBaseAsset }}</label>
                <b-form-input type="number" :step="amountStep" :min="amountStep"
                              required :disabled="isHedgingParamLocked" v-model="amount"
                              v-b-tooltip.hover :title="displayNotionalValue" />
              </div>
              <div class="mr-3 mb-0 flex-shrink-0" style="width: 200px;">
                <b-button variant="link" class="p-0 mb-1" :disabled="isHedgingParamLocked" @click="isMinDiffPercent = !isMinDiffPercent">
                  <span v-if="isMinDiffPercent">Min price diff %</span>
                  <span v-else>Min price diff {{ displayQuoteAsset }}</span>
                  <small class="ml-2"><b-icon-arrow-left-right /></small>
                </b-button>
                <b-form-input v-if="isMinDiffPercent" type="number" step="0.0001"
                              required :disabled="isHedgingParamLocked" v-model="minDiffPercent"></b-form-input>
                <b-form-input v-else type="number" :step="priceStep"
                              required :disabled="isHedgingParamLocked" v-model="minDiff"></b-form-input>
              </div>
              <div class="mr-3 mb-2">
                <span :class="{
                  'text-primary': !isHedgingParamLocked,
                  'cursor-pointer': !isHedgingParamLocked,
                  'text-secondary': isHedgingParamLocked
                }" @click="onClickMarketBBO">Market BBO</span>
              </div>
              <b-form-checkbox v-model="autoSendOrder" class="align-self-end mr-3 mb-2" :disabled="isHedgingParamLocked">
                Auto send order
              </b-form-checkbox>
              <b-form-group v-if="autoSendOrder" :label="'Total amount ' + displayBaseAsset" class="mr-3 mb-0" style="width: 150px;">
                <b-form-input type="number" :step="amountStep" :min="amountStep"
                              required :disabled="isHedgingParamLocked" v-model="remainingAmount"></b-form-input>
              </b-form-group>
              <div class="align-self-end mr-5">
                <span v-if="isHedgingParamLocked" class="btn btn-outline-primary" @click="onClickEditHedgingParams">
                  {{ autoSendOrder ? "Stop" : "Edit" }}
                </span>
                <b-button v-else type="submit" variant="primary" @click="beforeSubmitHedgingParamsForm">
                  {{ autoSendOrder ? "Start" : "Set" }}
                </b-button>
              </div>
            </div>
          </b-form>
          <div v-if="isHedgingParamLocked" class="d-flex flex-row mb-3">
            <div style="width: 200px;">
              <label>Buy price</label>
              <div class="text-monospace text-success">{{ displayBuyPrice }}</div>
            </div>
            <div style="width: 200px;">
              <label>Sell price</label>
              <div class="text-monospace text-danger">{{ displaySellPrice }}</div>
            </div>
            <div style="width: 200px;">
              <label>Diff</label>
              <div class="text-monospace">{{ displayPriceDiff }}</div>
              <div class="text-monospace">{{ displayPriceDiffPercent }}%</div>
            </div>
            <div style="width: 128px;">
              <label>PnL</label>
              <div class="text-monospace">{{ displayPnl }}</div>
            </div>
            <div class="pt-2 align-self-center">
              <span v-if="autoSendOrder" class="text-secondary">
                <b-spinner v-if="isSendingOrder" small></b-spinner>
                {{ isSendingOrder ? "Sending order..." : "Waiting for diff..." }}
              </span>
              <b-button v-else variant="primary" :disabled="sendOrdersBtnDisabled || isSendingOrder" @click="sendOrder">
                {{ isSendingOrder ? "Sending order..." : "Send order" }}
              </b-button>
            </div>
          </div>
        </section>
        <section>
          <div class="d-flex flex-row align-items-center justify-content-between mb-3">
            <label class="m-0">Trades</label>
            <div>
              <span class="text-secondary mr-2">Total PnL:</span>
              <span class="text-monospace" :class="displayTradesTotalPnlTextClass">{{ displayTradesTotalPnl }}</span>
              <span class="text-secondary"> {{ displayQuoteAsset }} (</span>
              <span class="text-monospace" :class="displayTradesTotalPnlTextClass">{{ displayTradesTotalPnlPercent }}%</span>
              <span class="text-secondary">)</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center mb-3">
            <div class="flex-shrink-0">{{ trades.length }} items</div>
            <div class="flex-grow-1"></div>
            <div class="mr-2 flex-shrink-0">Items per page</div>
            <div class="mr-4 flex-shrink-0">
              <b-select :options="itemsPerPageOptions" v-model="itemsPerPage" />
            </div>
            <b-pagination v-model="currentPage" :total-rows="trades.length" :per-page="itemsPerPage" limit="10" class="m-0" />
          </div>
          <b-table :items="trades" :fields="tradesTableFields" primary-key="id"
                   :per-page="itemsPerPage" :current-page="currentPage"
                   thead-class="text-nowrap" small hover show-empty>
            <template v-slot:cell(buyPrice)="{ item, unformatted, value }">
              {{ value }}
              <span v-if="!unformatted" class="ml-1 cursor-pointer" v-b-tooltip.hover :title="item.buyErrorMessage">
                <b-icon-info-circle />
              </span>
            </template>
            <template v-slot:cell(sellPrice)="{ item, unformatted, value }">
              {{ value }}
              <span v-if="!unformatted" class="ml-1 cursor-pointer" v-b-tooltip.hover :title="item.sellErrorMessage">
                <b-icon-info-circle />
              </span>
            </template>
          </b-table>
        </section>
        <section>
          <div class="text-center">
            <label>Order book</label>
          </div>
          <div>
            <div class="d-inline-block" style="width: 45%; vertical-align: top;">
              <div class="text-right">
                <label>Asks</label>
              </div>
              <b-row class="text-secondary">
                <b-col cols="4" class="text-right">Sum {{ displayBaseAsset }}</b-col>
                <b-col cols="4" class="text-right">Amount {{ displayBaseAsset }}</b-col>
                <b-col cols="4" class="text-right">Price {{ acc1.quoteAsset }}</b-col>
              </b-row>
              <b-row v-for="item in displayAcc1OrderBook" :key="item.displayPrice" class="text-monospace">
                <b-col cols="4" class="text-right">
                  <span>{{ item.displayTotalAmount }}</span>
                </b-col>
                <b-col cols="4" class="text-right">
                  <span>{{ item.displayAmount }}</span>
                </b-col>
                <b-col cols="4" class="text-right">
                  <span class="text-danger">{{ item.displayPrice }}</span>
                </b-col>
              </b-row>
            </div>
            <div class="d-inline-block" style="width: 10%;"></div>
            <div class="d-inline-block" style="width: 45%; vertical-align: top;">
              <label>Bids</label>
              <b-row class="text-secondary">
                <b-col cols="4">Price {{ acc2.quoteAsset }}</b-col>
                <b-col cols="4" class="text-right">Amount {{ displayBaseAsset }}</b-col>
                <b-col cols="4" class="text-right">Sum {{ displayBaseAsset }}</b-col>
              </b-row>
              <b-row v-for="item in displayAcc2OrderBook" :key="item.displayPrice" class="text-monospace">
                <b-col cols="4">
                  <span class="text-success">{{ item.displayPrice }}</span>
                </b-col>
                <b-col cols="4" class="text-right">
                  <span>{{ item.displayAmount }}</span>
                </b-col>
                <b-col cols="4" class="text-right">
                  <span>{{ item.displayTotalAmount }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
      </template>
    </b-container>

    <b-modal id="cex-hedging-edit-price-multiplier-modal" hide-header no-fade hide-footer>
      <CexHedgingEditPriceMultiplierModal
        modalId="cex-hedging-edit-price-multiplier-modal"
        :hedgeSide="editingSide"
        @done="applyPriceMultiplier"
      />
    </b-modal>
    <b-modal id="cex-hedging-confirm-market-modal" title="Confirm" no-fade hide-footer>
      <CexHedgingConfirmMarketModal
        modalId="cex-hedging-confirm-market-modal"
        :buySide="acc1"
        :sellSide="acc2"
        @done="onClickDoneSetup"
      />
    </b-modal>
    <b-modal id="cex-hedging-confirm-reverse-market-modal" title="Reverse sides" no-fade hide-footer>
      <!-- reverse the sides here -->
      <CexHedgingConfirmMarketModal
        modalId="cex-hedging-confirm-reverse-market-modal"
        :buySide="acc2"
        :sellSide="acc1"
        @done="reverseSides"
      />
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import * as constants from "@/constants";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as utils from "@/utils";
  import * as marketDataService from "@/services/marketDataService";
  import * as cexHedgingService from "@/services/cexHedgingService";
  import type {BvTableFieldArray} from "bootstrap-vue";
  import {DateTime} from "luxon";
  import axios from "@/axios";
  import CexHedgingConfirmMarketModal from "@/components/cex-hedging/CexHedgingConfirmMarketModal.vue";
  import CexHedgingEditPriceMultiplierModal from "@/components/cex-hedging/CexHedgingEditPriceMultiplierModal.vue";

  const priceMultipleTickerPattern = /^10{3,}/;
  const supportExchanges = ["binance", "bybit", "okx", "kucoin", "gate", "bitget"];

  export default {
    name: "CexHedging",
    components: {CexHedgingEditPriceMultiplierModal, CexHedgingConfirmMarketModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],

    data() {
      return {
        cexAccounts: [],
        typeOptions: [
          { value: "spot", text: "Spot" },
          { value: "futures", text: "Futures" },
        ],
        marginModeOptions: [
          { value: "cross", text: "Cross margin" },
          { value: "isolated", text: "Isolated margin" },
        ],

        acc1: {
          cexAccountId: 0,
          accExchange: "",
          type: "spot",
          marketExchange: "",
          searchMarket: "",
          markets: [],
          symbol: "",
          marginMode: "cross",
          leverage: 1,
          reduceOnly: false,
          dryRun: false,
          baseAsset: "",
          quoteAsset: "",
          priceMultiplier: 1,
          amountMultiplier: 1,
          priceStep: 0,
          amountStep: 0,
          ws: null,
          wsIsAlive: false,
          wsHeartbeatIntervalHandler: null,
          orderBook: [],
          orderBookTs: 0,
        },
        acc2: {
          cexAccountId: 0,
          accExchange: "",
          type: "futures",
          marketExchange: "",
          searchMarket: "",
          markets: [],
          symbol: "",
          marginMode: "cross",
          leverage: 1,
          reduceOnly: false,
          dryRun: false,
          baseAsset: "",
          quoteAsset: "",
          priceMultiplier: 1,
          amountMultiplier: 1,
          priceStep: 0,
          amountStep: 0,
          ws: null,
          wsIsAlive: false,
          wsHeartbeatIntervalHandler: null,
          orderBook: [],
          orderBookTs: 0,
        },

        editingSide: null,
        wsStartConnectTs: 0,
        verbose: false,

        doneSetup: false,

        amount: 0 as number | string,

        isMinDiffPercent: false,
        minDiff: 0 as number | string,
        minDiffPercent: 0 as number | string,
        isInitialMinDiffFilled: false,

        autoSendOrder: false,
        remainingAmount: 0 as number | string,
        isHedgingParamLocked: false,

        buyPriceBN: null as BigNumber,

        sellPriceBN: null as BigNumber,

        displayPriceDiff: "",
        displayPriceDiffPercent: "",
        displayPnl: "",

        sendOrdersBtnDisabled: true,

        tradesTableFields: [
          {
            key: "sendAt",
            label: "Date",
            thClass: "text-nowrap",
            tdClass: "text-nowrap",
            formatter: value => DateTime.fromMillis(value).toFormat("yyyy-MM-dd HH:mm:ss")
          },
          {
            key: "amount",
            label: "Amount",
            thClass: "text-nowrap",
            tdClass: "text-nowrap text-monospace",
            formatter: value => BigNumber(value).toFixed()
          },
          {
            key: "buyPrice",
            label: "Buy price",
            thClass: "text-nowrap",
            tdClass: "text-nowrap text-monospace text-success",
            formatter: value => value ? BigNumber(value).precision(6, BigNumber.ROUND_UP).toFixed() : "-"
          },
          {
            key: "sellPrice",
            label: "Sell price",
            thClass: "text-nowrap",
            tdClass: "text-nowrap text-monospace text-danger",
            formatter: value => value ? BigNumber(value).precision(6, BigNumber.ROUND_DOWN).toFixed() : "-"
          },
          {
            key: "priceDiff",
            label: "Price diff",
            thClass: "text-nowrap",
            tdClass(value) {
              const classes = ["text-nowrap", "text-monospace"];
              if (value < 0) {
                classes.push("text-danger");
              } else if (value > 0) {
                classes.push("text-success");
              }
              return classes;
            },
            formatter: value => value && BigNumber(value).precision(6, BigNumber.ROUND_DOWN).toFixed()
          },
          {
            key: "pnl",
            label: "PnL",
            thClass: "text-nowrap",
            tdClass(value) {
              const classes = ["text-nowrap", "text-monospace"];
              if (value < 0) {
                classes.push("text-danger");
              } else if (value > 0) {
                classes.push("text-success");
              }
              return classes;
            },
            formatter: value => value && BigNumber(value).precision(6, BigNumber.ROUND_DOWN).toFixed()
          },
          {
            key: "pnlPercent",
            label: "PnL %",
            thClass: "text-nowrap",
            tdClass(value, key, item) {
              const pnl = item.pnl;
              const classes = ["text-nowrap", "text-monospace"];
              if (pnl < 0) {
                classes.push("text-danger");
              } else if (pnl > 0) {
                classes.push("text-success");
              }
              return classes;
            },
            formatter(value, key, item) {
              const pnl = item.pnl;
              if (pnl) {
                const buyNotionalBN = BigNumber(item.amount).multipliedBy(item.buyPrice);
                const diffPercentBN = BigNumber(pnl).div(buyNotionalBN).multipliedBy(100);
                return diffPercentBN.toFixed(4) + "%";
              }
            }
          },
        ] as BvTableFieldArray,

        trades: [
          // { sendAt: Date.now(), amount: 200, buyPrice: 271.093, sellPrice: 271.005, priceDiff: -0.088, diffPercent: -0.0324, pnl: -17.6, tradeFee: 1 }
        ],

        itemsPerPageOptions: [10, 20, 50, 100, 200],
        itemsPerPage: 10,
        currentPage: 1,

        isSendingOrder: false,
      };
    },

    computed: {
      displayAcc1OrderBook() {
        return this.acc1.orderBook.slice(0, 40);
      },
      displayAcc2OrderBook() {
        return this.acc2.orderBook.slice(0, 40);
      },
      displayBaseAsset() {
        let base1 = this.acc1.baseAsset;
        if (base1.startsWith("1000")) base1 = base1.replace(priceMultipleTickerPattern, "");
        let base2 = this.acc2.baseAsset;
        if (base2.startsWith("1000")) base2 = base2.replace(priceMultipleTickerPattern, "");

        return base1 === base2 ? base1 : "";
      },
      displayQuoteAsset() {
        const quote1 = this.acc1.quoteAsset;
        const quote2 = this.acc2.quoteAsset;
        return quote1.toUpperCase() === quote2.toUpperCase() ? quote1 : "USD";
      },
      doneSetupBtnDisabled() {
        const { acc1, acc2 } = this;
        return !(
          acc1.cexAccountId && acc2.cexAccountId && acc1.symbol && acc2.symbol && // every param must be selected
          (acc1.cexAccountId !== acc2.cexAccountId || acc1.type !== acc2.type) // must be different acc OR different market type
        );
      },
      reverseButtonDisabled() {
        return this.isHedgingParamLocked && this.autoSendOrder;
      },
      amountStep() {
        return Math.max(this.acc1.amountStep, this.acc2.amountStep);
      },
      priceStep() {
        return Math.min(this.acc1.priceStep, this.acc2.priceStep);
      },
      displayBuyPrice() {
        return this.buyPriceBN && this.buyPriceBN.precision(6, BigNumber.ROUND_UP).toFixed() || "-";
      },
      displaySellPrice() {
        return this.sellPriceBN && this.sellPriceBN.precision(6, BigNumber.ROUND_DOWN).toFixed() || "-";
      },
      displayNotionalValue() {
        const amountBN = BigNumber(this.amount);
        const highestBidPriceBN = this.acc1.orderBook[0]?.priceBN;
        const lowestAskPriceBN = this.acc2.orderBook[0]?.priceBN;
        if (amountBN?.gt(0) && highestBidPriceBN?.gt(0) && lowestAskPriceBN?.gt(0)) {
          const value = BigNumber.sum(highestBidPriceBN, lowestAskPriceBN).div(2)
            .multipliedBy(amountBN)
            .decimalPlaces(2)
            .toNumber();
          return utils.formatUsdValue(value) + " " + this.displayQuoteAsset;
        }
        return "0 " + this.displayQuoteAsset;
      },
      tradesTotalBuyNotionalValueBN() {
        let bn = BigNumber(0);
        for (const t of this.trades) {
          if (t.buyPrice && t.sellPrice) {
            bn = bn.plus(BigNumber(t.buyPrice).multipliedBy(t.amount));
          }
        }
        return bn;
      },
      tradesTotalSellNotionalValueBN() {
        let bn = BigNumber(0);
        for (const t of this.trades) {
          if (t.buyPrice && t.sellPrice) {
            bn = bn.plus(BigNumber(t.sellPrice).multipliedBy(t.amount));
          }
        }
        return bn;
      },
      tradesTotalTradeFeeBN() {
        let bn = BigNumber(0);
        for (const t of this.trades) {
          if (t.tradeFee) {
            bn = bn.plus(t.tradeFee);
          }
        }
        return bn;
      },
      tradesTotalPnlBN() {
        let bn = BigNumber(0);
        for (const t of this.trades) {
          if (t.pnl) {
            bn = bn.plus(t.pnl);
          }
        }
        return bn;
      },
      displayTradesTotalPnlTextClass() {
        if (this.tradesTotalPnlBN.gt(0)) {
          return "text-success";
        } else if (this.tradesTotalPnlBN.lt(0)) {
          return "text-danger";
        } else {
          return "";
        }
      },
      displayTradesTotalPnl() {
        return this.tradesTotalPnlBN.decimalPlaces(2, BigNumber.ROUND_DOWN).toFixed();
      },
      displayTradesTotalPnlPercent() {
        if (this.tradesTotalBuyNotionalValueBN.gt(0)) {
          return this.tradesTotalPnlBN.div(this.tradesTotalBuyNotionalValueBN).multipliedBy(100).toFixed(4);
        } else {
          return "0";
        }
      },
    },

    mounted() {
      document.title = "CEX Hedging";
      this.fetchAccounts();
    },

    methods: {
      async fetchAccounts() {
        try {
          await utils.delay(0);
          this.showLoading();
          const accounts = await cexAccountService.getAll();
          this.cexAccounts = accounts.filter(a => supportExchanges.includes(a.exchange));

          const savedSettings = JSON.parse(localStorage.getItem("simpleHedging"));

          this.acc1.type = savedSettings?.buy?.type || "spot";
          this.acc2.type = savedSettings?.sell?.type || "futures";

          const buyCexAccount = accounts.find(a => a.id === savedSettings?.buy?.cexAccountId);
          if (buyCexAccount) {
            this.acc1.cexAccountId = buyCexAccount.id;
            await this.onSelectAccountAcc1();
            const market = this.acc1.markets.find(m => m.symbol === savedSettings?.buy?.symbol);
            if (market) {
              this.onPickMarketAcc1(market);
            }
          }
          const sellCexAccount = accounts.find(a => a.id === savedSettings?.sell?.cexAccountId);
          if (sellCexAccount) {
            this.acc2.cexAccountId = sellCexAccount.id;
            await this.onSelectAccountAcc2();
            const market = this.acc2.markets.find(m => m.symbol === savedSettings?.sell?.symbol);
            if (market) {
              this.onPickMarketAcc2(market);
            }
          }

        } catch (e) {
          console.error(e);
          this.toastError(e);

        } finally {
          this.hideLoading();
        }
      },

      async onSelectAccount(acc) {
        // reset value
        acc.markets = [];
        this.clearSelectedMarket(acc);

        if (acc.cexAccountId) {
          const cexAccount = this.cexAccounts.find(it => it.id === acc.cexAccountId);
          acc.accExchange = cexAccount.exchange;
          acc.marketExchange = cexAccount.exchange;
          if (acc.type === "futures") {
            acc.marketExchange += "-futures";
          }
          const markets = await marketDataService.getCexMarkets({ exchange: acc.marketExchange });
          acc.markets = markets.filter(m =>
            !utils.isLeveragedTokenTicker(m.baseAsset) &&
            constants.stableCoinSymbols.includes(m.quoteAsset.toUpperCase())
          );
        }
      },
      onSelectAccountAcc1() {
        return this.onSelectAccount(this.acc1);
      },
      onSelectAccountAcc2() {
        return this.onSelectAccount(this.acc2);
      },

      marketSelectDropdownFilter(options: any[], search: string) {
        if (!search) return [];
        search = utils.sanitizeSearchText(search).toLowerCase().replace(/[^a-z]/g, "");
        return options.filter(market => market.symbol.toLowerCase().replace(/[^a-z]/g, "").startsWith(search));
      },


      onPickMarket(acc, market) {
        acc.symbol = market.symbol;
        acc.baseAsset = market.baseAsset;
        acc.quoteAsset = market.quoteAsset;
        acc.amountMultiplier = market.amountMultiplier;
        acc.priceMultiplier = market.baseAssetPriceMultiplier;
        acc.priceStep = BigNumber(market.priceStep).div(acc.priceMultiplier).toNumber();
        acc.amountStep = BigNumber(market.amountStep).multipliedBy(acc.priceMultiplier).toNumber();
      },
      onPickMarketAcc1(market) {
        return this.onPickMarket(this.acc1, market);
      },
      onPickMarketAcc2(market) {
        return this.onPickMarket(this.acc2, market);
      },

      clearSelectedMarket(acc) {
        acc.searchMarket = "";
        acc.symbol = "";
        acc.baseAsset = "";
        acc.quoteAsset = "";
        acc.priceStep = 0;
        acc.amountStep = 0;
        acc.priceMultiplier = 1;
      },
      clearSelectedMarketAcc1() {
        return this.clearSelectedMarket(this.acc1);
      },
      clearSelectedMarketAcc2() {
        return this.clearSelectedMarket(this.acc2);
      },

      onClickPriceMultiplier(acc) {
        if (acc.symbol && !this.doneSetup) {
          this.editingSide = acc;
          this.$bvModal.show("cex-hedging-edit-price-multiplier-modal");
        }
      },
      applyPriceMultiplier(priceMultiplier) {
        const acc = this.editingSide;
        const market = acc.markets.find(it => it.symbol === acc.symbol);
        acc.priceMultiplier = priceMultiplier;
        acc.priceStep = BigNumber(market.priceStep).div(acc.priceMultiplier).toNumber();
        acc.amountStep = BigNumber(market.amountStep).multipliedBy(acc.priceMultiplier).toNumber();
        console.log("applyPriceMultiplier", market.exchange, market.symbol, "priceStep", acc.priceStep, "amountStep", acc.amountStep);
      },

      onClickReverseSides() {
        if (this.doneSetup) {
          this.$bvModal.show("cex-hedging-confirm-reverse-market-modal");
        } else {
          this.reverseSides();
        }
      },

      reverseSides() {
        const tmp = this.acc1;
        this.acc1 = this.acc2;
        this.acc2 = tmp;

        this.minDiff = BigNumber(this.minDiff).negated().toFixed();
        this.minDiffPercent = BigNumber(this.minDiffPercent).negated().toFixed();
        this.acc1.orderBook = [];
        this.acc2.orderBook = [];
        this.reconnectWs();
        this.refreshHedgingStats();
      },

      onClickDoneSetup() {
        this.doneSetup = true;
        this.saveSettings();
        this.connectOrderBook();
      },

      saveSettings() {
        localStorage.setItem("simpleHedging", JSON.stringify({
          buy: _.pick(this.acc1, ["cexAccountId", "type", "symbol", "marginMode"]),
          sell: _.pick(this.acc2, ["cexAccountId", "type", "symbol", "marginMode"])
        }));
      },

      async connectOrderBook() {
        const accs = [this.acc1, this.acc2];

        for (const acc of accs) {
          const priceDecimals = BigNumber(acc.priceStep).decimalPlaces();
          const amountDecimals = BigNumber(acc.amountStep).decimalPlaces();
          const convertOrderBook = (items: (number | string)[][]) => {
            let totalAmountBN = BigNumber(0);
            const ret = [];
            for (const item of items) {
              const priceBN = BigNumber(item[0]).div(acc.priceMultiplier);
              const price = priceBN.toNumber();
              const displayPrice = priceBN.toFixed(priceDecimals);
              const amountBN = BigNumber(item[1]).multipliedBy(acc.priceMultiplier).multipliedBy(acc.amountMultiplier);
              const displayAmount = amountBN.toFixed(amountDecimals);
              totalAmountBN = totalAmountBN.plus(amountBN);
              const displayTotalAmount = totalAmountBN.toFixed(amountDecimals);
              ret.push({ priceBN, price, displayPrice, amountBN, displayAmount, totalAmountBN, displayTotalAmount });
            }
            return ret;
          };
          const mergeOrderBook = (items: (number | string)[][]) => {
            const orderBookKeyed = _.keyBy(acc.orderBook, "price");

            for (const item of items) {
              const priceBN = BigNumber(item[0]).div(acc.priceMultiplier);
              const price = priceBN.toNumber();
              const amountBN = BigNumber(item[1]).multipliedBy(acc.priceMultiplier).multipliedBy(acc.amountMultiplier);
              if (amountBN.gt(0)) {
                const displayPrice = priceBN.toFixed(priceDecimals);
                const displayAmount = amountBN.toFixed(amountDecimals);
                orderBookKeyed[price] = { priceBN, price, displayPrice, amountBN, displayAmount };
              } else {
                delete orderBookKeyed[price];
              }
            }

            const priceOrder = acc === this.acc1 ? "asc" : "desc";
            const orderBookSorted = _.orderBy(Object.values(orderBookKeyed), ["price"], [priceOrder]);

            let totalAmountBN = BigNumber(0);
            for (const item of orderBookSorted) {
              totalAmountBN = totalAmountBN.plus(item.amountBN);
              item.totalAmountBN = totalAmountBN;
              item.displayTotalAmount = totalAmountBN.toFixed(amountDecimals);
            }

            return orderBookSorted;
          };

          this.wsStartConnectTs = Date.now();

          if (acc.accExchange === "binance") {
            const symbolL = acc.symbol.toLowerCase();
            if (acc.type === "spot") {
              const ws = new WebSocket(`wss://data-stream.binance.vision/stream?streams=${symbolL}@depth20@100ms`);
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({
                      method: "LIST_SUBSCRIPTIONS",
                      id: _.random(0, 1e9)
                    }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.stream?.startsWith(`${symbolL}@depth20`)) {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.data.asks);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.data.bids);
                  }
                  acc.orderBookTs = Date.now();

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };

            } else if (acc.type === "futures") {
              const ws = new WebSocket(`wss://fstream.binance.com/stream?streams=${symbolL}@depth20@100ms`);
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({
                      method: "LIST_SUBSCRIPTIONS",
                      id: _.random(0, 1e9)
                    }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.stream?.startsWith(`${symbolL}@depth20`)) {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.data.a);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.data.b);
                  }
                  acc.orderBookTs = dataObj.data.E;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };
            }

          } else if (acc.accExchange === "bybit") {
            const ws = new WebSocket("wss://stream.bybit.com/v5/public/" + (acc.type === "spot" ? "spot" : "linear"));
            acc.ws = ws;
            ws.onopen = () => {
              console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
              acc.wsHeartbeatIntervalHandler = setInterval(() => {
                if (this.verbose) {
                  console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                }
                if (acc.wsIsAlive) {
                  // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                  acc.wsIsAlive = false;
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                  }
                  ws.send(JSON.stringify({ op: "ping" }));
                } else {
                  console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                  this.reconnectWs();
                }
              }, constants.HEARTBEAT_INTERVAL);
              ws.send(JSON.stringify({
                op: "subscribe",
                args: ["orderbook.200." + acc.symbol]
              }));
            };
            ws.onmessage = messageEvent => {
              acc.wsIsAlive = true;
              const dataObj = JSON.parse(messageEvent.data);
              if (dataObj.topic === "orderbook.200." + acc.symbol) {
                if (acc === this.acc1) {
                  // buy to the asks
                  if (dataObj.type === "snapshot") {
                    acc.orderBook = convertOrderBook(dataObj.data.a);
                  } else {
                    acc.orderBook = mergeOrderBook(dataObj.data.a);
                  }
                } else if (acc === this.acc2) {
                  // sell to the bids
                  if (dataObj.type === "snapshot") {
                    acc.orderBook = convertOrderBook(dataObj.data.b);
                  } else {
                    acc.orderBook = mergeOrderBook(dataObj.data.b);
                  }
                }
                acc.orderBookTs = Date.now();

                this.checkAndFillInitialMinDiff();
                if (this.isHedgingParamLocked) {
                  this.refreshHedgingStats();
                }
              }
            };
            ws.onclose = (event) => {
              console.log(event);
              this.reconnectWs();
            };

          } else if (acc.accExchange === "okx") {
            const ws = new WebSocket("wss://wsaws.okx.com:8443/ws/v5/public");
            acc.ws = ws;
            ws.onopen = () => {
              console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
              acc.wsHeartbeatIntervalHandler = setInterval(() => {
                if (this.verbose) {
                  console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                }
                if (acc.wsIsAlive) {
                  // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                  acc.wsIsAlive = false;
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                  }
                  ws.send("ping");
                } else {
                  console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                  this.reconnectWs();
                }
              }, constants.HEARTBEAT_INTERVAL);
              ws.send(JSON.stringify({
                op: "subscribe",
                args: [{ channel: "books", instId: acc.symbol }]
              }));
            };
            ws.onmessage = messageEvent => {
              acc.wsIsAlive = true;
              if (messageEvent.data === "pong") {
                return;
              }
              const {arg, action, data} = JSON.parse(messageEvent.data);
              if (arg.channel === "books" && Array.isArray(data) && Array.isArray(data[0].bids) && Array.isArray(data[0].asks)) {
                if (acc === this.acc1) {
                  // buy to the asks
                  if (action === "snapshot" || data[0].prevSeqId === -1) {
                    acc.orderBook = convertOrderBook(data[0].asks);
                  } else {
                    acc.orderBook = mergeOrderBook(data[0].asks);
                  }
                } else if (acc === this.acc2) {
                  // sell to the bids
                  if (action === "snapshot" || data[0].prevSeqId === -1) {
                    acc.orderBook = convertOrderBook(data[0].bids);
                  } else {
                    acc.orderBook = mergeOrderBook(data[0].bids);
                  }
                }
                acc.orderBookTs = Date.now();

                this.checkAndFillInitialMinDiff();
                if (this.isHedgingParamLocked) {
                  this.refreshHedgingStats();
                }
              }
            };
            ws.onclose = (event) => {
              console.log(event);
              this.reconnectWs();
            };

          } else if (acc.accExchange === "kucoin") {
            if (acc.type === "spot") {
              const _g = this.wsStartConnectTs;
              const wsConnectInfo = (await axios.post("/proxy/api.kucoin.com/api/v1/bullet-public")).data.data;
              if (_g !== this.wsStartConnectTs) return; // async guard

              const wsEndpoint = wsConnectInfo.instanceServers[0].endpoint;
              const token = wsConnectInfo.token;

              const ws = new WebSocket(`${wsEndpoint}?token=${token}`);
              acc.ws = ws;

              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ id: utils.randomStr(4), type: "ping" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  id: utils.randomStr(4),
                  type: "subscribe",
                  topic: "/spotMarket/level2Depth50:" + acc.symbol,
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.topic === "/spotMarket/level2Depth50:" + acc.symbol) {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.data.asks);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.data.bids);
                  }
                  acc.orderBookTs = dataObj.data.timestamp;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };

            } else if (acc.type === "futures") {
              const _g = this.wsStartConnectTs;
              const wsConnectInfo = (await axios.post("/proxy/api-futures.kucoin.com/api/v1/bullet-public")).data.data;
              if (_g !== this.wsStartConnectTs) return; // async guard

              const wsEndpoint = wsConnectInfo.instanceServers[0].endpoint;
              const token = wsConnectInfo.token;

              const ws = new WebSocket(`${wsEndpoint}?token=${token}`);
              acc.ws = ws;

              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ id: utils.randomStr(4), type: "ping" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  id: utils.randomStr(4),
                  type: "subscribe",
                  topic: "/contractMarket/level2Depth50:" + acc.symbol,
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.topic === "/contractMarket/level2Depth50:" + acc.symbol) {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.data.asks);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.data.bids);
                  }
                  acc.orderBookTs = dataObj.data.timestamp;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };
            }

          } else if (acc.accExchange === "gate") {
            if (acc.type === "spot") {
              const ws = new WebSocket("wss://api.gateio.ws/ws/v4/");
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ channel: "spot.ping" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  channel: "spot.order_book",
                  event: "subscribe",
                  payload: [acc.symbol, "100", "100ms"]
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.channel === "spot.order_book" && dataObj.event === "update") {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.result.asks);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.result.bids);
                  }
                  acc.orderBookTs = dataObj.result.t;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };

            } else if (acc.type === "futures") {
              const ws = new WebSocket("wss://fx-ws.gateio.ws/v4/ws/usdt");
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ channel: "futures.ping" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  channel: "futures.order_book",
                  event: "subscribe",
                  payload: [acc.symbol, "100", "0"]
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.channel === "futures.order_book" && dataObj.event === "all") {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.result.asks.map(a => [a.p, a.s]));
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.result.bids.map(b => [b.p, b.s]));
                  }
                  acc.orderBookTs = dataObj.result.t;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };
            }

          } else if (acc.accExchange === "bitget") {
            const ws = new WebSocket("wss://ws.bitget.com/v2/ws/public");
            acc.ws = ws;
            const instType = acc.type === "spot" ? "SPOT" : "USDT-FUTURES";
            ws.onopen = () => {
              console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
              acc.wsHeartbeatIntervalHandler = setInterval(() => {
                if (this.verbose) {
                  console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                }
                if (acc.wsIsAlive) {
                  // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                  acc.wsIsAlive = false;
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                  }
                  ws.send("ping");
                } else {
                  console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                  this.reconnectWs();
                }
              }, constants.HEARTBEAT_INTERVAL);
              ws.send(JSON.stringify({
                "op": "subscribe",
                "args": [
                  {
                    instType,
                    channel: "books",
                    instId: acc.symbol
                  }
                ]
              }));
            };
            ws.onmessage = messageEvent => {
              acc.wsIsAlive = true;
              if (messageEvent.data === "pong") {
                return;
              }
              const dataObj = JSON.parse(messageEvent.data);
              if (dataObj.arg?.instType === instType && dataObj.arg.instId === acc.symbol) {
                if (acc === this.acc1) {
                  // buy to the asks
                  if (dataObj.action === "snapshot") {
                    acc.orderBook = convertOrderBook(dataObj.data[0].asks);
                  } else if (dataObj.action === "update") {
                    acc.orderBook = mergeOrderBook(dataObj.data[0].asks);
                  }
                } else if (acc === this.acc2) {
                  // sell to the bids
                  if (dataObj.type === "snapshot") {
                    acc.orderBook = convertOrderBook(dataObj.data[0].bids);
                  } else if (dataObj.action === "update") {
                    acc.orderBook = mergeOrderBook(dataObj.data[0].bids);
                  }
                }
                acc.orderBookTs = Date.now();

                this.checkAndFillInitialMinDiff();
                if (this.isHedgingParamLocked) {
                  this.refreshHedgingStats();
                }
              }
            };
            ws.onclose = (event) => {
              console.log(event);
              this.reconnectWs();
            };

          } else if (acc.accExchange === "mexc") {
            if (acc.type === "spot") {
              const ws = new WebSocket("wss://wbs.mexc.com/ws");
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ method: "PING" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  method: "SUBSCRIPTION",
                  params: [
                    `spot@public.limit.depth.v3.api@${acc.symbol}@20`
                  ]
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.c === `spot@public.limit.depth.v3.api@${acc.symbol}@20`) {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.d.asks.map(a => [a.p, a.v]));
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.d.bids.map(b => [b.p, b.v]));
                  }
                  acc.orderBookTs = dataObj.t;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };

            } else if (acc.type === "futures") {
              const ws = new WebSocket("wss://contract.mexc.com/edge");
              acc.ws = ws;
              ws.onopen = () => {
                console.log(`${acc.marketExchange} ${acc.symbol} ws open`);
                acc.wsHeartbeatIntervalHandler = setInterval(() => {
                  if (this.verbose) {
                    console.log(`${acc.marketExchange} ws heartBeat: isAlive =`, acc.wsIsAlive);
                  }
                  if (acc.wsIsAlive) {
                    // temporarily set isAlive = false and send ping, when pong received, it is set to true again
                    acc.wsIsAlive = false;
                    if (this.verbose) {
                      console.log(`${acc.marketExchange} ws heartBeat: sending ping`);
                    }
                    ws.send(JSON.stringify({ method: "ping" }));
                  } else {
                    console.error(`${acc.marketExchange} ws heartBeat: terminate`);
                    this.reconnectWs();
                  }
                }, constants.HEARTBEAT_INTERVAL);
                ws.send(JSON.stringify({
                  method: "sub.depth.full",
                  param: {
                    symbol: acc.symbol,
                    limit: 20
                  }
                }));
              };
              ws.onmessage = messageEvent => {
                acc.wsIsAlive = true;
                const dataObj = JSON.parse(messageEvent.data);
                if (dataObj.channel === "push.depth.full") {
                  if (acc === this.acc1) {
                    // buy to the asks
                    acc.orderBook = convertOrderBook(dataObj.data.asks);
                  } else if (acc === this.acc2) {
                    // sell to the bids
                    acc.orderBook = convertOrderBook(dataObj.data.bids);
                  }
                  acc.orderBookTs = dataObj.ts;

                  this.checkAndFillInitialMinDiff();
                  if (this.isHedgingParamLocked) {
                    this.refreshHedgingStats();
                  }
                }
              };
              ws.onclose = (event) => {
                console.log(event);
                this.reconnectWs();
              };
            }
          }
        }
      },

      reconnectWs() {
        const accs = [this.acc1, this.acc2];
        for (const acc of accs) {
          const ws: WebSocket = acc.ws;
          if (ws) {
            ws.onopen = null;
            ws.onmessage = null;
            ws.onclose = null;
            ws.close();
          }
          clearInterval(acc.wsHeartbeatIntervalHandler);
        }
        this.connectOrderBook();
      },

      beforeSubmitHedgingParamsForm() {
        this.amount = BigNumber(this.amount).toFixed();
        this.minDiff = BigNumber(this.minDiff).toFixed();
        this.minDiffPercent = BigNumber(this.minDiffPercent).toFixed();
        this.remainingAmount = BigNumber(this.remainingAmount).toFixed();
      },

      async onSubmitHedgingParamsForm(e: Event) {
        console.log("onSubmitHedgingParamsForm", e);
        if (this.autoSendOrder) {
          const message = this.isMinDiffPercent ?
            `Automatically send hedging orders of size ${this.amount} ${this.displayBaseAsset}, one by one, when price diff is at least ${this.minDiffPercent}%, for a total of ${this.remainingAmount} ${this.displayBaseAsset}` :
            `Automatically send hedging orders of size ${this.amount} ${this.displayBaseAsset}, one by one, when price diff is at least ${this.minDiff} ${this.displayQuoteAsset}, for a total of ${this.remainingAmount} ${this.displayBaseAsset}`;
          const ok = await this.$bvModal.msgBoxConfirm(message, {
            title: "Confirm auto send orders",
            noFade: true
          });
          this.isHedgingParamLocked = ok === true;
        } else {
          this.isHedgingParamLocked = true;
        }
      },

      onClickEditHedgingParams() {
        console.log("onClickEditHedgingParams");
        this.isHedgingParamLocked = false;
      },

      checkAndFillInitialMinDiff() {
        if (this.minDiff === 0 && !this.isInitialMinDiffFilled && this.acc1.orderBook.length && this.acc2.orderBook.length) {
          this.fillCurrentMarketBBO();
          this.isInitialMinDiffFilled = true;
        }
      },

      fillCurrentMarketBBO() {
        this.minDiff = this.acc2.orderBook[0].priceBN.minus(this.acc1.orderBook[0].priceBN).toFixed();
        this.minDiffPercent = this.acc2.orderBook[0].priceBN
          .div(this.acc1.orderBook[0].priceBN)
          .minus(1)
          .multipliedBy(100)
          .decimalPlaces(4)
          .toFixed();
        this.amount = BigNumber.min(this.acc1.orderBook[0].amountBN, this.acc2.orderBook[0].amountBN)
          .dividedBy(this.amountStep)
          .decimalPlaces(0, BigNumber.ROUND_DOWN)
          .multipliedBy(this.amountStep)
          .toNumber();
      },

      onClickMarketBBO() {
        if (!this.isHedgingParamLocked) {
          this.fillCurrentMarketBBO();
        }
      },

      refreshHedgingStats() {
        if (!this.isHedgingParamLocked) return;

        const amountBN = BigNumber(this.amount);

        let buyRemainingAmount = amountBN;
        let buyTotalQuote = BigNumber(0);
        for (const item of this.acc1.orderBook) {
          if (item.amountBN.lt(buyRemainingAmount)) {
            buyTotalQuote = buyTotalQuote.plus(item.amountBN.multipliedBy(item.priceBN));
            buyRemainingAmount = buyRemainingAmount.minus(item.amountBN);
          } else {
            buyTotalQuote = buyTotalQuote.plus(buyRemainingAmount.multipliedBy(item.priceBN));
            buyRemainingAmount = BigNumber(0);
            break;
          }
        }
        if (buyRemainingAmount.gt(0)) {
          this.showNullStats();
          return;
        }

        let sellRemainingAmount = amountBN;
        let sellTotalQuote = BigNumber(0);
        for (const item of this.acc2.orderBook) {
          if (item.amountBN.lt(sellRemainingAmount)) {
            sellTotalQuote = sellTotalQuote.plus(item.amountBN.multipliedBy(item.priceBN));
            sellRemainingAmount = sellRemainingAmount.minus(item.amountBN);
          } else {
            sellTotalQuote = sellTotalQuote.plus(sellRemainingAmount.multipliedBy(item.priceBN));
            sellRemainingAmount = BigNumber(0);
            break;
          }
        }
        if (sellRemainingAmount.gt(0)) {
          this.showNullStats();
          return;
        }


        const buyPriceBN = buyTotalQuote.div(amountBN);
        this.buyPriceBN = buyPriceBN;
        const sellPriceBN = sellTotalQuote.div(amountBN);
        this.sellPriceBN = sellPriceBN;

        const diffBN = sellPriceBN.minus(buyPriceBN);
        this.displayPriceDiff = diffBN.precision(6, BigNumber.ROUND_DOWN).toFixed();

        const diffPercentBN = diffBN.div(buyPriceBN).multipliedBy(100);
        this.displayPriceDiffPercent = diffPercentBN.toFixed(4, BigNumber.ROUND_DOWN);

        this.displayPnl = sellTotalQuote.minus(buyTotalQuote).precision(6, BigNumber.ROUND_DOWN).toFixed();

        const canSendOrder = this.isMinDiffPercent ? diffPercentBN.gte(this.minDiffPercent) : diffBN.gte(this.minDiff);
        this.sendOrdersBtnDisabled = !canSendOrder;

        if (canSendOrder && this.autoSendOrder && !this.isSendingOrder) {
          this.sendOrder();
        }
      },

      showNullStats() {
        this.buyPriceBN = null;
        this.sellPriceBN = null;
        this.displayPriceDiff = "-";
        this.displayPriceDiffPercent = "-";
        this.displayPnl = "-";
        this.sendOrdersBtnDisabled = true;
      },

      async sendOrder() {
        if (this.isSendingOrder) return;

        try {
          this.isSendingOrder = true;

          const amountBN = this.autoSendOrder ? BigNumber.min(this.amount, this.remainingAmount) : BigNumber(this.amount);
          const amount = amountBN.toNumber();

          if (amountBN.gt(0)) {
            const randomStr = utils.randomStr(8);
            const sendHedgingOrderResult = await cexHedgingService.sendCexHedgingOrders({
              amount,
              buy: {
                clientOrderId: "ArbTraderHedgeBuy" + randomStr,
                cexAccountId: this.acc1.cexAccountId,
                exchange: this.acc1.marketExchange,
                // @ts-ignore
                type: this.acc1.type,
                symbol: this.acc1.symbol,
                priceMultiplier: this.acc1.priceMultiplier,
                // @ts-ignore
                marginMode: this.acc1.marketExchange === "okx-futures" ? this.acc1.marginMode : undefined,
                leverage: this.acc1.marketExchange === "kucoin-futures" ? this.acc1.leverage : undefined,
                reduceOnly: this.acc1.type === "futures" ? this.acc1.reduceOnly : undefined,
                refPrice: this.buyPriceBN.toNumber(),
                dryRun: this.acc1.dryRun,
              },
              sell: {
                clientOrderId: "ArbTraderHedgeSell" + randomStr,
                cexAccountId: this.acc2.cexAccountId,
                exchange: this.acc2.marketExchange,
                // @ts-ignore
                type: this.acc2.type,
                symbol: this.acc2.symbol,
                priceMultiplier: this.acc2.priceMultiplier,
                // @ts-ignore
                marginMode: this.acc2.marketExchange === "okx-futures" ? this.acc2.marginMode : undefined,
                leverage: this.acc2.marketExchange === "kucoin-futures" ? this.acc2.leverage : undefined,
                reduceOnly: this.acc2.type === "futures" ? this.acc2.reduceOnly : undefined,
                refPrice: this.sellPriceBN.toNumber(),
                dryRun: this.acc2.dryRun,
              }
            });

            this.trades.unshift({
              id: sendHedgingOrderResult.id,
              sendAt: sendHedgingOrderResult.sendAt,
              amount,
              buyPrice: sendHedgingOrderResult.buyPrice,
              buyErrorMessage: sendHedgingOrderResult.buyOrderResponse.message,
              // displayBuyPrice: sendHedgingOrderResult.buyPrice ? BigNumber(sendHedgingOrderResult.buyPrice).precision(6, BigNumber.ROUND_UP).toFixed() : "-",
              sellPrice: sendHedgingOrderResult.sellPrice,
              sellErrorMessage: sendHedgingOrderResult.sellOrderResponse.message,
              // displaySellPrice: sendHedgingOrderResult.sellPrice ? BigNumber(sendHedgingOrderResult.sellPrice).precision(6, BigNumber.ROUND_DOWN).toFixed() : "-",
              priceDiff: sendHedgingOrderResult.priceDiff,
              tradeFee: sendHedgingOrderResult.tradeFee,
              pnl: sendHedgingOrderResult.pnl,
            });

            if (this.autoSendOrder) {
              const success = sendHedgingOrderResult.buyPrice && sendHedgingOrderResult.sellPrice;
              if (success) {
                const remainingAmountBN = BigNumber(this.remainingAmount).minus(amountBN);
                this.remainingAmount = remainingAmountBN.toNumber();
                if (remainingAmountBN.lte(0)) {
                  this.isHedgingParamLocked = false;
                }
              } else {
                this.isHedgingParamLocked = false;
              }
            }
          }

        } catch (e) {
          console.error(e);

        } finally {
          this.isSendingOrder = false;
        }
      },
    },

    unmounted() {
      const accs = [this.acc1, this.acc2];
      for (const acc of accs) {
        const ws: WebSocket = acc.ws;
        if (ws) {
          ws.onopen = null;
          ws.onmessage = null;
          ws.onclose = null;
          ws.close();
        }
        clearInterval(acc.wsHeartbeatIntervalHandler);
      }
    }
  };

</script>
