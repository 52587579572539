<template>
  <div>
    <b-form @keydown.enter.prevent @submit.prevent="submitForm">
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Account</b-col>
        <b-col cols="8">
          <b-form-select :options="cexAccountOptions" v-model="cexAccountId"></b-form-select>
        </b-col>
      </b-row>
      <template v-if="selectedCexAccount">
        <b-row class="mb-3" v-if="['binance'].includes(selectedCexAccount.exchange)">
          <b-col cols="4" class="text-right mt-2"></b-col>
          <b-col cols="8">
            <b-form-checkbox v-model="transferFromSpotAccount">Transfer from Spot</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4" class="text-right mt-2"></b-col>
          <b-col cols="8">
            <b-form-checkbox v-model="transferFromFundingAccount">Transfer from Funding</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-3" v-if="transferFromFundingAccount || transferFromSpotAccount">
          <b-col cols="4" class="text-right mt-2">Initial leverage</b-col>
          <b-col cols="8">
            <b-form-spinbutton v-model="leverage" min="1" max="10"></b-form-spinbutton>
          </b-col>
        </b-row>
        <div v-if="formErrorMsg" class="text-danger text-center">{{ formErrorMsg }}</div>
        <div class="text-center mt-4">
          <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Save</b-button>
        </div>
      </template>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as delistTradeWatchOrderService from "@/services/delistTradeWatchOrderService";

  export default {
    name: "DelistTradeAccountSettingsModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId"],
    emits: ["done"],

    data() {
      return {
        cexAccounts: [],
        cexAccountId: 0,
        accountSettings: {},
        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    computed: {
      cexAccountOptions() {
        return this.cexAccounts.map(a => ({ value: a.id, text: a.label }));
      },
      selectedCexAccount() {
        return this.cexAccounts.find(a => a.id === this.cexAccountId);
      },

      transferFromSpotAccount: {
        get() {
          return this.accountSettings[this.cexAccountId]?.transferFromSpotAccount;
        },
        set(value: boolean) {
          _.set(this.accountSettings, [this.cexAccountId, "transferFromSpotAccount"], value);
          this.$forceUpdate();
        }
      },

      transferFromFundingAccount: {
        get() {
          return this.accountSettings[this.cexAccountId]?.transferFromFundingAccount;
        },
        set(value: boolean) {
          _.set(this.accountSettings, [this.cexAccountId, "transferFromFundingAccount"], value);
          this.$forceUpdate();
        }
      },

      leverage: {
        get() {
          return this.accountSettings[this.cexAccountId]?.leverage;
        },
        set(value: number) {
          _.set(this.accountSettings, [this.cexAccountId, "leverage"], value);
        }
      },
    },

    mounted() {
      this.setup();
    },

    methods: {
      async setup() {
        try {
          const fetchCexAccounts = cexAccountService.getAll();
          const fetchAccountSettings = delistTradeWatchOrderService.getAccountSettings();
          this.cexAccounts = (await fetchCexAccounts).filter(a => ["binance", "okx", "bybit"].includes(a.exchange));

          this.cexAccountId = this.cexAccounts[0].id;
          this.accountSettings = await fetchAccountSettings;

          for (const a of this.cexAccounts) {
            if (!this.accountSettings[a.id]) {
              this.accountSettings[a.id] = {
                transferFromSpotAccount: false,
                transferFromFundingAccount: false,
                leverage: 3
              };
            }
          }
        } catch (e) {
          console.error(e);
          this.toastError(e);
        }
      },

      beforeSubmit() {

      },

      async submitForm() {
        try {
          this.isSubmitting = true;
          await delistTradeWatchOrderService.updateAccountSettings(this.accountSettings);

          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }

  };
</script>
