<template>
  <div>
    <b-form @submit.prevent="submitForm">
      <b-form-group>
        <template #label>
          Price multiplier for {{ exchangeName }} {{ hedgeSide.symbol }}
        </template>
        <b-form-input type="number" min="1" required v-model="priceMultiplier" />
      </b-form-group>
      <div class="text-right mt-3">
        <b-button type="button" variant="secondary mr-2" @click="onClickCancel">Cancel</b-button>
        <b-button type="submit" variant="primary">OK</b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as constants from "@/constants";

  export default {
    name: "CexHedgingEditPriceMultiplierModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "hedgeSide"],
    emits: ["done"],

    data() {
      return {
        priceMultiplier: "",
      };
    },

    computed: {
      exchangeName() {
        return constants.exchangeNames[this.hedgeSide.marketExchange];
      }
    },

    mounted() {
      this.priceMultiplier = this.hedgeSide.priceMultiplier;
    },

    methods: {
      onClickCancel() {
        this.$bvModal.hide(this.modalId);
      },

      beforeSubmit() {

      },

      submitForm() {
        this.$bvModal.hide(this.modalId);
        this.done();
      },

      done() {
        this.$emit("done", +this.priceMultiplier);
      }
    },
  };
</script>
