<template>
  <main role="main">
    <div style="max-width: 1366px;" class="mx-auto px-3 py-5">
      <div class="d-flex flex-row align-items-center justify-content-between mb-4">
        <h4 class="m-0">CEX Accounts (API keys)</h4>
        <div>
          <b-button variant="link" class="mr-3 text-decoration-none text-secondary" v-b-modal:arrange-cex-accounts-modal>
            Move accounts
          </b-button>
          <b-button variant="outline-primary" v-b-modal:add-cex-account-modal>
            <b-icon-plus/> Add API key
          </b-button>
        </div>
      </div>

      <b-table :items="cexAccounts" :fields="tableFields" :busy="isLoading" hover>
        <template v-slot:cell(exchange)="{ item }">
          <img :src="exchangeLogos[item.exchange]" class="mr-1" style="width: 20px; height: 20px;" :alt="exchangeNames[item.exchange]">
          {{ exchangeNames[item.exchange] }}
        </template>
        <template v-slot:cell(evmAddress)="data">
          <a class="text-monospace" :href="getExplorerUrl('all', 'address', data.item.evmAddress)" target="_blank">
            {{ getShortenedAddress(data.item.evmAddress) }}
          </a>
        </template>
        <template v-slot:cell(actions)="data">
          <b-dropdown size="sm" variant="outline-primary" right>
            <b-dropdown-item @click="onClickEditLabel(data.item)">Edit label</b-dropdown-item>
            <b-dropdown-item v-if="['binance', 'okx', 'bybit'].includes(data.item.exchange)" @click="onClickChangeUsdtFuturesAllSymbolsSettings(data.item)">
              Set leverage all<br> USDT futures markets
            </b-dropdown-item>
            <b-dropdown-item @click="onClickDelete(data.item)">Delete</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>

    <b-modal id="arrange-cex-accounts-modal" title="Arrange accounts" hide-footer no-fade no-close-on-backdrop>
      <ArrangeCexAccountsModal modal-id="arrange-cex-accounts-modal" @done="fetchAll" />
    </b-modal>
    <b-modal id="add-cex-account-modal" title="Add API key" size="lg" hide-footer no-fade no-close-on-backdrop>
      <AddCexAccountModal modal-id="add-cex-account-modal" @done="fetchAll"></AddCexAccountModal>
    </b-modal>
    <b-modal id="edit-cex-account-label-modal" title="Edit label" hide-footer no-fade no-close-on-backdrop>
      <EditCexAccountLabel :cex-account="editingCexAccount" modal-id="edit-cex-account-label-modal" @done="fetchAll"></EditCexAccountLabel>
    </b-modal>
    <b-modal id="change-usdt-futures-all-symbols-settings-modal" title="Set leverage all USDT futures markets" hide-footer no-fade no-close-on-backdrop>
      <ChangeUsdtFuturesAllSymbolsSettingsModal :cex-account="editingCexAccount" modal-id="change-usdt-futures-all-symbols-settings-modal" />
    </b-modal>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import { DateTime } from "luxon";
  import type {BvTableFieldArray} from "bootstrap-vue";

  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import * as cexAccountService from "@/services/cexAccountService";
  import AddCexAccountModal from "@/components/cex-account/AddCexAccountModal.vue";
  import EditCexAccountLabel from "@/components/cex-account/EditCexAccountLabel.vue";
  import ArrangeCexAccountsModal from "@/components/cex-account/ArrangeCexAccountsModal.vue";
  import ChangeUsdtFuturesAllSymbolsSettingsModal from "@/components/cex-account/ChangeUsdtFuturesAllSymbolsSettingsModal.vue";

  export default {
    name: "CexAccounts",
    components: {
      ChangeUsdtFuturesAllSymbolsSettingsModal,
      ArrangeCexAccountsModal, EditCexAccountLabel, AddCexAccountModal
    },
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading", "getShortenedAddress", "getExplorerUrl"],

    data() {
      return {
        exchangeNames: constants.exchangeNames,
        exchangeLogos: constants.EXCHANGE_LOGO,

        isLoading: true,
        cexAccounts: [],

        tableFields: [
          {
            key: "label",
            label: "Label",
            thClass: "text-nowrap",
            tdClass: "text-break",
          },
          {
            key: "exchange",
            label: "Exchange",
            thClass: "text-nowrap",
            tdClass: "text-break"
          },
          {
            key: "apiKey",
            label: "API key",
            thClass: "text-nowrap",
            tdClass: "text-monospace",
            formatter: utils.getShortenedAddress
          },
          {
            key: "evmAddress",
            label: "EVM deposit address",
            thClass: "text-nowrap",
          },
          {
            key: "createdAt",
            label: "Created",
            thClass: "text-nowrap",
            formatter: value => DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss")
          },
          {
            key: "actions",
            label: "",
            thClass: "text-nowrap",
            tdClass: "text-nowrap"
          }
        ] as BvTableFieldArray,

        editingCexAccount: null
      };
    },

    mounted() {
      document.title = "Manage CEX accounts";
      this.fetchAll();
    },

    methods: {
      async fetchAll() {
        try {
          this.isLoading = true;
          this.cexAccounts = await cexAccountService.getAll();

        } catch (e) {
          console.error(e);
          this.toastError(e);

        } finally {
          this.isLoading = false;
        }
      },

      onClickEditLabel(item) {
        this.editingCexAccount = item;
        this.$bvModal.show("edit-cex-account-label-modal");
      },

      onClickChangeUsdtFuturesAllSymbolsSettings(item) {
        this.editingCexAccount = item;
        this.$bvModal.show("change-usdt-futures-all-symbols-settings-modal");
      },

      async onClickDelete(item) {
        const ok = await this.$bvModal.msgBoxConfirm("Delete this account?", {
          okTitle: "Delete",
          okVariant: "danger"
        });

        if (ok) {
          try {
            this.isLoading = true;
            await cexAccountService.del(item.id);
            this.cexAccounts = await cexAccountService.getAll();

          } catch (e) {
            console.error(e);
            this.toastError(e);

          } finally {
            this.isLoading = false;
          }
        }
      },
    }
  };

</script>
