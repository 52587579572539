<template>
  <div>
    <b-form @keydown.enter.prevent @submit.prevent="submitForm">
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Market</b-col>
        <b-col cols="8">
          <div>{{ tradeExchangeName }}</div>
          <div>{{ tradeSymbolName }}</div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Account</b-col>
        <b-col cols="8">
          <b-form-select :options="cexAccountOptions" v-model="cexAccountId"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Notional volume</b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input class="text-right" type="number" required v-model="notional" min="10" autocomplete="off"></b-form-input>
            <b-input-group-append is-text>USDT</b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right mt-2">Max price impact</b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input class="text-right" type="number" required v-model="maxDropPercent" min="0" max="50" autocomplete="off"></b-form-input>
            <b-input-group-append is-text>%</b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4" class="text-right">Time in force</b-col>
        <b-col cols="8">
          <b-form-radio-group :options="timeInForceOptions" v-model="timeInForce"></b-form-radio-group>
        </b-col>
      </b-row>
      <template v-if="['ioc', 'fok'].includes(timeInForce)">
        <b-row class="mb-3">
          <b-col cols="4" class="text-right mt-2">
            <b-form-checkbox v-model="enableTakeProfit">Take profit</b-form-checkbox>
          </b-col>
          <b-col cols="8" v-if="enableTakeProfit">
            <b-input-group>
              <b-form-input class="text-right" type="number" required v-model="takeProfitPercent" min="1" autocomplete="off"></b-form-input>
              <b-input-group-append is-text>%</b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4" class="text-right mt-2">
            <b-form-checkbox v-model="enableStopLoss">Stop loss</b-form-checkbox>
          </b-col>
          <b-col cols="8" v-if="enableStopLoss">
            <b-input-group>
              <b-form-input class="text-right" type="number" required v-model="stopLossPercent" min="1"  autocomplete="off"></b-form-input>
              <b-input-group-append is-text>%</b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <div v-if="formErrorMsg" class="text-danger text-center">{{ formErrorMsg }}</div>
      <div class="text-center mt-4">
        <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Place order</b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as delistTradeWatchOrderService from "@/services/delistTradeWatchOrderService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";

  export default {
    name: "PlaceDelistTradeOrderModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "coin", "delistExchange", "tradeExchange"],
    emits: ["done"],

    data() {
      return {
        cexAccountOptions: [],
        cexAccountId: 0,

        notional: "",
        maxDropPercent: 15,

        timeInForceOptions: [
          { value: "ioc", text: "IOC" },
          { value: "fok", text: "FOK" },
          { value: "gtc", text: "GTC" },
        ],
        timeInForce: "ioc",

        enableTakeProfit: true,
        takeProfitPercent: "",

        enableStopLoss: true,
        stopLossPercent: "",

        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    computed: {
      tradeExchangeName() {
        return constants.exchangeNames[this.tradeExchange];
      },

      tradeSymbolName() {
        const market = this.coin.futuresMarkets[this.tradeExchange];
        return (market.baseAsset + market.quoteAsset).toUpperCase() + " Perpetual";
      },
    },

    mounted() {
      this.setup();
    },

    methods: {
      async setup() {
        const accExchange = this.tradeExchange.replace("-futures", "");

        const existingOrder = this.coin.futuresMarkets[this.tradeExchange].order;
        if (existingOrder) {
          this.cexAccountId = existingOrder.tradeCexAccountId;
          this.notional = existingOrder.notional;
          this.maxDropPercent = existingOrder.maxDropPercent;
          this.timeInForce = existingOrder.timeInForce;
          this.enableTakeProfit = !!existingOrder.takeProfitPercent;
          this.takeProfitPercent = existingOrder.takeProfitPercent;
          this.enableStopLoss = !!existingOrder.stopLossPercent;
          this.stopLossPercent = existingOrder.stopLossPercent;
        } else {
          this.cexAccountId = cexAccountService.getLastUsedAccountIdByExchange(accExchange) || 0;
        }

        this.cexAccountOptions = (await cexAccountService.getAll())
          .filter(a => a.exchange === accExchange)
          .map(a => ({ value: a.id, text: a.label }));
      },

      beforeSubmit() {

      },

      async submitForm() {
        try {
          this.isSubmitting = true;

          const delistAsset = this.coin.cexAssets[this.delistExchange];
          const market = this.coin.futuresMarkets[this.tradeExchange];
          const existingOrder = this.coin.futuresMarkets[this.tradeExchange].order;

          await delistTradeWatchOrderService.placeOrder({
            id: existingOrder?.id,
            delistExchange: this.delistExchange,
            delistAsset: delistAsset.asset,
            tradeCexAccountId: this.cexAccountId,
            tradeSymbol: market.symbol,
            notional: +this.notional,
            maxDropPercent: +this.maxDropPercent,
            timeInForce: this.timeInForce,
            takeProfitPercent: this.enableTakeProfit? +this.takeProfitPercent : undefined,
            stopLossPercent: this.enableStopLoss? +this.stopLossPercent : undefined,
            stopLossType: this.enableStopLoss? "market" : undefined
          });

          this.$bvModal.hide(this.modalId);
          this.done();
          this.toastSuccessDelay("Order placed");

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
