<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <label for="keyInput">Seed phrase or private key</label>
      <b-button variant="link" class="p-0" @click="onClickGenerateSeed" :disabled="isInputLocked">Random seed</b-button>
    </div>
    <b-form-textarea id="keyInput" size="sm" class="text-monospace mb-2" v-model="keyInput" :disabled="isInputLocked" />
    <b-button v-if="isInputLocked" variant="link" @click="resetForm">Reset form</b-button>
    <b-button v-else variant="outline-primary" @click="onClickCheck">Check</b-button>
    <div class="my-3" v-if="evmWallets && evmWallets.length">
      <label>EVM wallets</label>
      <div v-for="w in evmWallets" :key="w.address" class="d-flex flex-row align-items-center mb-2" style="height: 40px;">
        <b-form-checkbox v-model="w.checked" class="text-monospace flex-grow-1">
          <div>{{ w.address }}</div>
          <small v-if="w.hdPath" class="text-secondary">{{ w.hdPath }}</small>
        </b-form-checkbox>
        <b-form-input v-if="w.checked" type="text" v-model="w.label" placeholder="enter label" class="flex-shrink-0" style="width: 300px;" />
      </div>
      <b-button v-if="isSeedPhrase" variant="link" @click="addMoreEvmWallets">+{{ numWallets }} more</b-button>
    </div>
    <div class="my-3" v-if="solanaWallets && solanaWallets.length">
      <label>Solana wallets</label>
      <div v-for="w in solanaWallets" :key="w.address" class="d-flex flex-row align-items-center mb-2" style="height: 40px;">
        <b-form-checkbox v-model="w.checked" class="text-monospace flex-grow-1">
          <div>{{ w.address }}</div>
          <small v-if="w.hdPath" class="text-secondary">{{ w.hdPath }}</small>
        </b-form-checkbox>
        <b-form-input v-if="w.checked" type="text" v-model="w.label" placeholder="enter label" class="flex-shrink-0" style="width: 300px;" />
      </div>
      <b-button v-if="isSeedPhrase" variant="link" @click="addMoreSolanaWallets">+{{ numWallets }} more</b-button>
    </div>
    <div class="text-center" v-if="selectedWalletCount">
      <b-button variant="primary" @click="importWallets">Import {{ selectedWalletCount }} wallets</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import {ethers} from "ethers";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as utils from "@/utils";
  import base58 from "bs58";
  import * as bip39 from '@scure/bip39';
  import { wordlist } from '@scure/bip39/wordlists/english';
  import {Keypair} from "@solana/web3.js";
  import bs58 from "bs58";

  export default {
    name: "ImportPrivateKeyWalletModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId"],
    emits: ["done"],

    data() {
      return {
        keyInput: "",
        isInputLocked: false,

        isSeedPhrase: false,
        evmWallets: [],
        solanaWallets: [],
        numWallets: 3,
      };
    },

    computed: {
      selectedWalletCount() {
        const predicate = _.iteratee("checked");
        return utils.countPredicate(this.evmWallets, predicate) + utils.countPredicate(this.solanaWallets, predicate);
      }
    },

    methods: {
      onClickGenerateSeed() {
        this.keyInput = bip39.generateMnemonic(wordlist);
      },

      onClickCheck() {
        this.keyInput = this.keyInput.trim().replace(/\s+/g, " ");
        if (!this.keyInput) return;

        if (this.keyInput.split(" ").length >= 6) {
          try {
            this.evmWallets = privateKeyWalletService.evmSeedPhraseWallets(this.keyInput, 0, this.numWallets).map(w => ({
              type: "evm",
              label: "",
              address: w.address,
              privateKey: w.privateKey,
              mnemonicPhrase: w.mnemonic.phrase,
              hdPath: w.path
            }));
            this.isSeedPhrase = true;
            console.log("is evm seed phrase");

          } catch (e) {}

          try {
            this.solanaWallets = privateKeyWalletService.solanaSeedPhraseWallets(this.keyInput, 0, this.numWallets).map(w => ({
              type: "solana",
              label: "",
              address: w.keypair.publicKey.toBase58(),
              privateKey: base58.encode(w.keypair.secretKey),
              mnemonicPhrase: this.keyInput,
              hdPath: w.hdPath
            }));
            this.isSeedPhrase = true;
            console.log("is solana seed phrase");

          } catch (e) {}

        } else {
          try {
            const w = new ethers.Wallet(this.keyInput);
            this.evmWallets = [{ type: "evm", label: "", address: w.address, privateKey: w.privateKey}];
            this.isSeedPhrase = false;

          } catch (e) {
          }

          try {
            const w = Keypair.fromSecretKey(bs58.decode(this.keyInput));
            this.solanaWallets = [{ type: "solana", label: "", address: w.publicKey.toBase58(), privateKey: this.keyInput}];
            this.isSeedPhrase = false;
            console.log("is solana bs58 private key");

          } catch (e) {
          }
        }

        this.isInputLocked = this.evmWallets.length > 0 || this.solanaWallets.length > 0;
      },

      resetForm() {
        this.evmWallets = [];
        this.solanaWallets = [];
        this.isInputLocked = false;
      },

      addMoreEvmWallets() {
        try {
          const moreWallets = privateKeyWalletService.evmSeedPhraseWallets(this.keyInput, this.evmWallets.length, this.evmWallets.length + this.numWallets).map(w => ({
            type: "evm",
            label: "",
            address: w.address,
            privateKey: w.privateKey,
            mnemonicPhrase: w.mnemonic.phrase,
            hdPath: w.path
          }));
          this.evmWallets.push(...moreWallets);

        } catch (e) {}
      },

      addMoreSolanaWallets() {
        try {
          const moreWallets = privateKeyWalletService.solanaSeedPhraseWallets(this.keyInput, this.solanaWallets.length, this.solanaWallets.length + this.numWallets).map(w => ({
            type: "solana",
            label: "",
            address: w.keypair.publicKey.toBase58(),
            privateKey: base58.encode(w.keypair.secretKey),
            mnemonicPhrase: this.keyInput,
            hdPath: w.hdPath
          }));
          this.solanaWallets.push(...moreWallets);

        } catch (e) {}
      },

      importWallets() {
        const evmWallets = this.evmWallets.filter(w => w.checked);
        const solanaWallets = this.solanaWallets.filter(w => w.checked);
        const saveWallets = [...evmWallets, ...solanaWallets];
        for (const w of saveWallets) {
          w.label = w.label?.trim() || "";
        }
        privateKeyWalletService.saveWallets(saveWallets);
        this.$bvModal.hide(this.modalId);
        this.done();
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
