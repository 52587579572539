<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
<!--    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Show all chains</b-col>
      <b-col :cols="rightColSize">
        <b-form-radio-group v-model="isAllChains" :options="chainOptions" required></b-form-radio-group>
      </b-col>
    </b-row>-->
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Chains</b-col>
      <b-col :cols="rightColSize">
        <b-form-checkbox-group v-model="chainIds" :options="chainOptions"></b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">
        <div>Exchanges</div>
        <div><small class="text-secondary">Spot</small></div>
      </b-col>
      <b-col :cols="rightColSize">
        <b-form-checkbox-group v-model="spotExchanges" :options="spotExchangeOptions"></b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">
        <div>Exchanges</div>
        <div><small class="text-secondary">USDT Futures</small></div>
      </b-col>
      <b-col :cols="rightColSize">
        <b-form-checkbox-group v-model="futuresExchanges" :options="futuresExchangeOptions"></b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Min DEX liquidity</b-col>
      <b-col :cols="rightColSize">
        <b-form-input type="number" min="1000" v-model="minDexLiquidity" required autocomplete="off"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Min CEX 24h volume</b-col>
      <b-col :cols="4">
        <b-form-input type="number" min="1" v-model="minCexVolume24h" required autocomplete="off"></b-form-input>
      </b-col>
      <b-col :cols="2" class="text-right">Min 2% depth</b-col>
      <b-col :cols="3">
        <b-form-input type="number" min="0" v-model="minCexDepth" required autocomplete="off"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Min arbitrage (%)</b-col>
      <b-col :cols="rightColSize">
        <b-form-input type="number" min="0.1" step="0.01" v-model="minArbitragePercent" required autocomplete="off"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Price type</b-col>
      <b-col :cols="rightColSize">
        <b-form-radio-group v-model="priceType" :options="priceTypeOptions" required></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right"></b-col>
      <b-col :cols="rightColSize">
        <b-form-checkbox class="d-inline-block mr-4" v-model="showLongFuturesSellSpot">Show long futures → sell spot</b-form-checkbox>
        <b-form-checkbox class="d-inline-block" v-model="showAllPrices">
          Show all prices (<span class="text-success">buy</span>, index, <span class="text-danger">sell</span>)
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right"></b-col>
      <b-col :cols="4">
        <b-form-checkbox class="d-inline-block mr-4" v-model="showFundingArbitrage" @change="showFundingArbitrageCheckChange">
          Show funding arbitrage
        </b-form-checkbox>
      </b-col>
      <b-col v-if="showFundingArbitrage" :cols="5">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>Min arb %</b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" min="0.05" step="0.001" v-model="minFundingRateArbitragePercent" required autocomplete="off" />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Include coins</b-col>
      <b-col :cols="rightColSize" class="d-flex flex-row align-items-center flex-wrap">
        <div v-for="(item, i) in includeCoins" :key="item.coingeckoId"
             style="line-height: 1;" class="flex-shrink-0 d-flex flex-row align-items-center border rounded px-2 py-1 mr-2 mb-2">
          <img :src="item.logoSmallUrl || item.logoUrl" :alt="item.name" style="width: 25px; height: 25px;">
          <div class="mx-2">
            <div><small>{{ item.symbol }}</small></div>
            <!--            <div><small class="text-secondary">{{ item.name }}</small></div>-->
          </div>
          <div class="cursor-pointer" @click="includeCoins.splice(i, 1)">
            <b-icon-x-circle></b-icon-x-circle>
          </div>
        </div>
        <div style="width: 280px;" class="mb-2">
          <v-select class="vs-normalizer" label="name"
                    :clearable="false"
                    :filterable="false"
                    :options="includeTypeaheadOptions"
                    :clearSearchOnBlur="() => false"
                    @search="includedCoinsSearchDebounced"
                    v-on:option:selecting="includedCoinTypeaheadSelecting">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events" placeholder="search for coins" class="form-control"/>
            </template>
            <template #option="coin">
              <img style="width: 25px; height: 25px;" class="mr-2" :src="coin.thumb" :alt="coin.name">
              <span>{{ coin.name }} ({{ coin.symbol }})</span>
            </template>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right">Exclude coins</b-col>
      <b-col :cols="rightColSize" class="d-flex flex-row align-items-center flex-wrap">
        <div v-for="(item, i) in excludeCoins" :key="item.coingeckoId"
             style="line-height: 1;" class="flex-shrink-0 d-flex flex-row align-items-center border rounded px-2 py-1 mr-2 mb-2">
          <img :src="item.logoSmallUrl || item.logoUrl" :alt="item.name" style="width: 25px; height: 25px;">
          <div class="mx-2">
            <div><small>{{ item.symbol }}</small></div>
<!--            <div><small class="text-secondary">{{ item.name }}</small></div>-->
          </div>
          <div class="cursor-pointer" @click="excludeCoins.splice(i, 1)">
            <b-icon-x-circle></b-icon-x-circle>
          </div>
        </div>
        <div style="width: 280px;" class="mb-2">
          <v-select class="vs-normalizer" label="name"
                    :clearable="false"
                    :filterable="false"
                    :options="excludeTypeaheadOptions"
                    :clearSearchOnBlur="() => false"
                    @search="excludedCoinsSearchDebounced"
                    v-on:option:selecting="excludedCoinTypeaheadSelecting">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events" placeholder="search for coins" class="form-control"/>
            </template>
            <template #option="coin">
              <img style="width: 25px; height: 25px;" class="mr-2" :src="coin.thumb" :alt="coin.name">
              <span>{{ coin.name }} ({{ coin.symbol }})</span>
            </template>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col :cols="leftColSize" class="text-right"></b-col>
      <b-col :cols="rightColSize">
        <b-button variant="link" class="p-0" v-b-modal:saved-queries-modal>Manage saved queries</b-button>
      </b-col>
    </b-row>
    <div class="text-center">
      <b-button type="submit" variant="primary">Save</b-button>
    </div>
    <b-modal id="saved-queries-modal" title="Saved queries" hide-footer no-fade no-close-on-backdrop>
      <SavedQueriesModal
        modal-id="saved-queries-modal"
        :arbitrageSettings="arbitrageSettingsFormData"
        @onClickLoad="applySavedQuery"/>
    </b-modal>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import axios from "@/axios";
  import SavedQueriesModal from "@/components/arbitrage/SavedQueriesModal.vue";

  export default {
    name: "ArbitrageSettingsModal2",
    components: {SavedQueriesModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "arbitrageSettings"],
    emits: ["done"],

    data() {
      return {
        leftColSize: 3,
        rightColSize: 9,

        chainIds: [constants.CHAIN_ID_ETH],
        chainOptions: [
          { value: constants.CHAIN_ID_ETH, text: "Ethereum" },
          { value: constants.CHAIN_ID_BSC, text: "BNB" },
          { value: constants.CHAIN_ID_POLYGON, text: "Polygon" },
          { value: constants.CHAIN_ID_ARBITRUM, text: "Arbitrum" },
          { value: constants.CHAIN_ID_OPTIMISM, text: "Optimism" },
          { value: constants.CHAIN_ID_BASE, text: "Base" },
          { value: constants.CHAIN_ID_FANTOM, text: "Fantom" },
          { value: constants.CHAIN_ID_SOLANA, text: "Solana" },
          { value: constants.CHAIN_ID_TON, text: "Toncoin" },
          { value: constants.CHAIN_ID_TRON, text: "Tron" },
        ],

        spotExchanges: ["binance"],
        spotExchangeOptions: [
          { value: "binance", text: "Binance" },
          { value: "huobi", text: "Huobi" },
          { value: "okx", text: "OKX" },
          { value: "kucoin", text: "Kucoin" },
          { value: "mexc", text: "MEXC" },
          { value: "gate", text: "Gate" },
          { value: "bybit", text: "Bybit" },
          { value: "bitget", text: "Bitget" },
        ],

        futuresExchanges: ["binance-futures"],
        futuresExchangeOptions: [
          { value: "binance-futures", text: "Binance" },
          { value: "huobi-futures", text: "Huobi" },
          { value: "okx-futures", text: "OKX" },
          { value: "kucoin-futures", text: "Kucoin" },
          { value: "mexc-futures", text: "MEXC" },
          { value: "gate-futures", text: "Gate" },
          { value: "bybit-futures", text: "Bybit" },
          { value: "bitget-futures", text: "Bitget" },
        ],

        minDexLiquidity: 20000,
        minCexVolume24h: 20000,
        minCexDepth: 1000,
        minArbitragePercent: 1,

        priceTypeOptions: ["index", "trade"],
        priceType: "index",
        showLongFuturesSellSpot: false,
        showAllPrices: false,

        showFundingArbitrage: false,
        minFundingRateArbitragePercent: 0.05,

        excludeCoins: [],
        excludeTypeaheadOptions: [],

        includeCoins: [],
        includeTypeaheadOptions: [],
      };
    },
    computed: {
      exchanges: {
        get() {
          return [...this.spotExchanges, ...this.futuresExchanges];
        },
        set(val: string[]) {
          this.futuresExchanges = val.filter(e => e.endsWith("-futures"));
          this.spotExchanges = val.filter(e => !e.endsWith("-futures"));
        },
      },
      arbitrageSettingsFormData() {
        return {
          chainIds: this.chainIds,
          exchanges: this.exchanges,
          minDexLiquidity: +this.minDexLiquidity,
          minCexVolume24h: +this.minCexVolume24h,
          minCexDepth: +this.minCexDepth,
          minArbitragePercent: +this.minArbitragePercent,
          priceType: this.priceType,
          showLongFuturesSellSpot: this.showLongFuturesSellSpot,
          showAllPrices: this.showAllPrices,
          minFundingRateArbitragePercent: this.showFundingArbitrage? +this.minFundingRateArbitragePercent : 0,
          excludeCoins: this.excludeCoins,
          includeCoins: this.includeCoins,
        };
      }
    },

    created() {
      this.excludedCoinsSearchDebounced = _.debounce(this.excludedCoinsSearch, 300);
      this.includedCoinsSearchDebounced = _.debounce(this.includedCoinsSearch, 300);

      Object.assign(this, utils.jsonClone(this.arbitrageSettings));
      this.showFundingArbitrage = +this.minFundingRateArbitragePercent > 0;
    },

    methods: {
      showFundingArbitrageCheckChange() {
        if (this.showFundingArbitrage && +this.minFundingRateArbitragePercent < 0.05) {
          this.minFundingRateArbitragePercent = 0.05;
        }
      },

      async excludedCoinsSearch(query: string, loading: (p: boolean) => void) {
        query = query.trim();
        try {
          if (query.length < 2) {
            this.excludeTypeaheadOptions = [];
          } else {
            loading(true);

            // Fetch and assign typeahead options
            this.excludeTypeaheadOptions = (await axios.get("https://api.coingecko.com/api/v3/search", {
              params: { query }
            })).data.coins;
          }

        } catch (e) {
          console.error(e);

        } finally {
          loading(false);
        }
      },

      excludedCoinTypeaheadSelecting(option) {
        _.remove(this.includeCoins, (item: any) => item.coingeckoId === option.id);
        if (this.excludeCoins.every(item => item.coingeckoId !== option.id)) {
          this.excludeCoins.push({
            coingeckoId: option.id,
            name: option.name,
            symbol: option.symbol,
            logoUrl: option.large,
            logoSmallUrl: option.thumb
          });
        }
      },

      async includedCoinsSearch(query: string, loading: (p: boolean) => void) {
        query = query.trim();
        try {
          if (query.length < 2) {
            this.includeTypeaheadOptions = [];
          } else {
            loading(true);

            // Fetch and assign typeahead options
            this.includeTypeaheadOptions = (await axios.get("https://api.coingecko.com/api/v3/search", {
              params: { query }
            })).data.coins;
          }

        } catch (e) {
          console.error(e);

        } finally {
          loading(false);
        }
      },

      includedCoinTypeaheadSelecting(option) {
        _.remove(this.excludeCoins, (item: any) => item.coingeckoId === option.id);
        if (this.includeCoins.every(item => item.coingeckoId !== option.id)) {
          this.includeCoins.push({
            coingeckoId: option.id,
            name: option.name,
            symbol: option.symbol,
            logoUrl: option.large,
            logoSmallUrl: option.thumb
          });
        }
      },

      applySavedQuery(savedQuery) {
        const picked = _.pick(utils.jsonClone(savedQuery), Object.keys(this.arbitrageSettingsFormData));
        Object.assign(this, picked);
      },

      submitForm() {
        const obj = this.arbitrageSettingsFormData;
        localStorage.setItem("arbitrage2", JSON.stringify(obj));
        this.$bvModal.hide(this.modalId);
        this.$emit("done", obj);
      }
    }
  };
</script>
