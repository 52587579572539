<template>
  <main class="container py-5">
    <section class="mb-3">
      <div>EIP-6963 announced wallets</div>
      <BTable :items="walletArr" />
      <BButton variant="link" @click="dispatchRequestProvider">Dispatch eip6963:requestProvider</BButton>
    </section>

    <section class="mb-3">
      <div>Web3-Onboard props</div>
      <template v-if="connectedWallet">
        <div>label: <span class="font-monospace">{{ connectedWallet.label }}</span></div>
        <div>logo: <img :src="connectedWallet.icon" :alt="connectedWallet.label" style="width: 1.25rem; height: 1.25rem;"></div>
        <div>walletAddress: <span class="font-monospace">{{ walletAddress }}</span></div>
        <div>chainId: <span class="font-monospace">{{ chainId }}</span></div>
      </template>
    </section>

    <section class="mb-3">
      <div>Web3-Onboard methods</div>
      <BButton variant="link" class="mr-2" @click="connectWallet()">connectWallet</BButton>
      <BButton variant="link" class="mr-2" @click="disconnectConnectedWallet()">disconnectConnectedWallet</BButton>
    </section>
  </main>
</template>

<script setup lang="ts">
  import { ref, reactive, computed, onMounted } from "vue";
  import { useOnboard } from "@web3-onboard/vue";
  import { walletAddress, chainId } from "@/services/onboard.ts";
  import { wallets } from "@/services/eip6963";

  const { connectWallet, connectedWallet, disconnectConnectedWallet } = useOnboard();

  const walletArr = computed(() => Object.values(wallets).map(w => ({
    rdns: w.info.rdns,
    name: w.info.name,
    delay: w.delay
  })));

  function dispatchRequestProvider() {
    dispatchEvent(new Event("eip6963:requestProvider"));
  }

  onMounted(() => {

  });

</script>

