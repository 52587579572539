<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-row class="mb-3">
      <b-col cols="4" class="text-right mt-2">Leverage</b-col>
      <b-col cols="8">
        <b-form-group>
          <b-form-spinbutton style="width: 300px;" v-model="leverage" min="1" max="10" />
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";

  export default {
    name: "ChangeUsdtFuturesAllSymbolsSettingsModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "cexAccount"],
    emits: ["done"],

    data() {
      return {
        leverage: 3,
        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    methods: {
      beforeSubmit() {

      },

      async submitForm() {
        try {
          this.formErrorMsg = "";
          this.isSubmitting = true;

          await cexAccountService.changeUsdtFuturesAllSymbolsSettings({
            cexAccountId: this.cexAccount.id,
            initialLeverage: this.leverage
          });
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
