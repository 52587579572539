import axios from "@/axios";
import * as authService from "@/services/authService";
import * as constants from "@/constants";
import * as utils from "@/utils";

interface CexHedgingOrderRequest {
  buy: {
    cexAccountId: number;
    exchange: string;
    type: "spot" | "futures",
    symbol: string;
    priceMultiplier: number;
    refPrice: number;
    marginMode?: "cross" | "isolated";
    leverage?: number;
    reduceOnly?: boolean;
    clientOrderId: string;
    dryRun?: boolean;
  },
  sell: {
    cexAccountId: number;
    exchange: string;
    type: "spot" | "futures",
    symbol: string;
    priceMultiplier: number;
    refPrice: number;
    marginMode?: "cross" | "isolated";
    leverage?: number;
    reduceOnly?: boolean;
    clientOrderId: string;
    dryRun?: boolean;
  },
  amount: number;
}

export async function sendCexHedgingOrders(data: CexHedgingOrderRequest) {
  return (await axios.post("/hedging/sendCexHedgingOrders", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}

interface CexHedgeSideCheck {
  cexAccountId: number;
  type: "spot" | "futures",
  symbol: string;
}
interface CheckAccountsRequest {
  buy: CexHedgeSideCheck;
  sell: CexHedgeSideCheck;
}
export async function checkAccounts(data: CheckAccountsRequest) {
  return (await axios.post("/hedging/checkAccounts", data, {
    headers: await authService.getAuthHeaders()
  })).data.data;
}
