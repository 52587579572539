<template>
  <div>
    <slot></slot>
    <div v-if="errorMessage" class="text-danger text-break my-3">{{ errorMessage }}</div>
    <div class="text-right mt-3">
      <b-button variant="secondary" class="mr-3" @click="onClickCancel" :disabled="isSending">Cancel</b-button>
      <b-button variant="primary" @click="onClickExecute" :disabled="isSending">Confirm</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import {ethers, TransactionResponse, Wallet} from "ethers";
  import type {TransactionRequest} from "ethers";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as web3Service from "@/services/web3Service";

  export default {
    name: "TransactionRequestModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "transactionRequest"],
    emits: ["done"],

    data() {
      return {
        errorMessage: "",
        isSending: false,
      };
    },

    methods: {
      onClickCancel() {
        this.$bvModal.hide(this.modalId);
      },

      async onClickExecute() {
        try {
          this.isSending = true;
          const transactionRequest = ethers.copyRequest(this.transactionRequest);
          const provider = await web3Service.getProviderForChain(Number(transactionRequest.chainId), false);
          const estimatedGas = await provider.estimateGas(transactionRequest);

          // bump gas with 25% buffer
          transactionRequest.gasLimit = estimatedGas * 125n / 100n;

          const privateKeyWallet = privateKeyWalletService.getWallets().find(w =>
            w.type === "evm" &&
            w.address.toLowerCase() === transactionRequest.from.toString().toLowerCase()
          );
          const wallet = new Wallet(privateKeyWallet.privateKey, provider);
          const txResponse = await wallet.sendTransaction(transactionRequest);
          console.log(txResponse);
          this.$bvModal.hide(this.modalId);
          this.done(txResponse);

        } catch (e) {
          this.errorMessage = e.message;
          console.error(e);

        } finally {
          this.isSending = false;
        }
      },

      done(txResponse: TransactionResponse) {
        this.$emit("done", txResponse);
      }
    }
  };
</script>
