<template>
  <div class="px-2 border-bottom bg-hover" v-bind:style="{ 'background-color': 'rgba(255, 245, 157, ' + backgroundAlpha + ')' }">
    <b-row no-gutters>
      <b-col cols="2" class="py-1">
        <div class="d-flex flex-row">
          <img class="img-coin" :src="coin.logoUrl">
          <div>
            <div>{{ symbol }} <b-icon-pin-angle-fill v-if="coin.isPinned" /></div>
            <a :href="'https://www.coingecko.com/en/coins/' + coin.coingeckoId" target="_blank" class="text-secondary">
              {{ coin.name }}
            </a>
          </div>
        </div>
      </b-col>
      <div class="col-9">
        <div v-if="shouldShowPriceArbitrageRow" class="d-flex flex-row">
          <div style="flex: 0 0 11.1111%" class="py-1">
            <div><small class="text-secondary">Price</small></div>
            <div class="mb-1">{{ displayArbitragePercent }}%</div>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="arbBuyAt"
              :coingeckoId="coin.coingeckoId"
              :item="arbBuyAt"
              :type="arbBuyAt.type"
              :priceType="arbitrageSettings.priceType"
              :showAllPrices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
            <ArbitrageSubItem
              v-else-if="arbOnlyItem"
              :coingeckoId="coin.coingeckoId"
              :item="arbOnlyItem"
              :type="arbOnlyItem.type"
              :showAllPrices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="arbSellAt"
              :coingeckoId="coin.coingeckoId"
              :item="arbSellAt"
              :type="arbSellAt.type"
              :priceType="arbitrageSettings.priceType"
              :showAllPrices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </div>
        </div>
        <div v-if="shouldShowFundingArbitrageRow" class="d-flex flex-row">
          <div style="flex: 0 0 11.1111%" class="py-1">
            <div><small class="text-secondary">Funding</small></div>
            <div>{{ displayFundingRateArbitragePercent }}%</div>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="fundingArbLongItem"
              :coingeckoId="coin.coingeckoId"
              :item="fundingArbLongItem"
              :type="fundingArbLongItem.type"
              :priceType="arbitrageSettings.priceType"
              :showAllPrices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </div>
          <div style="flex: 0 0 44.4444%">
            <ArbitrageSubItem
              v-if="fundingArbShortItem"
              :coingeckoId="coin.coingeckoId"
              :item="fundingArbShortItem"
              :type="fundingArbShortItem.type"
              :priceType="arbitrageSettings.priceType"
              :showAllPrices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </div>
        </div>
      </div>
      <b-col cols="1">
        <b-button variant="link" @click="showMore = !showMore">
          {{ showMore ? "less" : "more" }}
        </b-button>
        <div v-if="showPinUnpinActions">
          <b-button v-if="coin.isPinned" size="sm" variant="link" class="text-secondary mr-2" @click="unpin">
            <b-iconstack>
              <b-icon-pin shift-h="-2" scale="0.75"></b-icon-pin>
              <b-icon-slash scale="2" shift-h="1" stacked></b-icon-slash>
            </b-iconstack>
          </b-button>
          <b-button v-else size="sm" variant="link" class="text-secondary mr-2" @click="pin">
            <b-icon-pin-angle></b-icon-pin-angle>
          </b-button>
          <b-button size="sm" variant="link" class="text-secondary" @click="blacklist">
            <b-icon-dash-circle></b-icon-dash-circle>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <template v-if="showMore">
      <b-row v-if="dexItems.length" no-gutters>
        <b-col cols="2"></b-col>
        <b-col cols="1" class="py-1">
          <span class="text-secondary">Chains ({{ dexItems.length }})</span>
        </b-col>
        <b-col cols="8" class="row no-gutters">
          <b-col v-for="item in dexItems" :key="item.chainId" cols="6">
            <ArbitrageSubItem
              :coingeckoId="coin.coingeckoId"
              :item="item"
              type="dex"
              :showAllPrices="arbitrageSettings.showAllPrices"
            ></ArbitrageSubItem>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="cexItems.length" no-gutters>
        <b-col cols="2"></b-col>
        <b-col cols="1" class="py-1">
          <span class="text-secondary">Exchanges ({{ cexItems.length }})</span>
        </b-col>
        <b-col cols="8" class="row no-gutters">
          <b-col v-for="item in cexItems" :key="item.exchange" cols="6">
            <ArbitrageSubItem
              :coingeckoId="coin.coingeckoId"
              :item="item"
              type="cex"
              :showAllPrices="arbitrageSettings.showAllPrices"
              @onClickDeposit="onClickDeposit"
              @onClickWithdraw="onClickWithdraw"
            ></ArbitrageSubItem>
          </b-col>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .img-coin {
    width: 36px;
    height: 36px;
    margin-right: 0.5em;
  }
  .img-exchange {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }
  .bg-highlight-hover:hover {
    background-color: lightyellow;
  }
</style>

<script lang="ts">
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import * as marketDataService from "@/services/marketDataService";
  import ArbitrageSubItem from "@/components/arbitrage/ArbitrageSubItem.vue";

  const volNumberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  export default {
    name: "ArbitrageItem",
    components: {ArbitrageSubItem},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["coin", "arbitrageSettings", "isFocusMode"],

    data() {
      return {
        symbol: "",
        displayArbitragePercent: "",
        displayFundingRateArbitragePercent: "",
        backgroundAlpha: "0",

        arbBuyAt: null,
        arbSellAt: null,
        arbOnlyItem: null,

        fundingArbLongItem: null,
        fundingArbShortItem: null,

        cexItems: [],
        dexItems: [],

        showMore: false,
        showPinUnpinActions: true,
      };
    },
    computed: {
      shouldShowPriceArbitrageRow() {
        return this.coin.isPinned ||
          !this.displayFundingRateArbitragePercent ||
          !this.arbitrageSettings.minFundingRateArbitragePercent ||
          (this.coin.highestArbitragePercent || 0) >= this.arbitrageSettings.minArbitragePercent;
      },
      shouldShowFundingArbitrageRow() {
        return this.displayFundingRateArbitragePercent && this.arbitrageSettings.minFundingRateArbitragePercent;
      }
    },

    watch: {
      coin: {
        immediate: true,
        deep: true,
        handler() {
          this.symbol = this.coin.symbol.toUpperCase();

          const highestArbitragePercentBN = BigNumber(this.coin.highestArbitragePercent || 0);
          this.displayArbitragePercent = highestArbitragePercentBN.toFixed(2);

          if (highestArbitragePercentBN.gte(5)) {
            this.backgroundAlpha = BigNumber.min(highestArbitragePercentBN.dividedBy(50), 1).toFixed(2);
          } else {
            this.backgroundAlpha = "0";
          }

          for (const item of this.coin.cex) {
            item.type = "cex";
          }
          for (const item of this.coin.dex) {
            item.type = "dex";
          }

          this.cexItems = this.coin.cex;
          this.dexItems = this.coin.dex;
          const arr = [...this.coin.cex, ...this.coin.dex];

          if (arr.length >= 2) {
            this.arbBuyAt = arr.find(item => item.buyHere) || arr[0];
            this.arbSellAt = arr.find(item => item.sellHere) || arr[1];
            this.arbOnlyItem = null;
          } else {
            this.arbBuyAt = this.arbSellAt = null;
            this.arbOnlyItem = arr[0] || null;
          }

          const highestFundingRateArbitragePercentBN = BigNumber(this.coin.highestFundingRateArbitragePercent || 0);
          this.displayFundingRateArbitragePercent = highestFundingRateArbitragePercentBN.toFixed(4);
          if (highestFundingRateArbitragePercentBN.gt(0)) {
            this.fundingArbLongItem = this.cexItems.find(item => item.fundingLongHere);
            this.fundingArbShortItem = this.cexItems.find(item => item.fundingShortHere);
          } else {
            this.displayFundingRateArbitragePercent = "";
            this.fundingArbLongItem = this.fundingArbShortItem = null;
          }

          // reset
          this.showPinUnpinActions = !this.isFocusMode;
        }
      }
    },

    created() {
      this.showMore = this.isFocusMode;
    },

    methods: {
      pin() {
        this.showPinUnpinActions = false;
        this.$emit("pin", this.coin);
      },
      unpin() {
        this.showPinUnpinActions = false;
        this.$emit("unpin", this.coin);
      },
      blacklist() {
        this.showPinUnpinActions = false;
        this.$emit("blacklist", this.coin);
      },
      onClickWithdraw(item) {
        this.$emit("onClickWithdraw", item); // pass back to parent
        // console.log("ArbitrageItem onClickWithdraw", item);
      },
      onClickDeposit(item) {
        this.$emit("onClickDeposit", item); // pass back to parent
        // console.log("ArbitrageItem onClickDeposit", item);
      }
    }
  };
</script>
