<template>
  <main role="main">
    <audio ref="audioEl_percussion" preload="auto" src="/sound/percussion.mp3"></audio>
    <audio ref="audioEl_meet" preload="auto" src="/sound/oneplus_meet.mp3"></audio>
    <div v-if="!canPlayAudio" class="text-center border-bottom">
      <span class="text-danger">⚠️ Audio autoplay is disabled. Click anywhere to allow</span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between px-3 py-1 border-bottom">
      <h4 class="m-0">Arbitrage</h4>
      <div>
        <span class="text-secondary mr-3">{{ statusText }}</span>
        <b-button variant="link" class="text-decoration-none" @click="togglePauseUpdate">
          <template v-if="isUpdatePaused"><b-icon-play/> Resume</template>
          <template v-else><b-icon-pause/> Pause</template>
        </b-button>
        <b-button variant="link" class="text-decoration-none" v-b-modal:arbitrage-filter-modal>
          <b-icon-filter/> Filters
        </b-button>
      </div>
    </div>

    <div>
      <ArbitrageItem
        v-for="(coin, i) in arbitrageCoins"
        :key="coin.coingeckoId"
        :coin="coin"
        :arbitrage-settings="arbitrageSettings"
        @pin="addToIncludeCoins(coin)"
        @unpin="removeFromIncludeCoins(coin)"
        @blacklist="addToExcludeCoins(coin, i)"
        @onClickWithdraw="onClickWithdraw"
        @onClickDeposit="onClickDeposit"
      ></ArbitrageItem>
    </div>

    <b-modal id="arbitrage-filter-modal" title="Filters" size="lg" hide-footer no-fade>
      <ArbitrageSettingsModal2 :arbitrageSettings="arbitrageSettings" modalId="arbitrage-filter-modal" @done="doneChangeSettings" />
    </b-modal>

    <template v-if="dwItem">
      <b-modal :id="cexWithdrawModalId" :title="'Withdraw ' + dwItem.asset.toUpperCase()" hide-footer no-fade no-close-on-backdrop>
        <ArbitrageWithdrawCexAssetModal
          :modalId="cexWithdrawModalId"
          :exchange="dwItem.exchange"
          :asset="dwItem.asset"
        ></ArbitrageWithdrawCexAssetModal>
      </b-modal>
      <b-modal :id="cexDepositModalId" :title="'Deposit ' + dwItem.asset.toUpperCase()" hide-footer no-fade no-close-on-backdrop>
        <ArbitrageDepositCexAssetModal
          :modalId="cexDepositModalId"
          :exchange="dwItem.exchange"
          :asset="dwItem.asset"
        ></ArbitrageDepositCexAssetModal>
      </b-modal>
    </template>
  </main>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as arbitrageService from "@/services/arbitrageService";
  import * as constants from "@/constants";
  import BigNumber from "bignumber.js";
  import ArbitrageSettingsModal2 from "@/components/arbitrage/ArbitrageSettingsModal2.vue";
  import * as web3Service from "@/services/web3Service";
  import ArbitrageItem from "@/components/arbitrage/ArbitrageItem.vue";
  import ArbitrageDepositCexAssetModal from "@/components/arbitrage/ArbitrageDepositCexAssetModal.vue";
  import ArbitrageWithdrawCexAssetModal from "@/components/arbitrage/ArbitrageWithdrawCexAssetModal.vue";
  import * as utils from "@/utils";

  export default {
    name: "Arbitrage2",
    components: {ArbitrageWithdrawCexAssetModal, ArbitrageDepositCexAssetModal, ArbitrageItem, ArbitrageSettingsModal2 },
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],

    data() {
      return {
        chainId: 1,
        reloadTimeoutHandler: null,
        isUpdatePaused: false,
        canPlayAudio: true,

        arbitrageCoins: [],
        recentNotifyArbitrageItems: {} as Record<string, { ts: number, arbitragePercent: number }>,
        isDestroyed: false,

        arbitrageSettings: {
          chainIds: [],
          exchanges: ["binance", "okx"],
          minDexLiquidity: 25000,
          minCexVolume24h: 25000,
          minCexDepth: 1000,
          minArbitragePercent: 1,
          priceType: "index",
          showLongFuturesSellSpot: false,
          minFundingRateArbitragePercent: 0.05,
          excludeCoins: [],
          includeCoins: [],
        },

        isLoading: false,
        statusText: "",

        dwItem: null,
        cexWithdrawModalId: "cex-withdraw-modal",
        cexDepositModalId: "cex-deposit-modal",
      };
    },

    async mounted() {
      document.title = "Arbitrage";
      this.chainId = await web3Service.getConnectedEvmChainId();
      this.arbitrageSettings.chainIds.push(this.chainId);
      this.loadSettings();
      this.checkAudioPlayPermission();
      this.refreshLoop();
    },

    methods: {
      checkAudioPlayPermission() {
        if (window["canPlayAudio"]) {
          this.canPlayAudio = true;
        } else {
          this.canPlayAudio = false;
          addEventListener("click", () => {
            this.canPlayAudio = true;
          }, { once: true });
        }
      },

      loadSettings() {
        try {
          const savedSettingsStr = localStorage.getItem("arbitrage2");
          if (savedSettingsStr) {
            this.arbitrageSettings = JSON.parse(savedSettingsStr);
            if (!Array.isArray(this.arbitrageSettings.chainIds) || !this.arbitrageSettings.chainIds.every(chainId => typeof chainId === "number")) {
              this.arbitrageSettings.chainIds = [this.chainId];
            }
            if (!Array.isArray(this.arbitrageSettings.exchanges) || !this.arbitrageSettings.exchanges.every(exchange => typeof exchange === "string")) {
              this.arbitrageSettings.exchanges = ["binance", "okx"];
            }
            if (!Array.isArray(this.arbitrageSettings.excludeCoins)) {
              this.arbitrageSettings.excludeCoins = [];
            }
            if (!Array.isArray(this.arbitrageSettings.includeCoins)) {
              this.arbitrageSettings.includeCoins = [];
            }
            if (_.isNil(this.arbitrageSettings.minFundingRateArbitragePercent) || this.arbitrageSettings.minFundingRateArbitragePercent < 0) {
              this.arbitrageSettings.minFundingRateArbitragePercent = 0;
            }
          }
        } catch (e) {}
      },

      togglePauseUpdate() {
        this.isUpdatePaused = !this.isUpdatePaused;
      },

      doneChangeSettings() {
        this.loadSettings();
      },

      async refreshLoop() {
        clearTimeout(this.reloadTimeoutHandler);
        if (this.isDestroyed) return;

        try {
          this.isLoading = true;
          this.statusText = "Finding arbitrages...";
          const includeCoinsIds = new Set(this.arbitrageSettings.includeCoins.map(coin => coin.coingeckoId));

          if (this.isUpdatePaused) {
            const coingeckoIds = this.arbitrageCoins.map(coin => coin.coingeckoId);
            const getAssetsRet = await arbitrageService.getAssets({
              coingeckoIds,
              chainIds: this.arbitrageSettings.chainIds,
              exchanges: this.arbitrageSettings.exchanges,
              priceType: this.arbitrageSettings.priceType,
              showLongFuturesSellSpot: this.arbitrageSettings.showLongFuturesSellSpot
            });
            const arbitrageCoins = getAssetsRet.data;
            for (const coin of arbitrageCoins) {
              coin.isPinned = includeCoinsIds.has(coin.coingeckoId);
            }
            this.arbitrageCoins = arbitrageCoins;
            this.statusText = `Found ${arbitrageCoins.length} items in ${getAssetsRet.timeTaken}ms`;

          } else {
            const arbitrageSettings = _.omit(this.arbitrageSettings, ["excludeCoins", "includeCoins"]) as Record<string, any>;
            arbitrageSettings.excludeCoins = this.arbitrageSettings.excludeCoins.map(it => it.coingeckoId);
            arbitrageSettings.includeCoins = this.arbitrageSettings.includeCoins.map(it => it.coingeckoId);
            const findArbitrageRet = await arbitrageService.findArbitrage(arbitrageSettings);
            const arbitrageCoins = findArbitrageRet.data;
            for (const coin of arbitrageCoins) {
              coin.isPinned = includeCoinsIds.has(coin.coingeckoId);
            }
            this.arbitrageCoins = arbitrageCoins;
            this.statusText = `Found ${arbitrageCoins.length} items in ${findArbitrageRet.timeTaken}ms`;

            // check and notify
            const notifyCoins = [];
            let audioEl = this.$refs.audioEl_meet;
            for (const coin of arbitrageCoins) {
              if (coin.included) continue;
              const recentAlert = this.recentNotifyArbitrageItems[coin.coingeckoId];
              if (!recentAlert || Date.now() - recentAlert.ts >= 180000 || coin.highestArbitragePercent - recentAlert.arbitragePercent > 2) {
                notifyCoins.push(coin);
                this.recentNotifyArbitrageItems[coin.coingeckoId] = {
                  arbitragePercent: coin.highestArbitragePercent,
                  ts: Date.now()
                };
                if (coin.highestArbitragePercent >= 10) {
                  audioEl = this.$refs.audioEl_percussion;
                }
              }
            }
            if (notifyCoins.length) {
              audioEl.play();
              new Notification(`New Arbitrage (${notifyCoins.length})`, {
                body: notifyCoins.map(c => `${c.symbol.toUpperCase()} ${c.highestArbitragePercent.toFixed(2)}%`).join(", ")
              });
            }
          }

        } catch (e) {
          console.error(e);
          this.statusText = "⚠️ " + e.message;

        } finally {
          this.isLoading = false;
        }

        clearTimeout(this.reloadTimeoutHandler);
        this.reloadTimeoutHandler = setTimeout(() => this.refreshLoop(), 5000);
      },

      addToIncludeCoins(arbitrageItem) {
        if (this.arbitrageSettings.includeCoins.some(coin => coin.coingeckoId === arbitrageItem.coingeckoId)) return;

        this.arbitrageSettings.includeCoins.push({
          coingeckoId: arbitrageItem.coingeckoId,
          logoUrl: arbitrageItem.logoUrl,
          name: arbitrageItem.name,
          symbol: arbitrageItem.symbol.toUpperCase()
        });
        _.remove(this.arbitrageSettings.excludeCoins, (coin: any) => coin.coingeckoId === arbitrageItem.coingeckoId);
        this.saveSettings();
        this.toastSuccess("Alert", `Added ${arbitrageItem.symbol.toUpperCase()} to included list`);
      },

      addToExcludeCoins(arbitrageItem, index: number) {
        if (this.arbitrageSettings.excludeCoins.some(coin => coin.coingeckoId === arbitrageItem.coingeckoId)) return;

        this.arbitrageSettings.excludeCoins.push({
          coingeckoId: arbitrageItem.coingeckoId,
          logoUrl: arbitrageItem.logoUrl,
          name: arbitrageItem.name,
          symbol: arbitrageItem.symbol.toUpperCase()
        });
        _.remove(this.arbitrageSettings.includeCoins, (coin: any) => coin.coingeckoId === arbitrageItem.coingeckoId);
        this.saveSettings();

        this.arbitrageCoins.splice(index, 1);

        this.toastSuccess("Alert", `Added ${arbitrageItem.symbol.toUpperCase()} to excluded list`);
      },

      removeFromIncludeCoins(arbitrageItem) {
        _.remove(this.arbitrageSettings.includeCoins, (coin: any) => coin.coingeckoId === arbitrageItem.coingeckoId);
        this.saveSettings();
        this.toastSuccess("Alert", `Removed ${arbitrageItem.symbol.toUpperCase()} from included list`);
      },

      async onClickWithdraw(item) {
        this.dwItem = item;
        await utils.delay(0);
        this.$bvModal.show(this.cexWithdrawModalId);
        console.log("Arbitrage onClickWithdraw", item);
      },

      async onClickDeposit(item) {
        this.dwItem = item;
        await utils.delay(0);
        this.$bvModal.show(this.cexDepositModalId);
        console.log("Arbitrage onClickDeposit", item);
      },

      saveSettings() {
        localStorage.setItem("arbitrage2", JSON.stringify(this.arbitrageSettings));
      }
    },

    unmounted() {
      clearTimeout(this.reloadTimeoutHandler);
      this.isDestroyed = true;
    }
  };

</script>
