import { reactive } from "vue";

export const wallets = reactive({});
const startTime = Date.now();

function handleEIP6963Announce(event) {
  const { info, provider } = event.detail;

  if (wallets[info.rdns]) {
    Object.assign(wallets[info.rdns], event.detail);
    console.log("Wallet already detected:", info.rdns);

  } else if (provider) {
    console.log(`Detected new wallet:`, info.rdns);
    wallets[info.rdns] = {
      delay: Date.now() - startTime,
      ...event.detail
    };
  }
}

// Add event listener
addEventListener("eip6963:announceProvider", handleEIP6963Announce);
dispatchEvent(new Event("eip6963:requestProvider"));
