<template>
  <div class="mb-3 text-info border border-info d-flex flex-row align-items-center px-1 py-1">
    <div class="flex-grow-1 text-break">
      <span v-if="status === 'waiting'">
        <b-spinner small /> Swapping...
      </span>
      <span v-else-if="status === 'confirmed'">Transaction confirmed. </span>
      <span v-else-if="status === 'error'" class="text-danger text-break">{{ errorMsg }} </span>
      ·
      <a :href="getExplorerUrl(chainId, 'tx', confirmationStrategy.signature)" target="_blank">View in explorer</a>
    </div>
    <div class="cursor-pointer p-1" @click="onClickClose"><b-icon-x-circle/></div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import * as web3Service from "@/services/web3Service";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import type {TransactionConfirmationStrategy} from "@solana/web3.js";

  export default {
    name: "SolanaTxStatusBanner",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading", "getShortenedAddress", "getExplorerUrl"],
    props: ["confirmationStrategy"],
    emits: ["onClickClose"],

    data() {
      return {
        chainId: constants.CHAIN_ID_SOLANA,
        errorMsg: "",
        status: "waiting",
        isDestroyed: false,
      };
    },

    mounted() {
      this.process();
    },

    methods: {
      async process() {
        try {
          this.status = "waiting";
          console.log("SolanaTxStatusBanner waiting for", this.confirmationStrategy.signature);
          const txResponse = await web3Service.solanaWeb3.confirmTransaction(this.confirmationStrategy);
          if (txResponse.value.err) {
            throw txResponse.value.err;
          }
          this.status = "confirmed";

        } catch (e) {
          console.error(e);
          this.status = "error";
          if (e.InstructionError?.[1]?.Custom === 6001) {
            this.errorMsg = "Error 6001: Slippage tolerance exceeded";
          } else {
            this.errorMsg = e.response?.data?.description || e.response?.data?.error || e.message || e;
          }
        }
      },

      onClickClose() {
        this.$emit("onClickClose", this.status);
      }
    },

    unmounted() {
      this.isDestroyed = true;
      console.log("SolanaTxStatusBanner destroy");
    },
  };
</script>
