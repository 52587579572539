<template>
  <div>
    <draggable v-model="privateKeyWallets" ghost-class="bg-ghost" class="mb-3">
      <div v-for="w in privateKeyWallets" :key="w.address" class="border-bottom py-1" style="cursor: move;">
        {{ w.label || w.address }}
      </div>
    </draggable>
    <div class="text-center">
      <b-button variant="primary" @click="submit">Save</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bg-ghost {
  background-color: #fffeaf;
}
</style>

<script lang="ts">
  import _ from "lodash";
  import draggable from "vuedraggable";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as constants from "@/constants";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";

  export default {
    name: "ArrangePrivateKeyWalletsModal",
    components: { draggable },
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId"],
    emits: ["done"],

    data() {
      return {
        privateKeyWallets: [],
      };
    },

    mounted() {
      this.loadWallets();
    },

    methods: {
      loadWallets() {
        this.privateKeyWallets = privateKeyWalletService.getWallets();
      },

      async submit() {
        try {
          privateKeyWalletService.saveWalletsNoValidate(this.privateKeyWallets);
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.toastError(e);
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
