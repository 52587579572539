<template>
  <nav class="sticky-top bg-white border-bottom">
    <b-container class="d-flex flex-row align-items-center justify-content-between flex-wrap py-1">
      <div class="flex-shrink-0">
        <b-button variant="link" to="/swap">Swap</b-button>
        <template v-if="currentAddress">
          <b-button v-if="currentAddress" variant="link" to="/cex-wallet">CEX wallet</b-button>
          <b-button v-if="currentAddress" variant="link" to="/arbitrage">Arbitrage</b-button>
          <b-button v-if="currentAddress" variant="link" to="/cex-hedging">CEX Hedging</b-button>
          <b-button id="header-more-btn" variant="link" class="text-decoration-none">
            More<small><b-icon-caret-down/></small>
          </b-button>
          <b-popover target="header-more-btn" triggers="hover" placement="bottom">
            <b-button class="d-block" variant="link" to="/cex-balance-watch-orders">Balance watch orders</b-button>
            <b-button class="d-block" variant="link" to="/delist-trade-orders">Delist trade orders</b-button>
            <b-button class="d-block" variant="link" to="/feed">Feed</b-button>
            <b-button class="d-block" variant="link" to="/cex-accounts">Manage CEX accounts</b-button>
            <b-button class="d-block" variant="link" to="/private-key-wallets">Private key wallets</b-button>
          </b-popover>
        </template>
      </div>
      <div>
        <b-button v-if="!currentAddress" variant="link" v-b-modal:change-user-modal>Sign in</b-button>
        <div class="text-right" v-else>
          <div>
            <img v-if="currentAddress === walletAddress"
                 :src="connectedWallet.icon" :alt="connectedWallet.label" v-b-tooltip :title="connectedWallet.label"
                 class="mr-1" style="width: 20px; height: 20px;" />
<!--            <img :src="networkLogoUrl" :alt="networkName" v-b-tooltip :title="networkName"
                 class="mr-1" style="width: 20px; height: 20px;" />-->
            <small class="text-monospace">{{ currentAddress }}</small>
          </div>
          <div>
            <small>
              <span class="text-secondary cursor-pointer" v-if="displaySessionTimeLeft"
                    @click="onClickRenewSession"
                    v-b-tooltip:hover title="Renew session">
                <b-icon-clock /> {{ displaySessionTimeLeft }} ·
              </span>
<!--              <a class="text-secondary" :href="explorerUrl" target="_blank">Explorer</a> ·-->
              <a class="text-secondary" :href="debankUrl" target="_blank">DeBank</a> ·
              <span class="text-secondary cursor-pointer" v-b-modal:change-user-modal>Accounts</span>
            </small>
          </div>
        </div>
      </div>
    </b-container>
  </nav>

  <b-modal id="change-user-modal" title="Accounts" hide-footer no-fade>
    <ChangeUserModal modalId="change-user-modal" />
  </b-modal>
</template>

<style lang="scss" scoped>

</style>

<script setup lang="ts">
  import { ref, reactive, readonly, onMounted, onDeactivated, computed, watch, inject } from "vue";
  import { useOnboard } from "@web3-onboard/vue";
  import { walletAddress } from "@/services/onboard";
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import {ethers} from "ethers";
  import * as authService from "@/services/authService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import {DateTime} from "luxon";
  import ChangeUserModal from "@/components/ChangeUserModal.vue";

  const { connectedWallet } = useOnboard();
  const toastError = inject<(e: any) => void>("toastError");

  const currentAddress = ref("");
  const displaySessionTimeLeft = ref("");
  let refreshAccountTimeoutHandler = null;
  let isDestroyed = false;

  const debankUrl = computed(() => currentAddress.value && `https://debank.com/profile/${currentAddress.value}`);

  onMounted(() => {
    currentAddress.value = authService.getCurrentAddress();
    refreshAccountState();
  });

  function refreshAccountState() {
    clearTimeout(refreshAccountTimeoutHandler);
    if (isDestroyed) return;

    try {
      if (currentAddress.value) {
        const auth = JSON.parse(localStorage.getItem("auth-" + currentAddress.value));
        if (auth) {
          const tsDiffToNow = DateTime.fromMillis(auth.ts ?? 0).plus({ days: 6 }).diffNow();
          if (tsDiffToNow.as("milliseconds") > 0) {
            displaySessionTimeLeft.value = tsDiffToNow.toFormat("hh'h' mm'm'");
          } else {
            displaySessionTimeLeft.value = "Session expired";
          }
        } else {
          displaySessionTimeLeft.value = "";
        }
      } else {

      }

    } catch (e) {
      console.error(e);
    }

    clearTimeout(refreshAccountTimeoutHandler);
    refreshAccountTimeoutHandler = setTimeout(() => refreshAccountState(), 5000);
  }

  async function onClickRenewSession() {
    try {
      await authService.refreshAuth();
      await refreshAccountState();
    } catch (e) {
      toastError(e);
    }
  }

  onDeactivated(() => {
    clearTimeout(refreshAccountTimeoutHandler);
    isDestroyed = true;
  });

</script>
