<template>
  <div>
    <div>
      <span class="text-success">{{ buySide.type === "futures" ? "LONG" : "BUY" }}</span>
      {{ buySide.baseAsset }} on {{ buyExchangeName }}
    </div>
    <div>
      <span class="text-danger">{{ sellSide.type === "futures" ? "SHORT" : "SELL" }}</span>
      {{ sellSide.baseAsset }} on {{ sellExchangeName }}
    </div>
    <div v-if="errorMsg" class="text-danger py-2">{{ errorMsg }}</div>
    <div class="text-right mt-3">
      <b-button variant="secondary mr-2" :disabled="isChecking" @click="onClickCancel">Cancel</b-button>
      <b-button variant="primary" :disabled="isChecking" @click="onClickOk">OK</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as constants from "@/constants";
  import * as cexHedgingService from "@/services/cexHedgingService";

  export default {
    name: "CexHedgingConfirmMarketModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "buySide", "sellSide"],
    emits: ["done"],

    data() {
      return {
        errorMsg: "",
        isChecking: false,
      };
    },

    computed: {
      buyExchangeName() {
        return constants.exchangeNames[this.buySide.marketExchange];
      },
      sellExchangeName() {
        return constants.exchangeNames[this.sellSide.marketExchange];
      }
    },

    methods: {
      onClickCancel() {
        this.$bvModal.hide(this.modalId);
      },

      async onClickOk() {
        try {
          this.errorMsg = "";
          this.isChecking = true;
          await cexHedgingService.checkAccounts({
            buy: _.pick(this.buySide, ["cexAccountId", "type", "symbol"]),
            sell: _.pick(this.sellSide, ["cexAccountId", "type", "symbol"]),
          });
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.errorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isChecking = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
