<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Label">
      <b-form-input type="text" v-model="label" placeholder="optional" minlength="0" maxlength="200"></b-form-input>
    </b-form-group>
    <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";

  export default {
    name: "EditCexAccountLabel",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "cexAccount"],
    emits: ["done"],

    data() {
      return {
        label: "",
        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    mounted() {
      this.label = this.cexAccount.label;
    },

    methods: {
      beforeSubmit() {
        this.label = this.label.trim();
      },

      async submitForm() {
        try {
          this.formErrorMsg = "";
          this.isSubmitting = true;

          await cexAccountService.editLabel({
            id: this.cexAccount.id,
            label: this.label
          });
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
