import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as utils from "@/utils";

import App from './App.vue';
import router from './router';

import "@/services/onboard";
import "@/services/eip6963";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSelect from "vue-select";
import "@/app.scss";

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(BootstrapVue);
app.use(IconsPlugin);

app.component('v-select', VueSelect);

app.provide("getShortenedAddress", utils.getShortenedAddress);
app.provide("getExplorerUrl", utils.getExplorerUrl);

app.mount('#app');
