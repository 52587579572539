<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Exchange">
      <b-form-radio-group required v-model="formInput.exchange" :options="exchangeOptions"></b-form-radio-group>
    </b-form-group>
    <b-form-group label="Whitelist IP address">
      <b-input-group>
        <b-form-input type="text" readonly :value="whitelistIpTextByExchange[formInput.exchange]"></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-secondary" @click="onClickCopyIp" v-b-tooltip.click.blur title="Copied">
            <b-icon-files></b-icon-files>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Label">
      <b-form-input type="text" v-model="formInput.label" minlength="0" maxlength="100"></b-form-input>
    </b-form-group>
    <b-form-group label="API key">
      <b-form-input type="text" class="text-monospace" autocomplete="off" v-model="formInput.apiKey" minlength="1" maxlength="200" required></b-form-input>
    </b-form-group>
    <b-form-group label="Secret key">
      <b-form-input type="text" class="text-monospace" autocomplete="off" v-model="formInput.secret" minlength="1" maxlength="200" required></b-form-input>
    </b-form-group>
    <b-form-group label="Passphrase" v-if="shouldShowPasswordInput">
      <b-form-input type="text" class="text-monospace" autocomplete="off" v-model="formInput.password" minlength="1" maxlength="200" required></b-form-input>
    </b-form-group>
    <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
    <div class="text-right">
      <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Save</b-button>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";

  export default {
    name: "AddCexAccountModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId"],
    emits: ["done"],

    data() {
      return {
        exchangeOptions: [
          { text: "Binance", value: "binance" },
          { text: "Huobi", value: "huobi" },
          { text: "OKX", value: "okx" },
          { text: "Kucoin", value: "kucoin" },
          { text: "MEXC", value: "mexc" },
          { text: "Gate", value: "gate" },
          { text: "Bybit", value: "bybit" },
          { text: "Bitget", value: "bitget" },
        ],
        whitelistIpTextByExchange: {
          binance: "202.182.119.232",
          huobi: "202.182.119.232,2001:19f0:7001:79d:5400:4ff:fe51:5572",
          okx: "202.182.119.232,2001:19f0:7001:79d:5400:4ff:fe51:5572",
          kucoin: "202.182.119.232,2001:19f0:7001:79d:5400:4ff:fe51:5572",
          mexc: "202.182.119.232",
          gate: "202.182.119.232,2001:19f0:7001:79d:5400:4ff:fe51:5572",
          bybit: "202.182.119.232",
          bitget: "202.182.119.232",
        },

        formInput: {
          exchange: "",
          label: "",
          apiKey: "",
          secret: "",
          password: "",
        },

        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    computed: {
      shouldShowPasswordInput() {
        return ["okx", "kucoin", "bitget"].includes(this.formInput.exchange);
      }
    },

    methods: {
      onClickCopyIp() {
        navigator.clipboard.writeText(this.whitelistIpTextByExchange[this.formInput.exchange] || "");
      },

      beforeSubmit() {
        const trimFields = ["label", "apiKey", "secret"];
        for (const k of trimFields) {
          this.formInput[k] = this.formInput[k].trim();
        }
      },

      async submitForm() {
        try {
          this.isSubmitting = true;
          await cexAccountService.add(this.formInput);
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
