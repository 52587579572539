<template>
  <div>
    <b-form-input ref="searchInput" v-if="!stableCoinsOnly" type="search" @input="searchTextChangeDebounced" class="mb-2" placeholder="Search by name or paste address" />
    <div class="no-scrollbar" :class="stableCoinsOnly ? '' : 'token-list-mode-normal'">
      <div v-if="!stableCoinsOnly" class="d-flex flex-row flex-wrap align-items-center py-2 border-bottom">
        <div v-for="token in pinnedTokens" :key="token.address"
             class="pinned-token-item flex-shrink-0 d-flex flex-row align-items-center mr-2 mb-2 px-2 border rounded bg-hover cursor-pointer position-relative"
             style="height: 36px;" @click="pickToken(token)">
          <img class="flex-shrink-0 d-block mr-2" :src="token.logo" style="width: 20px; height: 20px;">
          <div class="flex-shrink-0">{{ token.symbol }}</div>
          <div class="del-btn-container" @click="onClickDeletePinnedToken($event, token)"><b-icon-x-circle-fill/></div>
        </div>
      </div>
      <div v-for="token in displayTokens" :key="token.address"
           class="d-flex flex-row align-items-center py-1 border-bottom cursor-pointer bg-hover"
           @click="pickToken(token)">
        <div class="flex-shrink-0 mr-3" style="width: 40px; height: 40px; overflow: hidden;">
          <img v-if="token.logo" :src="token.logo" style="width: 100%; height: 100%;">
        </div>
        <div class="flex-grow-1 min-width-0 text-nowrap">
          <div class="overflow-hidden text-overflow-ellipsis">
            {{ token.name }} <b-badge class="ml-1" variant="warning" v-if="token.isImported">imported</b-badge>
          </div>
          <div class="overflow-hidden text-overflow-ellipsis text-secondary">
            {{ token.displayBalance }} {{ token.symbol }}
          </div>
        </div>
        <div class="flex-shrink-0" v-if="token.isImported">
          <b-button variant="link" size="sm" class="text-danger"
                    @click="onClickDeleteCustomToken($event, token)">
            <b-icon-trash/>
          </b-button>
        </div>
        <div v-if="!stableCoinsOnly" class="flex-shrink-0">
          <b-button variant="link" size="sm" @click="onClickPinToken($event, token)"><b-icon-pin/></b-button>
        </div>
        <div class="flex-shrink-0 p-2" v-if="!token.isNative">
          <b-icon-info-circle :id="'icon-info-' + token.address"></b-icon-info-circle>
          <b-tooltip :target="'icon-info-' + token.address">
            <a class="small text-light" :href="token.explorerUrl" target="_blank">{{ token.address }}</a>
          </b-tooltip>
        </div>
      </div>
      <div v-if="newCustomToken" class="d-flex flex-row align-items-center py-1 border-bottom">
        <div class="flex-shrink-0 mr-3" style="width: 40px; height: 40px; overflow: hidden;">
          <img v-if="newCustomToken.logo" :src="newCustomToken.logo" style="width: 100%; height: 100%;">
        </div>
        <div class="flex-grow-1 min-width-0 text-nowrap">
          <div class="overflow-hidden text-overflow-ellipsis">{{ newCustomToken.name }}</div>
          <div class="overflow-hidden text-overflow-ellipsis text-secondary">
            {{ newCustomToken.displayBalance }} {{ newCustomToken.symbol }}
          </div>
        </div>
        <div>
          <b-button variant="link" @click="importCustomToken">Import</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .pinned-token-item {
    .del-btn-container {
      width: 24px;
      height: 24px;
      display: grid;
      place-items: center;
      position: absolute;
      visibility: hidden;
      top: -10px;
      right: -7px;
    }
    &:hover .del-btn-container, .del-btn-container:hover {
      visibility: visible;
    }
  }
  .token-list-mode-normal {
    height: 70vh;
    overflow-y: scroll;
  }
</style>

<script lang="ts">
  import _ from "lodash";
  import {ethers} from "ethers";
  import * as utils from "@/utils";
  import * as customTokensService from "@/services/customTokensService";
  import * as web3Service from "@/services/web3Service";
  import * as constants from "@/constants";
  import BigNumber from "bignumber.js";
  import {BFormInput} from "bootstrap-vue";

  const stableTokensByChain: Record<string, string[]> = {
    [constants.CHAIN_ID_ETH]: [
      "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      "0x6B175474E89094C44Da98b954EedeAC495271d0F"
    ],
    [constants.CHAIN_ID_SOLANA]: [
      "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
      "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    ],
  };

  export default {
    name: "PickTokenModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["chainId", "walletAddress", "stableCoinsOnly"],
    emits: ["pickToken"],

    data() {
      return {
        searchText: "",
        pinnedTokens: [],
        allTokens: [],
        newCustomToken: null as customTokensService.CustomToken,
      };
    },

    computed: {
      displayTokens() {
        if (this.stableCoinsOnly) {
          const stableTokenAddresses = stableTokensByChain[this.chainId];
          if (utils.isEvmChain(this.chainId)) {
            return this.allTokens.filter(token => stableTokenAddresses.some(stableTokenAddress => token.address.toLowerCase() === stableTokenAddress.toLowerCase()));
          } else {
            return this.allTokens.filter(token => stableTokenAddresses.includes(token.address));
          }

        } else {
          const text = this.searchText.trim().toLowerCase();
          const ret = [];

          for (const token of this.allTokens) {
            token.sortScore = 0;

            const address = token.address.toLowerCase();
            const symbol = token.symbol?.toLowerCase() || "";
            const name = token.name?.toLowerCase() || "";

            if (address === text) {
              token.sortScore += 100;
            } else if (address.includes(text)) {
              token.sortScore += 3;
            }

            if (symbol === text) {
              token.sortScore += 50;
            } else if (symbol.startsWith(text)) {
              token.sortScore += 25;
            } else if (symbol.includes(text)) {
              token.sortScore += 2;
            }

            if (name === text) {
              token.sortScore += 25;
            } else if (name.startsWith(text)) {
              token.sortScore += 12;
            } else if (name.includes(text)) {
              token.sortScore += 1;
            }

            if (token.sortScore > 0) {
              ret.push(token);
              if (ret.length >= 100) break;
            }
          }

          return _.orderBy(ret, ["sortScore"], ["desc"]);
        }
      },
    },

    created() {
      this.searchTextChangeDebounced = _.debounce(this.searchTextChange, 200);
    },
    async mounted() {
      this.fetchTokensAndWallet();
      await utils.delay();
      (this.$refs.searchInput as BFormInput)?.focus();
    },

    methods: {
      async fetchTokensAndWallet() {
        this.pinnedTokens = customTokensService.getAllPinnedTokens(this.chainId);

        const isEvm = utils.isEvmChain(this.chainId);
        const allTokens = [];

        // tokens from saved list
        const customTokens = customTokensService.getAllCustomTokens(this.chainId);
        for (const tokenObj of customTokens) {
          allTokens.push({ ...tokenObj, balance: "0", isImported: true });
        }
        const customTokenAddresses = new Set(allTokens.map(it => isEvm ? it.address.toLowerCase() : it.address));

        // commonly listed tokens
        const listedTokensKeyed = await web3Service.getListedTokens(this.chainId);
        const listedTokens: any[] = Object.values(listedTokensKeyed);
        for (const tokenObj of listedTokens) {
          if (!customTokenAddresses.has(isEvm ? tokenObj.address.toLowerCase() : tokenObj.address)) {
            allTokens.push({
              balance: "0",
              address: isEvm ? ethers.getAddress(tokenObj.address) : tokenObj.address,
              decimals: tokenObj.decimals,
              name: tokenObj.name,
              symbol: tokenObj.symbol,
              logo: tokenObj.logoURI
            });
          }
        }

        // assign other props for displaying if necessary
        for (const tokenObj of this.pinnedTokens) {
          const officialTokenObj = listedTokensKeyed[isEvm ? tokenObj.address.toLowerCase() : tokenObj.address];
          if (officialTokenObj?.logoURI) {
            tokenObj.logo = officialTokenObj.logoURI;
          }
        }

        // balances
        const walletTokenBalances = web3Service.getCachedWalletTokenBalances(this.chainId, this.walletAddress);
        console.log("PickTokenModal walletTokenBalances", walletTokenBalances);

        for (const tokenObj of allTokens) {
          const oneInchTokenObj = listedTokensKeyed[isEvm ? tokenObj.address.toLowerCase() : tokenObj.address];
          if (oneInchTokenObj?.logoURI) {
            tokenObj.logo = oneInchTokenObj.logoURI;
          }
          if (oneInchTokenObj?.name) {
            tokenObj.name = oneInchTokenObj.name;
          }

          tokenObj.balance = walletTokenBalances[isEvm ? tokenObj.address.toLowerCase() : tokenObj.address] || "0";

          tokenObj.displayBalance = BigNumber(tokenObj.balance)
            .shiftedBy(-tokenObj.decimals)
            .decimalPlaces(8, BigNumber.ROUND_DOWN)
            .toFixed();

          if (isEvm) {
            tokenObj.isNative = tokenObj.address.toLowerCase() === constants.NATIVE_ASSET_ADDRESS.toLowerCase();
          } else if (this.chainId === constants.CHAIN_ID_SOLANA) {
            tokenObj.isNative = tokenObj.address === constants.WSOL;
            if (tokenObj.isNative) {
              tokenObj.name = "Solana";
            }
          } else if (this.chainId === constants.CHAIN_ID_TRON) {
            tokenObj.isNative = tokenObj.address === constants.ZERO_ADDRESS_TRON;
          }
          tokenObj.explorerUrl = tokenObj.isNative ? null : utils.getExplorerUrl(this.chainId, "token", tokenObj.address);
        }

        this.allTokens = _.orderBy(allTokens, [
          it => it.isNative ? 0 : 1,
          it => it.balance !== "0" ? 0 : 1,
          it => it.isImported ? 0 : 1,
        ]);
      },

      async searchTextChange(searchText) {
        this.searchText = searchText;

        if (this.displayTokens.length === 0) {
          if (utils.isEvmChain(this.chainId) && ethers.isAddress(searchText)) {
            const address = ethers.getAddress(searchText);
            const getTokenMetadataP = web3Service.getEvmTokenMetadata(this.chainId, address);
            const getTokenBalanceP = web3Service.getEvmTokenBalance(this.chainId, this.walletAddress, address);

            const tokenMetadata = await getTokenMetadataP;
            const balance = (await getTokenBalanceP).toString();
            const displayBalance = BigNumber(balance).shiftedBy(-tokenMetadata.decimals).decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();

            if (tokenMetadata && utils.isTokenDecimalsValid(tokenMetadata.decimals) && tokenMetadata.symbol) {
              this.newCustomToken = { ...tokenMetadata, address, balance, displayBalance };
            }
          } else if (this.chainId === constants.CHAIN_ID_SOLANA && utils.solanaPublicKeyOrNull(searchText)) {
            const tokenMetadata = await web3Service.getSolanaTokenMetadata(searchText);
            const cachedBalances = web3Service.getCachedWalletTokenBalances(constants.CHAIN_ID_SOLANA, this.walletAddress);
            const balance = cachedBalances[searchText] || 0;
            const displayBalance = BigNumber(balance).shiftedBy(-tokenMetadata.decimals).decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();
            this.newCustomToken = { ...tokenMetadata, address: searchText, balance, displayBalance };
          }

        } else {
          this.newCustomToken = null;
        }
      },

      pickToken(tokenObj: any) {
        this.$emit("pickToken", tokenObj);
      },

      importCustomToken() {
        customTokensService.addCustomToken(this.chainId, this.newCustomToken);
        this.pickToken(this.newCustomToken);
      },

      onClickPinToken(event: Event, token: any) {
        console.log("onClickPinToken", event, token);
        event.stopPropagation();
        customTokensService.addPinnedToken(this.chainId, token);

        const index = this.pinnedTokens.findIndex(t => t.address.toLowerCase() === token.address.toLowerCase());
        if (index >= 0) {
          this.pinnedTokens.splice(index, 1, token);
        } else {
          this.pinnedTokens.push(token);
        }
      },

      onClickDeletePinnedToken(event: Event, token: any) {
        console.log("onClickDeletePinnedToken", event, token);
        event.stopPropagation();
        customTokensService.deletePinnedToken(this.chainId, token.address);
        this.pinnedTokens = this.pinnedTokens.filter(it => it.address.toLowerCase() !== token.address.toLowerCase());
      },

      onClickDeleteCustomToken(event: Event, token: any) {
        console.log("onClickDeleteCustomToken", event, token);
        event.stopPropagation();

        customTokensService.deleteCustomToken(this.chainId, token.address);
        this.allTokens = this.allTokens.filter(it => it.address.toLowerCase() !== token.address.toLowerCase());

        customTokensService.deletePinnedToken(this.chainId, token.address);
        this.pinnedTokens = this.pinnedTokens.filter(it => it.address.toLowerCase() !== token.address.toLowerCase());
      }
    }
  };
</script>
