<template>
  <div>
    <div class="text-center">
      <b-button variant="link" class="text-decoration-none" v-b-modal:create-new-preset-modal>
        <b-icon-plus class="mr-1"/> Save new preset
      </b-button>
    </div>
    <div v-if="isLoading" class="text-center text-secondary py-3">
      Loading saved presets...
    </div>
    <template v-else>
      <div v-for="preset in presets" :key="preset.id" class="py-2 border-bottom bg-hover d-flex flex-row align-items-center justify-content-between">
        <div class="min-width-0 text-break mr-3">
          <div>{{ preset.label }}</div>
          <div class="text-secondary">{{ preset.displayUpdatedAt }}</div>
        </div>
        <div class="flex-shrink-0 text-secondary">
          <b-button variant="link" size="sm" @click="onClickLoadPreset(preset)">Import</b-button>
          <b-button variant="link" size="sm" @click="onClickUpdatePreset(preset)">Save</b-button>
          <b-button variant="link" class="text-danger" size="sm" @click="onClickDeletePreset(preset)">Delete</b-button>
        </div>
      </div>
    </template>
    <b-modal id="create-new-preset-modal" title="Create new preset" hide-footer no-fade no-close-on-backdrop>
      <CreateNewPresetModal modal-id="create-new-preset-modal" @done="fetchSavedPresets"></CreateNewPresetModal>
    </b-modal>
    <div v-if="errorMsg" class="text-danger">{{ errorMsg }}</div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import { DateTime } from "luxon";
  import * as savedPresetsService from "@/services/savedPresetsService";
  import CreateNewPresetModal from "@/components/CreateNewPresetModal.vue";

  export default {
    name: "SavedPresetsModal",
    components: { CreateNewPresetModal },
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId"],
    emits: ["usePreset"],

    data() {
      return {
        isLoading: true,
        presets: [],
        errorMsg: "",
      };
    },

    mounted() {
      this.fetchSavedPresets();
    },

    methods: {
      async fetchSavedPresets() {
        try {
          this.isLoading = true;
          this.errorMsg = "";
          this.presets = await savedPresetsService.getSavedPresets();
          for (const preset of this.presets) {
            preset.displayCreatedAt = DateTime.fromISO(preset.createdAt).toFormat("yyyy-MM-dd HH:mm:ss");
            preset.displayUpdatedAt = DateTime.fromISO(preset.updatedAt).toFormat("yyyy-MM-dd HH:mm:ss");
          }

        } catch (e) {
          console.error(e);
          this.errorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isLoading = false;
        }
      },

      async onClickLoadPreset(preset: any) {
        const ok = await this.$bvModal.msgBoxConfirm("Import this preset? This will overwrite your current settings.", {
          okTitle: "Import"
        });

        if (ok) {
          this.$bvModal.hide(this.modalId);
          this.usePreset(preset.data);
        }
      },

      async onClickUpdatePreset(preset: any) {
        const ok = await this.$bvModal.msgBoxConfirm("Overwrite this preset with your current settings?", {
          okTitle: "Overwrite"
        });

        if (ok) {
          await savedPresetsService.update(preset.id);
          this.fetchSavedPresets();
        }
      },

      async onClickDeletePreset(preset: any) {
        const ok = await this.$bvModal.msgBoxConfirm("Delete this preset?", {
          okVariant: "danger",
          okTitle: "Delete"
        });

        if (ok) {
          await savedPresetsService.del(preset.id);
          this.presets = this.presets.filter(it => it.id !== preset.id);
        }
      },

      usePreset(presetData: any) {
        this.$emit("usePreset", presetData);
      }
    },
  };
</script>
