<template>
  <b-form @keydown.enter.prevent @submit.prevent="submitForm">
    <b-form-group label="Network">
      <b-form-radio-group :options="networkOptions" v-model="network" required @input="onChangeWithdrawNetwork"></b-form-radio-group>
    </b-form-group>
    <template v-if="network">
      <div class="d-flex flex-row align-items-center justify-content-between mb-2">
        <label class="m-0">Address</label>
        <div>
          <div class="text-primary">
            <span class="cursor-pointer mr-4" v-b-modal:address-book-modal><b-icon-bookmark/> Address book</span>
            <span class="cursor-pointer" v-b-modal:save-wallet-modal><b-icon-plus-lg/> Save new</span>
          </div>
        </div>
      </div>
      <v-select class="vs-normalizer mb-3" :hidden="!!addressLabel"
                label="label"
                :getOptionKey="s => s.address + ':' + s.memo"
                :filter="addressSelectDropdownFilter"
                :options="savedWallets"
                :clearSearchOnBlur="() => false"
                v-on:option:selecting="onPickSavedWallet">
        <template v-slot:search="{attributes, events}">
          <input v-bind="attributes" v-on="events" autocomplete="off"
                 :value="address"
                 @input="address = $event.target.value"
                 @blur="address = address?.trim() || ''"
                 class="form-control text-monospace" required/>
        </template>
        <template v-slot:option="savedWallet">
          <div class="py-1 border-bottom">
            <div class="text-secondary">{{ savedWallet.label }}</div>
            <div class="text-monospace text-break">{{ savedWallet.address }}</div>
            <div v-if="selectedNetwork.hasMemo">
              <span class="text-secondary">MEMO: </span>
              <span class="text-secondary" v-if="!savedWallet.memo">none</span>
              <span class="text-monospace">{{ savedWallet.memo }}</span>
            </div>
          </div>
        </template>
      </v-select>
      <div v-if="addressLabel" class="border rounded d-flex flex-row align-items-center mb-3 p-2">
        <div class="flex-grow-1 min-width-0">
          <div class="overflow-hidden text-secondary text-overflow-ellipsis">{{ addressLabel }}</div>
          <div class="text-monospace text-break">{{ address }}</div>
          <div v-if="selectedNetwork.hasMemo">
            <span class="text-secondary">MEMO: </span>
            <span class="text-secondary" v-if="!memo">none</span>
            <span class="text-monospace">{{ memo }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <b-button variant="link" class="text-secondary" size="sm" @click="clearAddress"><b-icon-x-circle/></b-button>
        </div>
      </div>

      <b-form-group v-if="selectedNetwork.hasMemo && !addressLabel" label="Memo/tag">
        <b-form-input type="text" v-model="memo" autocomplete="off"/>
      </b-form-group>

      <div class="d-flex flex-row align-items-center justify-content-between mb-2">
        <div>Amount</div>
        <div><small class="text-secondary cursor-pointer" @click="onClickBalance">Available: {{ available }}</small></div>
      </div>
      <b-form-group>
        <b-form-input type="number" required v-model="amount" autocomplete="off"
                      :min="0"
                      :step="selectedNetwork.withdrawAmountStep"/>
      </b-form-group>
      <div><span class="text-secondary">Fee:</span> {{ selectedNetwork.withdrawFee }}</div>
      <div><span class="text-secondary">Will receive:</span> {{ displayWillReceiveAmount }}</div>
      <div v-if="formErrorMsg" class="text-danger">{{ formErrorMsg }}</div>
      <div class="text-right">
        <b-button type="submit" variant="primary" @click="beforeSubmit" :disabled="isSubmitting">Withdraw</b-button>
      </div>
    </template>

    <b-modal id="address-book-modal" title="Address book" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <AddressBookModal @pickWallet="onPickSavedWallet"></AddressBookModal>
    </b-modal>
    <b-modal id="save-wallet-modal" title="Save new wallet" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <SaveWalletModal modal-id="save-wallet-modal" :prefillAddress="address" :prefillMemo="memo" @done="onPickSavedWallet"></SaveWalletModal>
    </b-modal>
  </b-form>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as marketDataService from "@/services/marketDataService";
  import BigNumber from "bignumber.js";
  import * as cexAccountService from "@/services/cexAccountService";
  import * as web3Service from "@/services/web3Service";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import * as savedWalletsService from "@/services/savedWalletsService";
  import AddressBookModal from "@/components/AddressBookModal.vue";
  import SaveWalletModal from "@/components/SaveWalletModal.vue";

  export default {
    name: "WithdrawCexAssetModal",
    components: {SaveWalletModal, AddressBookModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "cexAccountId", "cexAccountEvmAddress", "exchange", "asset", "available"],
    emits: ["done"],

    data() {
      return {
        clientWithdrawOrderId: utils.randomStr(12),

        assetDetails: null,

        networkOptions: [],
        network: "",

        savedWallets: [],

        addressLabel: "",
        address: "",

        memo: "",
        amount: "",

        formErrorMsg: "",
        isSubmitting: false,
      };
    },

    computed: {
      selectedNetwork() {
        return this.assetDetails.networks.find(it => it.network === this.network);
      },

      displayWillReceiveAmount() {
        const willReceiveAmountBN = BigNumber(this.amount).minus(this.selectedNetwork.withdrawFee);
        if (willReceiveAmountBN.gt(0)) {
          return willReceiveAmountBN.toFixed();
        }
        return undefined;
      }
    },

    mounted() {
      this.setup();
    },

    methods: {
      async setup() {
        this.reloadSavedWallets();

        const fetchCexAsset = marketDataService.getCexAssets({
          exchange: this.exchange,
          asset: this.asset
        });
        this.assetDetails = (await fetchCexAsset)[0];

        this.networkOptions = this.assetDetails.networks.map(it => ({
          value: it.network,
          text: this.exchange === "binance"? it.network : it.name,
          disabled: !it.withdrawEnabled
        }));

        const chainId = await web3Service.getConnectedEvmChainId();

        // networks to find default
        const networks = this.assetDetails.networks.filter(it => it.withdrawEnabled);
        this.network = networks.find(it => it.chainId === chainId)?.network;

        if (this.network) {
          // found matching network, assign address
          this.address = await web3Service.getConnectedEvmAddress();
        } else {
          // use default network
          this.network = this.assetDetails.networks.find(it => it.isDefault && it.withdrawEnabled)?.network;
        }
      },

      reloadSavedWallets() {
        this.savedWallets = savedWalletsService.getAllSavedWallets2()
          .filter(it => it.address.toLowerCase() !== this.cexAccountEvmAddress.toLowerCase());
      },

      onChangeWithdrawNetwork() {
        this.addressLabel = "";
      },

      addressSelectDropdownFilter(options: any[], search: string) {
        search = utils.sanitizeSearchText(search).toLowerCase();
        return options.filter(savedWallet =>
          savedWallet.label.toLowerCase().includes(search) ||
          savedWallet.address.toLowerCase().includes(search)
        );
      },

      onPickSavedWallet(savedWallet: savedWalletsService.SavedWallet) {
        if (savedWallet.address.toLowerCase() === this.cexAccountEvmAddress.toLowerCase()) {
          this.toastError(new Error("Cannot select this address"));
          return;
        }

        this.address = savedWallet.address;
        this.memo = savedWallet.memo;
        this.addressLabel = savedWallet.label;
        this.$bvModal.hide("address-book-modal");
      },

      clearAddress() {
        this.addressLabel = "";
        this.address = "";
        this.memo = "";
      },

      onClickBalance() {
        const numLotsBN = BigNumber(this.available)
          .dividedBy(this.selectedNetwork.withdrawAmountStep)
          .decimalPlaces(0, BigNumber.ROUND_DOWN);

        this.amount = numLotsBN.multipliedBy(this.selectedNetwork.withdrawAmountStep).toFixed();
      },

      beforeSubmit() {
        this.address = this.address.trim();
        this.memo = this.memo.trim();
      },

      async submitForm() {
        try {
          this.formErrorMsg = "";
          this.isSubmitting = true;

          await cexAccountService.withdraw({
            clientWithdrawOrderId: this.clientWithdrawOrderId,
            cexAccountId: this.cexAccountId,
            asset: this.asset,
            network: this.network,
            address: this.address,
            memo: (this.selectedNetwork.hasMemo? this.memo : undefined) || undefined,
            amount: this.amount,
            fee: this.selectedNetwork.withdrawFee
          });
          this.$bvModal.hide(this.modalId);
          this.done();

        } catch (e) {
          console.error(e);
          this.formErrorMsg = e.response?.data?.message || e.message;

        } finally {
          this.isSubmitting = false;
        }
      },

      done() {
        this.$emit("done");
      }
    }
  };
</script>
