import { createRouter, createWebHistory } from 'vue-router'
import * as constants from '@/constants';
import Swap from "@/views/Swap.vue";
import CexAccounts from "@/views/CexAccounts.vue";
import CexWallet from "@/views/CexWallet.vue";
import CexBalanceWatchOrders from "@/views/CexBalanceWatchOrders.vue";
import Arbitrage2 from "@/views/Arbitrage2.vue";
import TestVueSelect from "@/views/TestVueSelect.vue";
import ArbitrageFocus from "@/views/ArbitrageFocus.vue";
import Feed from "@/views/Feed.vue";
import Debug from "@/views/Debug.vue";
import CexHedging from "@/views/CexHedging.vue";
import SwapSolana from "@/views/SwapSolana.vue";
import ArbitrageFocusSolana from "@/views/ArbitrageFocusSolana.vue";
import SwapTron from "@/views/SwapTron.vue";
import Hedging2 from "@/views/Hedging2.vue";
import PrivateKeyWallets from "@/views/PrivateKeyWallets.vue";
import WatchSwapLiquidityBootstrapPool from "@/views/extra/WatchSwapLiquidityBootstrapPool.vue";
import DelistTradeOrders from "@/views/DelistTradeOrders.vue";
import TestEIP6963 from "@/views/TestEIP6963.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: `/swap/${constants.CHAIN_ID_SOLANA}`, redirect: "/swap-solana" },
    { path: `/swap/${constants.CHAIN_ID_TRON}`, redirect: "/swap-tron" },
    { path: '/swap/:chainId', name: 'Swap', component: Swap },
    {
      path: '/swap',
      redirect: to => {
        if (["/", "/swap"].includes(to.path)) {
          try {
            const chainId = JSON.parse(localStorage.getItem("swap")).chainId;
            if (chainId == constants.CHAIN_ID_SOLANA) {
              return "/swap-solana";
            } else if (chainId === constants.CHAIN_ID_TRON) {
              return "/swap-tron";
            } else if ([
              constants.CHAIN_ID_ETH,
              constants.CHAIN_ID_BSC,
              constants.CHAIN_ID_POLYGON,
              constants.CHAIN_ID_ARBITRUM,
              constants.CHAIN_ID_OPTIMISM,
              constants.CHAIN_ID_BASE,
              constants.CHAIN_ID_FANTOM,
            ].includes(chainId)){
              return `/swap/${chainId}`;
            }
          } catch (e) {}
          return "/swap/1";
        }
        return to;
      }
    },
    { path: '/swap-solana', name: 'SwapSolana', component: SwapSolana },
    { path: '/swap-tron', name: 'SwapTron', component: SwapTron },
    { path: '/cex-wallet', name: 'CexWallet', component: CexWallet },
    { path: '/cex-accounts', name: 'CexAccounts', component: CexAccounts },
    { path: '/cex-balance-watch-orders', name: 'CexBalanceWatchOrders', component: CexBalanceWatchOrders },
    { path: '/delist-trade-orders', name: 'DelistTradeOrders', component: DelistTradeOrders },
    { path: '/arbitrage', name: 'Arbitrage', component: Arbitrage2 },
    { path: '/arbitrage2', name: 'Arbitrage2', component: Arbitrage2 },
    { path: '/test-vue-select', name: 'TestVueSelect', component: TestVueSelect },
    { path: '/arbitrage-focus', name: 'ArbitrageFocus', component: ArbitrageFocus },
    { path: '/arbitrage-focus-solana', name: 'ArbitrageFocusSolana', component: ArbitrageFocusSolana },
    { path: '/feed', name: 'Feed', component: Feed },
    { path: '/debug', name: 'Debug', component: Debug },
    { path: '/cex-hedging', name: 'CexHedging', component: CexHedging },
    { path: '/simple-hedging', redirect: "/cex-hedging" },
    { path: '/hedging2', name: 'Hedging2', component: Hedging2 },
    { path: '/private-key-wallets', name: 'PrivateKeyWallets', component: PrivateKeyWallets },
    { path: '/extra/watch-swap-lbp', name: 'WatchSwapLiquidityBootstrapPool', component: WatchSwapLiquidityBootstrapPool },
    { path: '/debug/eip6963', name: 'TestEIP6963', component: TestEIP6963 },
    { path: '/', redirect: "/swap" },
  ],
});

export default router
