import _ from "lodash";
import {ethers} from "ethers";
import * as web3Service from "@/services/web3Service";
import * as onboardExtra from "@/services/onboard";
import * as constants from "@/constants";
import { DateTime } from "luxon";

export function getCurrentAddress() {
  const address = localStorage.getItem("authAddress");
  try {
    const checksumAddress = ethers.getAddress(address);
    if (checksumAddress !== address) {
      localStorage.setItem("authAddress", checksumAddress);
    }
    return checksumAddress;

  } catch (e) {
    return undefined;
  }
}

export async function refreshAuth(_address?: string) {
  const address = _address ? ethers.getAddress(_address) : getCurrentAddress();

  const ts = Date.now();
  const message = constants.AUTH_HEADER_PREFIX + ":" + ts;
  const provider = await onboardExtra.waitForBrowserProvider();
  const signer = await provider.getSigner();
  const signerAddress = await signer.getAddress();

  if (signerAddress !== address) {
    throw new Error("Address mismatch");
  }
  const signature = await signer.signMessage(message);

  const auth = { ts, message, signature };
  localStorage.setItem("auth-" + address, JSON.stringify(auth));
  return auth;
}


export async function refreshAuthIfNecessary(_address?: string) {
  const address = _address ? ethers.getAddress(_address) : getCurrentAddress();

  try {
    const auth = JSON.parse(localStorage.getItem("auth-" + address));
    const { ts, message, signature } = auth;
    const tsDiffToNowHours = DateTime.now().diff(DateTime.fromMillis(ts)).as("hours");
    if (_.inRange(tsDiffToNowHours, -1, 144)) {
      const verifiedAddress = ethers.verifyMessage(message, signature);
      if (verifiedAddress === address) {
        return auth;
      }
    }

  } catch (e) {

  }

  return refreshAuth(address);
}

export async function getAuthHeaders() {
  const auth = await refreshAuthIfNecessary();

  return {
    "x-evm-auth-msg": auth.message,
    "x-evm-auth-signature": auth.signature,
  };
}
