<template>
  <div>
    <b-table :items="depositAddresses" :fields="tableFields" :busy="isLoading" show-empty hover>
      <template v-slot:table-busy>
        <div class="text-center py-4">Loading...</div>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <span class="text-primary cursor-pointer" @click="onClickViewDetails(item)">Raw data</span>
      </template>
    </b-table>
    <b-modal id="deposit-address-details-modal" title="Raw data" size="xl" hide-footer no-fade>
      <DepositAddressDetailsModal :address-details="viewDetailsItem" />
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";
  import * as cexAccountService from "@/services/cexAccountService";
  import type {BvTableFieldArray} from "bootstrap-vue";
  import DepositAddressDetailsModal from "@/components/DepositAddressDetailsModal.vue";
  import {DateTime} from "luxon";

  export default {
    name: "DepositCexAssetModal",
    components: {DepositAddressDetailsModal},
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "cexAccountId", "asset"],
    emits: ["done"],

    data() {
      return {
        isLoading: true,
        depositAddresses: [],
        viewDetailsItem: null,

        tableFields: [
          {
            key: "network",
            label: "Network",
            thClass: "text-nowrap",
            tdClass: "text-nowrap",
          },
          {
            key: "status",
            label: "Status",
            thClass: "text-nowrap",
            tdClass(value, key, item) {
              const classes = ["text-nowrap"];
              if (item.networkStatus) {
                classes.push(item.networkStatus.depositEnabled? "text-success" : "text-danger");
              }
              return classes;
            },
            formatter(value, key, item) {
              if (!item.networkStatus) return "";
              return item.networkStatus.depositEnabled? "OK" : "Suspended";
            }
          },
          {
            key: "address",
            label: "Address",
            thClass: "text-nowrap",
            tdClass: "text-monospace text-break",
          },
          {
            key: "memo",
            label: "Memo",
            thClass: "text-nowrap",
            tdClass: "text-monospace text-nowrap",
          },
          {
            key: "createdAt",
            label: "Created",
            thClass: "text-nowrap",
            tdClass: "text-nowrap",
            formatter: value => DateTime.fromISO(value).toFormat("yyyy-MM-dd HH:mm:ss")
          },
          {
            key: "actions",
            label: "",
            thClass: "text-nowrap",
            tdClass: "text-nowrap",
          },
        ] as BvTableFieldArray,
      };
    },

    mounted() {
      this.fetchDepositAddresses();
    },

    methods: {
      async fetchDepositAddresses() {
        try {
          this.isLoading = true;
          this.depositAddresses = await cexAccountService.getDepositAddresses(this.cexAccountId, this.asset);
        } catch (e) {
          console.error(e);
          this.toastError(e);
        } finally {
          this.isLoading = false;
        }
      },

      onClickViewDetails(item) {
        this.viewDetailsItem = item;
        this.$bvModal.show("deposit-address-details-modal");
      }
    }
  };
</script>
