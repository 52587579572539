<template>
  <div>
    <div class="text-secondary">Name</div>
    <div class="mb-2 text-monospace">{{ errorObj.name }}</div>
    <div class="text-secondary">Message</div>
    <div class="mb-2 text-monospace">{{ errorObj.message }}</div>
    <div class="text-secondary">Stack</div>
    <div class="text-monospace small text-break" style="white-space: pre-wrap">{{ errorObj.stack }}</div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import _ from "lodash";

  export default {
    name: "OrderDetailsModal",
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading"],
    props: ["modalId", "order"],

    computed: {
      errorObj() {
        return JSON.parse(this.order.error);
      }
    }
  };
</script>
