<template>
  <div v-if="currentAddress" class="bg-hover cursor-pointer p-2" @click="closeModal">
    <div class="text-secondary">
      <span class="mr-2">Current account</span>
      <img v-if="walletAddress === currentAddress"
           :src="connectedWallet.icon" :alt="connectedWallet.label" v-b-tooltip :title="connectedWallet.label"
           class="mr-1" style="width: 1.2rem; height: 1.2rem;" />
    </div>
    <div class="text-monospace">{{ currentAddress }}</div>
  </div>
  <div v-if="walletAddress && walletAddress !== currentAddress" class="bg-hover cursor-pointer p-2" @click="signInWithConnectedWallet">
    <div class="text-secondary">
      <span class="mr-2">Connected wallet</span>
      <img :src="connectedWallet.icon" :alt="connectedWallet.label" v-b-tooltip :title="connectedWallet.label"
           class="mr-1" style="width: 1.2rem; height: 1.2rem;" />
    </div>
    <div class="text-monospace">{{ walletAddress }}</div>
  </div>
  <div>
    <b-button variant="link" @click="onClickConnectWallet">Connect wallet</b-button>
  </div>
</template>


<script setup lang="ts">
import {
  ref,
  reactive,
  readonly,
  onActivated,
  onDeactivated,
  computed,
  watch,
  inject,
  getCurrentInstance
} from "vue";

  import { useOnboard } from "@web3-onboard/vue";
  import { walletAddress, chainId, browserProvider } from "@/services/onboard";

  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import {ethers} from "ethers";
  import * as authService from "@/services/authService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import {DateTime} from "luxon";

  const vm = getCurrentInstance().proxy;
  const { connectWallet, connectedWallet } = useOnboard();

  const props = defineProps(['modalId']);
  const currentAddress = ref(authService.getCurrentAddress());

  function onClickConnectWallet() {
    connectWallet();
  }

  function closeModal() {
    vm.$bvModal.hide(props.modalId);
  }

  async function signInWithConnectedWallet() {
    try {
      const address = walletAddress.value;
      await authService.refreshAuthIfNecessary(address);
      localStorage.setItem("authAddress", address);
      await utils.delay();
      location.reload();

    } catch (e) {
      console.error("signInWithConnectedWallet", e);
    }
  }

</script>
