<template>
  <main role="main">
    <ArbitrageItem
      v-if="arbitrageItem"
      :coin="arbitrageItem"
      :arbitrage-settings="arbitrageSettings"
      :is-focus-mode="true"
      @onClickWithdraw="onClickWithdraw"
      @onClickDeposit="onClickDeposit"
    ></ArbitrageItem>

    <b-container v-if="arbitrageItem" class="py-5">
      <b-row>
        <section class="px-3 col-lg-6 mb-5">
          <div class="border rounded mb-3 p-2">
            <div>
              <div class="label-row">
                <div>You sell</div>
                <div class="cursor-pointer text-secondary" @click="onClickAllIn">
                  <small>Balance: {{ swapInput.walletDisplayBalance }}</small>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div @click="onClickInputAsset" class="asset-btn">
                  <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
                    <img v-if="swapInput.logo" :src="swapInput.logo" class="w-100 h-100">
                  </div>
                  <div class="overflow-hidden text-overflow-ellipsis">{{ swapInput.symbol }}</div>
                </div>
                <b-input type="text" v-model.trim="swapInput.displayAmount" @input="inputAmountChangedDebounced"
                         class="border-0 text-right flex-grow-1" size="lg"
                         autocomplete="off" inputmode="decimal"></b-input>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2">{{ swapInput.name || swapInput.symbol }}</span>
                  <template v-if="!swapInput.isNative">
                    <span class="cursor-pointer mr-2" @click="copyTokenAddress(swapInput.contract)">
                      <b-icon-files />
                    </span>
                    <b-icon-info-circle id="icon-info-input"></b-icon-info-circle>
                    <b-tooltip target="icon-info-input">
                      <a class="small text-light" :href="getExplorerUrl(chainId, 'token', swapInput.contract)" target="_blank">
                        {{ swapInput.contract }}
                      </a>
                    </b-tooltip>
                  </template>
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                    <span class="mr-2">
                      1 {{ swapInput.symbol }} = {{ swapInput.displayPriceOther }} {{ swapOutput.symbol }}
                    </span>
                </div>
              </div>
            </div>
            <div class="text-center">
                <span class="text-primary cursor-pointer" @click="reverseInputOutput" title="Reverse">
                  <b-icon-arrow-down-up/>
                </span>
            </div>
            <div>
              <div class="label-row">
                <div>You buy</div>
                <div class="text-secondary"><small>Balance: {{ swapOutput.walletDisplayBalance }}</small></div>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div @click="onClickOutputAsset" class="asset-btn">
                  <div class="flex-shrink-0 mr-2" style="width: 30px; height: 30px;">
                    <img v-if="swapOutput.logo" :src="swapOutput.logo" class="w-100 h-100">
                  </div>
                  <div class="overflow-hidden text-overflow-ellipsis">{{ swapOutput.symbol }}</div>
                </div>
                <div class="flex-grow-1 px-3 py-2 text-right overflow-hidden" style="font-size: 20px;"
                     :class="{'text-secondary': isRequestingQuote}">
                  {{ swapOutput.displayAmount }}
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                  <span class="mr-2">{{ swapOutput.name || swapOutput.symbol }}</span>
                  <template v-if="!swapOutput.isNative">
                    <span class="cursor-pointer mr-2" @click="copyTokenAddress(swapOutput.contract)">
                      <b-icon-files />
                    </span>
                    <b-icon-info-circle id="icon-info-output"></b-icon-info-circle>
                    <b-tooltip target="icon-info-output">
                      <a class="small text-light" :href="getExplorerUrl(chainId, 'token', swapOutput.contract)" target="_blank">
                        {{ swapOutput.contract }}
                      </a>
                    </b-tooltip>
                  </template>
                </div>
              </div>
              <div class="label-row small text-secondary">
                <div>
                    <span class="mr-2">
                      1 {{ swapOutput.symbol }} = {{ swapOutput.displayPriceOther }} {{ swapInput.symbol }}
                    </span>
                </div>
                <div v-b-tooltip :title="displayGasCostTooltip">
                  <svg viewBox="0 0 512 512" width="14" height="14">
                    <path fill="#6c757d" d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z"/>
                  </svg>
                  <span class="ml-2">{{ displayGasCostNative }} · ${{ displayGasCostUsd }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="quoteErrorMsg" class="mb-3 text-danger border border-danger d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              Error requesting quote: {{ quoteErrorMsg }}
            </div>
            <div class="cursor-pointer p-1" @click="quoteErrorMsg = ''"><b-icon-x-circle/></div>
          </div>
          <div class="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2">
            <label class="m-0" for="inputSendToAddress">Send to address</label>
            <div class="text-primary">
              <span class="cursor-pointer mr-4" v-b-modal:address-book-modal><b-icon-bookmark/> Address book</span>
              <span class="cursor-pointer" v-b-modal:save-wallet-modal><b-icon-plus-lg/> Save new</span>
            </div>
          </div>
          <v-select class="vs-normalizer mb-3" :hidden="!!destReceiverLabel"
                    label="label"
                    :getOptionKey="s => s.address + ':' + s.memo"
                    :filter="destReceiverSelectDropdownFilter"
                    :options="savedWallets"
                    :clearSearchOnBlur="() => false"
                    v-on:option:selecting="onPickSavedWallet">
            <template v-slot:search="{attributes, events}">
              <input v-bind="attributes" v-on="events"
                     :value="destReceiver"
                     @input="destReceiver = $event.target.value"
                     @blur="destReceiver = destReceiver?.trim() || ''"
                     class="form-control text-monospace" required minlength="2" maxlength="100"/>
            </template>
            <template v-slot:option="{ label, address }">
              <div class="py-1">
                <div>{{ label }}</div>
                <div class="text-secondary text-monospace">
                  <span class="d-inline d-md-none">{{ getShortenedAddress(address) }}</span>
                  <span class="d-none d-md-inline">{{ address }}</span>
                </div>
              </div>
            </template>
          </v-select>
          <div v-if="destReceiverLabel" class="border rounded d-flex flex-row align-items-center mb-3 p-2">
            <div class="flex-grow-1 min-width-0">
              <div class="overflow-hidden text-overflow-ellipsis">{{ destReceiverLabel }}</div>
              <div class="text-monospace">
                <a class="text-break" :href="destReceiverExplorerUrl" target="_blank">{{ destReceiver }}</a>
              </div>
            </div>
            <div class="flex-shrink-0">
              <b-button variant="link" class="text-secondary" @click="clearDestReceiver"><b-icon-x-circle/></b-button>
            </div>
          </div>
          <div class="mb-4 text-right">
            <b-button variant="link mr-3" @click="refreshQuote" :disabled="isRequestingQuote">Refresh quote</b-button>
            <b-button variant="primary" style="width: 160px;" @click="doSwap" :disabled="isSwapping">{{ swapBtnText }}</b-button>
          </div>
          <div class="border rounded mb-3 p-2" v-if="reviewInfo">
            <h6 class="mb-3 pb-2 border-bottom">Review</h6>
            <table class="table table-borderless">
              <tbody>
              <tr>
                <td>Price: {{ reviewInfo.fromTokenSymbol }} / {{ reviewInfo.toTokenSymbol }}</td>
                <td class="text-right">{{ reviewInfo.displayPrice }}</td>
              </tr>
              <tr>
                <td>Price: {{ reviewInfo.toTokenSymbol }} / {{ reviewInfo.fromTokenSymbol }}</td>
                <td class="text-right">{{ reviewInfo.displayInversePrice }}</td>
              </tr>
              <tr>
                <td>You spend</td>
                <td class="text-right">{{ reviewInfo.displayInputAmount }} {{ reviewInfo.fromTokenSymbol }}</td>
              </tr>
              <tr>
                <td>Estimated received</td>
                <td class="text-right">{{ reviewInfo.displayOutputAmount }} {{ reviewInfo.toTokenSymbol }}</td>
              </tr>
              <tr>
                <td>Minimum received</td>
                <td class="text-right">{{ reviewInfo.displayMinimumReceiveAmount }} {{ reviewInfo.toTokenSymbol }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="swapErrorMsg" class="mb-3 text-danger border border-danger d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              <div class="text-break small" style="max-height: 100px; overflow-y: scroll;">
                Error requesting swap: {{ swapErrorMsg }}
              </div>
              <div v-if="showApproveInfinite">
                <b-btn variant="primary" size="sm" :disabled="isApproving" @click="onClickApprove">
                  Approve {{ swapInput.symbol }}
                </b-btn>
                <b-btn variant="secondary" size="sm" v-if="showSetApproveToZero" :disabled="isApproving" class="ml-3" @click="onClickRevoke">
                  Revoke {{ swapInput.symbol }}
                </b-btn>
              </div>
            </div>
            <div class="cursor-pointer p-1" @click="swapErrorMsg = ''"><b-icon-x-circle/></div>
          </div>
          <div v-if="sentTxExplorerUrl" class="mb-3 text-info border border-info d-flex flex-row align-items-center px-1 py-1">
            <div class="flex-grow-1 text-break">
              Transaction {{ sentTxNonce }} sent. <a :href="sentTxExplorerUrl" target="_blank">View on explorer</a>
            </div>
            <div class="cursor-pointer p-1" @click="sentTxExplorerUrl = ''"><b-icon-x-circle/></div>
          </div>
        </section>
        <section class="px-3 col-lg-6 mb-5">
          <div class="border rounded mb-3 px-3 py-2">
            <div class="d-flex flex-row align-items-center justify-content-between">
              <div>
                <span v-if="!showBotOrderForm" class="text-primary cursor-pointer" @click="showBotOrderForm = true">Create bot order</span>
                <span v-if="showBotOrderForm" class="text-primary cursor-pointer" @click="showBotOrderForm = false">Hide form</span>
              </div>
              <div v-if="showBotOrderForm">
                <router-link to="/cex-balance-watch-orders">Manage orders</router-link>
              </div>
            </div>
            <div v-if="showBotOrderForm" class="border-top mt-2 pt-2">
              <NewCexBalanceWatchOrderModal
                ref="cex-balance-watch-form"
                :p_evm-address="destReceiver"
                :p_base-asset="swapOutput && swapOutput.symbol"
                :p_quote-asset="swapInput && swapInput.symbol"
              />
            </div>
          </div>
          <div class="border rounded mb-3 px-3 py-2">
            <h6 class="mb-3 pb-2 border-bottom">Swap options</h6>
            <div class="d-flex flex-row align-items-center justify-content-between mb-2">
              <label class="m-0">Aggregator</label>
              <b-form-radio-group v-model="aggregator" :options="aggregatorOptions"></b-form-radio-group>
            </div>
            <div class="mb-2">
              <b-form-checkbox v-model="manualGasPrice" switch>Set gas price</b-form-checkbox>
            </div>
            <div v-if="manualGasPrice" class="mb-2">
              <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                <div>Gas price</div>
                <div>
                  <small><span class="text-secondary">Current estimate:</span> {{ currentGasPriceGwei }} gwei</small>
                </div>
              </div>
              <div class="d-flex flex-row flex-wrap align-items-center">
                <div v-for="multiplier in gasPriceMultiplierOptions" :key="multiplier"
                     class="flex-shrink-0 small text-center mr-2 mb-2 px-2 py-1 cursor-pointer border rounded-pill"
                     :class="{ 'bg-primary': multiplier === gasPriceMultiplier, 'text-white': multiplier === gasPriceMultiplier }"
                     style="width: 40px;" @click="onClickGasPriceMultiplier(multiplier)">
                  {{ multiplier }}x
                </div>
                <b-input-group class="flex-grow-1 mb-2" style="min-width: 120px; flex-basis: 120px;">
                  <b-form-input type="number" v-model.number="gasPriceGwei" class="text-right"
                                @blur="onInputGasPriceBlur" @input="gasPriceMultiplier = null"
                                min="0" :step="gasPriceStep" max="100000"></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>gwei</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <div class="mb-3">
              <div><label>Slippage tolerance</label></div>
              <div class="d-flex flex-row flex-wrap align-items-center">
                <div v-for="slippageOption in slippageToleranceOptions" :key="slippageOption"
                     class="flex-shrink-0 small text-center mr-2 mb-2 px-2 py-1 cursor-pointer border rounded-pill"
                     :class="{ 'bg-primary': slippageOption === slippageTolerance, 'text-white': slippageOption === slippageTolerance }"
                     style="width: 64px;" @click="slippageTolerance = slippageOption">
                  {{ slippageOption }}%
                </div>
                <b-input-group class="flex-grow-1 mb-2" style="min-width: 120px; flex-basis: 120px;">
                  <b-form-input type="number" v-model.number="slippageTolerance" class="text-right" @blur="onInputSlippageBlur"
                                min="0" step="0.001" max="50"></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>%</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-if="slippageTolerance >= 10" class="text-danger text-right mt-2 small">Warning: high slippage</div>
            </div>
            <div v-if="aggregator === '1inch'">
              <b-form-checkbox v-model="allowPartialFill" switch>Allow partial fill</b-form-checkbox>
            </div>
          </div>
        </section>
      </b-row>
    </b-container>

    <b-modal id="pick-swap-token-modal" title="Select a token" hide-footer no-fade no-close-on-backdrop>
      <PickTokenModal :chainId="chainId" :walletAddress="walletAddress" @pickToken="onPickToken"></PickTokenModal>
    </b-modal>
    <b-modal id="address-book-modal" title="Address book" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <AddressBookModal type="evm" @pickWallet="onPickSavedWallet"></AddressBookModal>
    </b-modal>
    <b-modal id="save-wallet-modal" title="Save new wallet" hide-footer no-fade no-close-on-backdrop @hidden="reloadSavedWallets">
      <SaveWalletModal modal-id="save-wallet-modal" :prefillAddress="destReceiver" type="evm" @done="onPickSavedWallet"></SaveWalletModal>
    </b-modal>

    <template v-if="dwItem">
      <b-modal :id="cexWithdrawModalId" :title="'Withdraw ' + dwItem.asset.toUpperCase()" hide-footer no-fade no-close-on-backdrop>
        <ArbitrageWithdrawCexAssetModal
          :modalId="cexWithdrawModalId"
          :exchange="dwItem.exchange"
          :asset="dwItem.asset"
        ></ArbitrageWithdrawCexAssetModal>
      </b-modal>
      <b-modal :id="cexDepositModalId" :title="'Deposit ' + dwItem.asset.toUpperCase()" hide-footer no-fade no-close-on-backdrop>
        <ArbitrageDepositCexAssetModal
          :modalId="cexDepositModalId"
          :exchange="dwItem.exchange"
          :asset="dwItem.asset"
        ></ArbitrageDepositCexAssetModal>
      </b-modal>
    </template>
  </main>
</template>

<style lang="scss" scoped>
  .label-row {
    height: 1.375rem;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .asset-btn {
    flex-shrink: 0;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 16px;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  table {
    tr, td {
      padding: 8px 16px;
    }
  }
</style>

<script lang="ts">
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import * as arbitrageService from "@/services/arbitrageService";
  import * as web3Service from "@/services/web3Service";
  import {ethers} from "ethers";
  import type {TransactionRequest} from "ethers";
  import * as oneInchService from "@/services/oneInchService";
  import * as paraswapService from "@/services/paraswapService";
  import * as kyberSwapService from "@/services/kyberSwapService";
  import * as savedWalletsService from "@/services/savedWalletsService";
  import * as customTokensService from "@/services/customTokensService";
  import * as marketDataService from "@/services/marketDataService";
  import * as onboardComposableExtra from "@/services/onboard";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import erc20Abi from "@/services/abi/erc20Abi.json";

  import ArbitrageItem from "@/components/arbitrage/ArbitrageItem.vue";
  import PickTokenModal from "@/components/PickTokenModal.vue";
  import AddressBookModal from "@/components/AddressBookModal.vue";
  import SaveWalletModal from "@/components/SaveWalletModal.vue";
  import NewCexBalanceWatchOrderModal from "@/components/NewCexBalanceWatchOrderModal.vue";
  import ArbitrageDepositCexAssetModal from "@/components/arbitrage/ArbitrageDepositCexAssetModal.vue";
  import ArbitrageWithdrawCexAssetModal from "@/components/arbitrage/ArbitrageWithdrawCexAssetModal.vue";

  const gasNumberFormatUsd = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  export default {
    name: "ArbitrageFocus",
    components: {
      ArbitrageWithdrawCexAssetModal,
      ArbitrageDepositCexAssetModal,
      NewCexBalanceWatchOrderModal, SaveWalletModal, AddressBookModal, PickTokenModal, ArbitrageItem
    },
    inject: ["toastError", "toastSuccess", "toastSuccessDelay", "showLoading", "hideLoading", "getExplorerUrl", "getShortenedAddress"],

    data() {
      return {
        coingeckoId: "",
        chainId: 1,

        walletAddress: "",

        name: "",
        symbol: "",

        // params for the /arbitrage/getAssets API
        arbitrageSettings: {
          chainIds: [1],
          exchanges: ["binance", "okx"],
          priceType: "index",
          showLongFuturesSellSpot: false,
        },
        arbitrageItem: null,

        isLoading: false,
        reloadTimeoutHandler: null,

        swapInput: {
          name: "",
          contract: "",
          isNative: false,
          decimals: 0,
          symbol: "",
          logo: "",
          walletBalance: "0",
          walletDisplayBalance: "0",
          displayAmount: "",
          displayPriceUsd: "--",
          displayPriceOther: "--",
        },

        swapOutput: {
          name: "",
          contract: "",
          isNative: false,
          decimals: 0,
          symbol: "",
          logo: "",
          walletBalance: "0",
          walletDisplayBalance: "0",
          displayAmount: "",
          displayPriceUsd: "--",
          displayPriceOther: "--",
        },

        nativePrice: 0,

        destReceiverLabel: "",
        destReceiver: "",
        savedWallets: [],

        pickingTokenMode: "input" as "input" | "output",

        reviewInfo: null as {
          fromTokenSymbol: string,
          toTokenSymbol: string,
          displayInputAmount: string,
          displayOutputAmount: string,
          displayMinimumReceiveAmount: string,
          displayPrice: string,
          displayInversePrice: string,
        },

        isRequestingQuote: false,
        quoteErrorMsg: "",

        isSwapping: false,
        swapErrorMsg: "",

        showApproveInfinite: false,
        showSetApproveToZero: false,
        isApproving: false,

        currentGasPriceGwei: 0,
        gasPriceGwei: 0,
        estimateGas: 0,

        gasPriceMultiplierOptions: [1, 2, 4, 8, 10, 15],
        gasPriceMultiplier: 1,

        manualGasPrice: true,

        slippageTolerance: 1,
        slippageToleranceOptions: [0.1, 0.5, 1, 3],

        aggregatorOptions: [
          { value: "1inch", text: "1inch", disabled: false },
          { value: "paraswap", text: "ParaSwap", disabled: false },
          { value: "kyberSwap", text: "KyberSwap", disabled: false },
        ],
        aggregator: "1inch",
        paraswapPriceRoute: undefined,
        kyberSwapRoute: undefined,

        allowPartialFill: false,

        sentTxNonce: 0,
        sentTxExplorerUrl: "",

        showBotOrderForm: false,

        refreshSwapTokensAndBalancesTimeoutHandler: null,
        refreshQuoteTimeoutHandler: null,
        refreshWalletTokenBalancesTimeoutHandler: null,

        dwItem: null,
        cexWithdrawModalId: "cex-withdraw-modal",
        cexDepositModalId: "cex-deposit-modal",

        isDestroyed: false,
      };
    },

    computed: {
      displayGasCostNative() {
        const gasPrice = this.manualGasPrice ? this.gasPriceGwei : this.currentGasPriceGwei;
        return BigNumber(this.estimateGas)
          .multipliedBy(gasPrice)
          .shiftedBy(-9)
          .precision(4)
          .toFixed();
      },
      displayGasCostUsd() {
        const gasPrice = this.manualGasPrice ? this.gasPriceGwei : this.currentGasPriceGwei;
        return BigNumber(this.estimateGas)
          .multipliedBy(gasPrice)
          .shiftedBy(-9)
          .multipliedBy(this.nativePrice)
          .precision(4)
          .toFixed();
      },
      displayGasCostTooltip() {
        const gasPrice = this.manualGasPrice ? this.gasPriceGwei : this.currentGasPriceGwei;
        return gasNumberFormatUsd.format(this.estimateGas) + ' @ ' + gasPrice + ' gwei';
      },
      swapBtnText() {
        if (onboardComposableExtra.chainId.value && onboardComposableExtra.chainId.value !== this.chainId) {
          return "Switch to " + constants.chainNames[this.chainId];
        }
        return "Swap";
      },
      gasPriceStep() { return constants.gasStep[this.chainId] || 1; },
      destReceiverExplorerUrl() {
        return utils.getExplorerUrl(this.chainId, "address", this.destReceiver);
      },
    },

    created() {
      this.chainId = +this.$route.query.chainId;
      this.inputAmountChangedDebounced = _.debounce(this.inputAmountChanged, 300);
    },
    async mounted() {
      const { coingeckoId, side } = this.$route.query as Record<string, string>;
      this.coingeckoId = coingeckoId;

      document.title = coingeckoId + " | Arbitrage Focus";

      try {
        const savedSettingsStr = localStorage.getItem("arbitrage2");
        if (savedSettingsStr) {
          this.arbitrageSettings = JSON.parse(savedSettingsStr);
        }
      } catch (e) {}

      try {
        const savedObj = JSON.parse(localStorage.getItem(`swap-${this.chainId}`));
        Object.assign(this, _.pick(savedObj, [
          "aggregator", "gasPriceMultiplier", "slippageTolerance", "allowPartialFill", "manualGasPrice"
        ]));
        // Object.assign(this.swapInput, _.pick(savedObj.swapInput, ["name", "contract", "symbol", "decimals", "displayAmount"]));
        // Object.assign(this.swapOutput, _.pick(savedObj.swapOutput, ["name", "contract", "symbol", "decimals"]));
        // Object.assign(this.swapInput, _.pick(savedObj.swapInput, ["contract", "displayAmount"]));
        // Object.assign(this.swapOutput, _.pick(savedObj.swapOutput, ["contract"]));

      } catch (e) {
        console.error(e);
      }

      this.reloadSavedWallets();

      try {
        const getAssetsRet = await arbitrageService.getAssets({
          coingeckoIds: [coingeckoId],
          chainIds: this.arbitrageSettings.chainIds,
          exchanges: this.arbitrageSettings.exchanges,
          priceType: this.arbitrageSettings.priceType,
          showLongFuturesSellSpot: this.arbitrageSettings.showLongFuturesSellSpot
        });
        const arbitrageItem = getAssetsRet.data[0];
        const dexItem = arbitrageItem.dex.find(it => it.chainId === this.chainId);

        if (side === "buy") {
          this.swapInput.displayAmount = "5000";
          this.swapInput.contract = constants.USDT_TOKEN[this.chainId].address;
          this.swapOutput.contract = dexItem.address;
          this.swapOutput.logo = arbitrageItem.logoUrl;
        } else {
          this.swapOutput.contract = constants.USDT_TOKEN[this.chainId].address;
          this.swapInput.contract = dexItem.address;
          this.swapInput.logo = arbitrageItem.logoUrl;
        }

        const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
        this.destReceiver = destReceiverObj?.destReceiver || "";
        this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";

        // if ([1, 56, 137].includes(this.chainId)) {
        this.aggregator = this.aggregator || "1inch";
        // } else {
        //   this.aggregator = "1inch";
        //   this.aggregatorOptions.find(it => it.value === "paraswap").disabled = true;
        // }

        this.arbitrageItem = arbitrageItem;

        this.fetchArbitrageCoinLoop();

        this.refreshWalletTokenBalances();
        await this.refreshSwapTokensAndBalances();
        this.refreshQuote();

      } catch (e) {
        console.error(e);
      }
    },

    methods: {
      async fetchArbitrageCoinLoop() {
        clearTimeout(this.reloadTimeoutHandler);
        if (this.isDestroyed) return;

        try {
          const getAssetsRet = await arbitrageService.getAssets({
            coingeckoIds: [this.coingeckoId],
            chainIds: this.arbitrageSettings.chainIds,
            exchanges: this.arbitrageSettings.exchanges,
            priceType: this.arbitrageSettings.priceType,
            showLongFuturesSellSpot: this.arbitrageSettings.showLongFuturesSellSpot
          });
          const arbitrageItem = getAssetsRet.data[0];

          let maxLiq = 0;
          let refPrice = 0;
          for (const dexItem of arbitrageItem.dex) {
            if (dexItem.liquidity > maxLiq) {
              maxLiq = dexItem.liquidity;
              refPrice = dexItem.price;
            }
          }
          const displayRefPrice = BigNumber(refPrice).precision(6).toFixed();
          const displayArbitragePercent = (arbitrageItem.highestArbitragePercent || 0).toFixed(2);

          this.arbitrageItem = arbitrageItem;
          document.title = `${this.arbitrageItem.symbol.toUpperCase()} ${displayArbitragePercent}% ${displayRefPrice} | Arbitrage Focus`;

        } catch (e) {
          console.error(e);
        }

        clearTimeout(this.reloadTimeoutHandler);
        this.reloadTimeoutHandler = setTimeout(() => this.fetchArbitrageCoinLoop(), 5000);
      },

      async onClickWithdraw(item) {
        this.dwItem = item;
        await utils.delay(0);
        this.$bvModal.show(this.cexWithdrawModalId);
        console.log("ArbitrageFocus onClickWithdraw", item);
      },

      async onClickDeposit(item) {
        this.dwItem = item;
        await utils.delay(0);
        this.$bvModal.show(this.cexDepositModalId);
        console.log("ArbitrageFocus onClickDeposit", item);
      },

      reloadSavedWallets() {
        this.savedWallets = savedWalletsService.getAllSavedWallets2("evm");
      },

      async refreshSwapTokensAndBalances() {
        clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
        if (this.isDestroyed) return;

        try {
          const oneInchSupportedTokensObj = await web3Service.getListedTokens(this.chainId);

          // input token metadata
          const inputTokenDetailsFrom1inch = oneInchSupportedTokensObj[this.swapInput.contract.toLowerCase()];
          if (inputTokenDetailsFrom1inch) {
            this.swapInput.name = inputTokenDetailsFrom1inch.name;
            this.swapInput.decimals = inputTokenDetailsFrom1inch.decimals;
            this.swapInput.symbol = inputTokenDetailsFrom1inch.symbol;
            this.swapInput.logo = inputTokenDetailsFrom1inch.logoURI;
          } else if (!this.swapInput.name || !utils.isTokenDecimalsValid(this.swapInput.decimals) || !this.swapInput.symbol) {
            const inputTokenDetailsFromProvider = await web3Service.getEvmTokenMetadata(this.chainId, this.swapInput.contract);
            this.swapInput.name = inputTokenDetailsFromProvider.name;
            this.swapInput.decimals = inputTokenDetailsFromProvider.decimals;
            this.swapInput.symbol = inputTokenDetailsFromProvider.symbol;
          }

          // output token metadata
          const outputTokenDetailsFrom1inch = oneInchSupportedTokensObj[this.swapOutput.contract.toLowerCase()];
          if (outputTokenDetailsFrom1inch) {
            this.swapOutput.name = outputTokenDetailsFrom1inch.name;
            this.swapOutput.decimals = outputTokenDetailsFrom1inch.decimals;
            this.swapOutput.symbol = outputTokenDetailsFrom1inch.symbol;
            this.swapOutput.logo = outputTokenDetailsFrom1inch.logoURI;
          } else if (!this.swapOutput.name || !utils.isTokenDecimalsValid(this.swapOutput.decimals) || !this.swapOutput.symbol) {
            const outputTokenDetailsFromProvider = await web3Service.getEvmTokenMetadata(this.chainId, this.swapOutput.contract);
            this.swapOutput.name = outputTokenDetailsFromProvider.name;
            this.swapOutput.decimals = outputTokenDetailsFromProvider.decimals;
            this.swapOutput.symbol = outputTokenDetailsFromProvider.symbol;
          }

          // input and output token balances
          await onboardComposableExtra.waitForBrowserProvider();
          this.walletAddress = onboardComposableExtra.walletAddress.value;
          const balances = await web3Service.getEvmTokenBalances(this.chainId, this.walletAddress, [this.swapInput.contract, this.swapOutput.contract]);

          this.swapInput.walletBalance = (balances[this.swapInput.contract.toLowerCase()] || 0).toString();
          this.swapInput.walletDisplayBalance = BigNumber(this.swapInput.walletBalance)
            .shiftedBy(-this.swapInput.decimals)
            .decimalPlaces(8, BigNumber.ROUND_DOWN)
            .toFixed();

          this.swapOutput.walletBalance = (balances[this.swapOutput.contract.toLowerCase()] || 0).toString();
          this.swapOutput.walletDisplayBalance = BigNumber(this.swapOutput.walletBalance)
            .shiftedBy(-this.swapOutput.decimals)
            .decimalPlaces(8, BigNumber.ROUND_DOWN)
            .toFixed();

          // gas
          const gasPriceWei = await web3Service.getEvmGasPrice(this.chainId);
          const currentGasPriceGweiBN = BigNumber(ethers.formatUnits(gasPriceWei, "gwei"))
            .dividedBy(this.gasPriceStep)
            .decimalPlaces(0, BigNumber.ROUND_UP)
            .multipliedBy(this.gasPriceStep);
          this.currentGasPriceGwei = currentGasPriceGweiBN.toNumber();
          if (this.gasPriceMultiplier) {
            this.gasPriceGwei = currentGasPriceGweiBN.multipliedBy(this.gasPriceMultiplier).toNumber();
          } else if (!this.gasPriceGwei) {
            this.gasPriceGwei = this.currentGasPriceGwei;
          }
          this.nativePrice = (await marketDataService.getNativePrices())[this.chainId];

        } catch (e) {
          console.error(e);
        }

        clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
        this.refreshSwapTokensAndBalancesTimeoutHandler = setTimeout(() => this.refreshSwapTokensAndBalances(), 3500);
      },

      async refreshWalletTokenBalances() {
        clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
        if (this.isDestroyed) return;

        try {
          await onboardComposableExtra.waitForBrowserProvider();
          this.walletAddress = onboardComposableExtra.walletAddress.value;
          await Promise.allSettled([
            web3Service.getEvmWalletListedAndCustomTokenBalances(this.chainId, this.walletAddress),
            web3Service.getListedTokens(this.chainId)
          ]);
        } catch (e) {

        }

        clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
        this.refreshWalletTokenBalancesTimeoutHandler = setTimeout(() => this.refreshWalletTokenBalances(), 10000);
      },


      onClickInputAsset() {
        this.pickingTokenMode = "input";
        this.$bvModal.show("pick-swap-token-modal");
      },

      onClickOutputAsset() {
        this.pickingTokenMode = "output";
        this.$bvModal.show("pick-swap-token-modal");
      },

      onPickToken(tokenObj: any) {
        if (this.pickingTokenMode === "input") {
          if (tokenObj.address.toLowerCase() === this.swapOutput.contract.toLowerCase()) {
            this.reverseInputOutput();
          } else {
            this.swapInput.name = tokenObj.name;
            this.swapInput.contract = tokenObj.address;
            this.swapInput.isNative = tokenObj.isNative;
            this.swapInput.decimals = tokenObj.decimals;
            this.swapInput.symbol = tokenObj.symbol;
            this.swapInput.logo = tokenObj.logo;
            this.swapInput.walletBalance = tokenObj.balance;
            this.swapInput.walletDisplayBalance = tokenObj.displayBalance;
            this.showApproveInfinite = false;
          }

        } else if (this.pickingTokenMode === "output") {
          if (tokenObj.address.toLowerCase() === this.swapInput.contract.toLowerCase()) {
            this.reverseInputOutput();
          } else {
            this.swapOutput.name = tokenObj.name;
            this.swapOutput.contract = tokenObj.address;
            this.swapOutput.isNative = tokenObj.isNative;
            this.swapOutput.decimals = tokenObj.decimals;
            this.swapOutput.symbol = tokenObj.symbol;
            this.swapOutput.logo = tokenObj.logo;
            this.swapOutput.walletBalance = tokenObj.balance;
            this.swapOutput.walletDisplayBalance = tokenObj.displayBalance;

            const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
            this.destReceiver = destReceiverObj?.destReceiver || "";
            this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";
          }
        }

        this.swapOutput.displayAmount = "";
        this.swapInput.displayPriceUsd = "--";
        this.swapInput.displayPriceOther = "--";
        this.swapOutput.displayPriceUsd = "--";
        this.swapOutput.displayPriceOther = "--";

        this.paraswapPriceRoute = undefined;
        this.kyberSwapRoute = undefined;

        this.$bvModal.hide("pick-swap-token-modal");
        this.refreshQuote();
        this.refreshSwapTokensAndBalances();
      },

      onPickSavedWallet(savedWallet: savedWalletsService.SavedWallet) {
        this.destReceiver = savedWallet.address;
        this.destReceiverLabel = savedWallet.label;
        this.$bvModal.hide("address-book-modal");
      },

      clearDestReceiver() {
        this.destReceiverLabel = "";
        this.destReceiver = "";
      },

      destReceiverSelectDropdownFilter(options: any[], search: string) {
        search = utils.sanitizeSearchText(search).toLowerCase();
        return options.filter(savedWallet =>
          savedWallet.label.toLowerCase().includes(search) ||
          savedWallet.address.toLowerCase().includes(search)
        );
      },

      onClickAllIn() {
        this.swapInput.displayAmount = BigNumber(this.swapInput.walletBalance)
          .shiftedBy(-this.swapInput.decimals)
          .decimalPlaces(8, BigNumber.ROUND_DOWN)
          .toFixed();
        this.refreshQuote();
      },

      async copyTokenAddress(address: string) {
        await navigator.clipboard.writeText(address);
        this.toastSuccess("Address copied", address);
      },

      inputAmountChanged() {
        this.refreshQuote();
      },

      reverseInputOutput() {
        const temp = this.swapInput;
        this.swapInput = this.swapOutput;
        this.swapOutput = temp;

        this.swapOutput.displayAmount = "";
        this.swapInput.displayPriceUsd = "--";
        this.swapInput.displayPriceOther = "--";
        this.swapOutput.displayPriceUsd = "--";
        this.swapOutput.displayPriceOther = "--";

        this.paraswapPriceRoute = undefined;
        this.kyberSwapRoute = undefined;

        const destReceiverObj = customTokensService.getSwapDestReceiverByToken(this.chainId, this.swapOutput.contract);
        this.destReceiver = destReceiverObj?.destReceiver || "";
        this.destReceiverLabel = destReceiverObj?.destReceiverLabel || "";

        this.showApproveInfinite = false;
        this.refreshQuote();
      },

      async onClickGasPriceMultiplier(multiplier: number) {
        if (multiplier === this.gasPriceMultiplier) {
          this.gasPriceMultiplier = null;
        } else {
          this.gasPriceMultiplier = multiplier;
        }

        const gasPriceWei = await web3Service.getEvmGasPrice(this.chainId);
        const currentGasPriceGweiBN = BigNumber(ethers.formatUnits(gasPriceWei, "gwei"))
          .dividedBy(this.gasPriceStep)
          .decimalPlaces(0, BigNumber.ROUND_UP)
          .multipliedBy(this.gasPriceStep);

        this.currentGasPriceGwei = currentGasPriceGweiBN.toNumber();
        this.gasPriceGwei = currentGasPriceGweiBN.multipliedBy(multiplier).toNumber();
      },

      onInputGasPriceBlur() {
        if (!this.gasPriceGwei || this.gasPriceGwei < 0) {
          this.gasPriceGwei = this.currentGasPriceGwei;
          this.gasPriceMultiplier = 1;
        }
      },

      onInputSlippageBlur() {
        if (!this.slippageTolerance) {
          this.slippageTolerance = 1;
        } else if (this.slippageTolerance > 40) {
          this.slippageTolerance = 40;
        } else if (this.slippageTolerance < 0.001) {
          this.slippageTolerance = 0.001;
        }
      },

      async refreshQuote() {
        clearTimeout(this.refreshQuoteTimeoutHandler);
        if (this.isDestroyed) return;

        const displayInputAmount = this.swapInput.displayAmount;
        const fromRealTokenAmountBN = BigNumber(displayInputAmount);
        if (!fromRealTokenAmountBN.gt(0)) {
          this.swapOutput.displayAmount = "";
          this.swapInput.displayPriceUsd = "--";
          this.swapInput.displayPriceOther = "--";
          this.swapOutput.displayPriceUsd = "--";
          this.swapOutput.displayPriceOther = "--";
          this.paraswapPriceRoute = undefined;
          this.kyberSwapRoute = undefined;
          return;
        }

        try {
          this.isRequestingQuote = true;
          this.quoteErrorMsg = "";

          let inputPriceBN: BigNumber, outputPriceBN: BigNumber;

          if (this.aggregator === "1inch") {
            const getQuoteParams: Record<string, any> = {
              src: this.swapInput.contract,
              dst: this.swapOutput.contract,
              amount: fromRealTokenAmountBN
                .shiftedBy(this.swapInput.decimals)
                .decimalPlaces(0, BigNumber.ROUND_DOWN)
                .toFixed(),
              includeTokensInfo: true,
              includeGas: true,
              includeProtocols: true,
            };
            if (this.manualGasPrice) {
              getQuoteParams.gasPrice = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei").toString();
            }

            const quoteRes = await oneInchService.getQuote(this.chainId, getQuoteParams);
            if ( // make sure params haven't changed on ui
              displayInputAmount === this.swapInput.displayAmount &&
              quoteRes.srcToken.address.toLowerCase() === this.swapInput.contract.toLowerCase() &&
              quoteRes.dstToken.address.toLowerCase() === this.swapOutput.contract.toLowerCase()
            ) {
              const toRealTokenAmountBN = BigNumber(quoteRes.dstAmount).shiftedBy(-quoteRes.dstToken.decimals);
              inputPriceBN = toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN);
              this.swapInput.displayPriceOther = inputPriceBN.precision(6).toFixed();
              outputPriceBN = fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN);
              this.swapOutput.displayPriceOther = outputPriceBN.precision(6).toFixed();
              this.swapOutput.displayAmount = toRealTokenAmountBN.decimalPlaces(8).toFixed();
              this.estimateGas = quoteRes.gas;
            }

          } else if (this.aggregator === "paraswap") {
            const getQuoteParams: Record<string, any> = {
              srcToken: this.swapInput.contract,
              srcDecimals: this.swapInput.decimals,
              destToken: this.swapOutput.contract,
              destDecimals: this.swapOutput.decimals,
              amount: fromRealTokenAmountBN
                .shiftedBy(this.swapInput.decimals)
                .decimalPlaces(0, BigNumber.ROUND_DOWN)
                .toFixed(),
              side: "SELL",
              network: this.chainId,
              version: "6.2",
            };

            const quoteRes = await paraswapService.getQuote(getQuoteParams);
            if ( // make sure params haven't changed on ui
              displayInputAmount === this.swapInput.displayAmount &&
              quoteRes.srcToken.toLowerCase() === this.swapInput.contract.toLowerCase() &&
              quoteRes.destToken.toLowerCase() === this.swapOutput.contract.toLowerCase()
            ) {
              const toRealTokenAmountBN = BigNumber(quoteRes.destAmount).shiftedBy(-quoteRes.destDecimals);
              inputPriceBN = toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN);
              this.swapInput.displayPriceOther = inputPriceBN.precision(6).toFixed();
              outputPriceBN = fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN);
              this.swapOutput.displayPriceOther = outputPriceBN.precision(6).toFixed();
              this.swapOutput.displayAmount = toRealTokenAmountBN.decimalPlaces(8).toFixed();
              this.estimateGas = +quoteRes.gasCost;
              this.paraswapPriceRoute = quoteRes;
            }

          } else if (this.aggregator === "kyberSwap") {
            const route = await kyberSwapService.getRoute(this.chainId, utils.deleteNilProps({
              tokenIn: this.swapInput.contract,
              tokenOut: this.swapOutput.contract,
              amountIn: fromRealTokenAmountBN
                .shiftedBy(this.swapInput.decimals)
                .decimalPlaces(0, BigNumber.ROUND_DOWN)
                .toFixed(),
              gasInclude: true,
              gasPrice: this.manualGasPrice ? ethers.parseUnits(this.gasPriceGwei.toString(), "gwei").toString() : undefined,
            }));

            if ( // make sure params haven't changed on ui
              displayInputAmount === this.swapInput.displayAmount &&
              route.routeSummary.tokenIn.toLowerCase() === this.swapInput.contract.toLowerCase() &&
              route.routeSummary.tokenOut.toLowerCase() === this.swapOutput.contract.toLowerCase()
            ) {
              const toRealTokenAmountBN = BigNumber(route.routeSummary.amountOut).shiftedBy(-this.swapOutput.decimals);
              inputPriceBN = toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN);
              this.swapInput.displayPriceOther = inputPriceBN.precision(6).toFixed();
              outputPriceBN = fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN);
              this.swapOutput.displayPriceOther = outputPriceBN.precision(6).toFixed();
              this.swapOutput.displayAmount = toRealTokenAmountBN.decimalPlaces(8).toFixed();
              this.estimateGas = +route.routeSummary.gas;
              this.kyberSwapRoute = route;
            }
          }

        } catch (e) {
          this.quoteErrorMsg = e.response?.data?.description || e.response?.data?.error || e.message;
          console.error(e);

        } finally {
          this.isRequestingQuote = false;
        }

        clearTimeout(this.refreshQuoteTimeoutHandler);
        this.refreshQuoteTimeoutHandler = setTimeout(() => this.refreshQuote(), 5000);
      },

      async doSwap() {
        if (!this.walletAddress || this.walletAddress.toLowerCase() !== onboardComposableExtra.walletAddress.value?.toLowerCase()) {
          this.swapErrorMsg = "Wallet not connected";
          return;
        }

        if (onboardComposableExtra.chainId.value && onboardComposableExtra.chainId.value !== this.chainId) {
          web3Service.switchEvmChain(this.chainId);
          return;
        }

        if (this.destReceiver && !ethers.isAddress(this.destReceiver)) {
          this.swapErrorMsg = "Invalid receiver address";
          return;
        }

        try {
          this.isSwapping = true;
          this.swapErrorMsg = "";
          this.showApproveInfinite = false;
          this.showSetApproveToZero = false;
          this.sentTxExplorerUrl = "";

          let fromTokenAmountBN = BigNumber(this.swapInput.displayAmount)
            .shiftedBy(this.swapInput.decimals)
            .decimalPlaces(0, BigNumber.ROUND_DOWN);

          if (!fromTokenAmountBN.gt(0)) {
            throw new Error("Zero input amount");
          }

          const inputWalletBalanceBN = BigNumber(this.swapInput.walletBalance);
          if (inputWalletBalanceBN.gt(0)) {
            const divByBalance = fromTokenAmountBN.div(inputWalletBalanceBN);
            if (divByBalance.gt(1) && divByBalance.lt(1.0001)) {
              fromTokenAmountBN = inputWalletBalanceBN;
            }
          }

          const fromTokenAmount = fromTokenAmountBN.toFixed();
          const slippage = this.slippageTolerance || 1;

          try {
            customTokensService.setSwapDestReceiverByToken(this.chainId, this.swapOutput.contract, this.destReceiver);
          } catch (e) {
            console.error(e);
          }

          if (this.aggregator === "1inch") {
            const swapParams: Record<string, any> = {
              src: this.swapInput.contract,
              dst: this.swapOutput.contract,
              amount: fromTokenAmount,
              from: this.walletAddress,
              slippage,
              allowPartialFill: this.allowPartialFill,
              // referrer: constants.REFERRER_ADDRESS,
              includeTokensInfo: true,
              includeProtocols: true,
            };
            if (this.destReceiver) {
              swapParams.receiver = this.destReceiver;
            }
            if (this.manualGasPrice) {
              swapParams.gasPrice = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei").toString();
            }
            const displayedRealToTokenAmountBN = BigNumber(this.swapOutput.displayAmount);

            const res = await oneInchService.getSwap(this.chainId, swapParams);

            if (
              res.srcToken.address.toLowerCase() === this.swapInput.contract.toLowerCase() &&
              res.dstToken.address.toLowerCase() === this.swapOutput.contract.toLowerCase() &&
              res.srcAmount === fromTokenAmount
            ) {
              const fromRealTokenAmountBN = BigNumber(res.srcAmount).shiftedBy(-res.srcToken.decimals);
              const toRealTokenAmountBN = BigNumber(res.dstAmount).shiftedBy(-res.dstToken.decimals);

              if (
                displayedRealToTokenAmountBN.gt(0) &&
                toRealTokenAmountBN.div(displayedRealToTokenAmountBN).negated().multipliedBy(100).gt(this.slippageTolerance)
              ) {
                throw new Error("estimated output too low");
              }

              this.reviewInfo = {
                displayPrice: toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN).precision(6).toFixed(),
                displayInversePrice: fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN).precision(6).toFixed(),
                displayInputAmount: fromRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayOutputAmount: toRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayMinimumReceiveAmount: toRealTokenAmountBN.multipliedBy(1 - slippage / 100).decimalPlaces(8).toFixed(),
                fromTokenSymbol: res.srcToken.symbol,
                toTokenSymbol: res.dstToken.symbol,
              };

              const sendingTx: TransactionRequest = {
                chainId: this.chainId,
                from: res.tx.from,
                to: res.tx.to,
                data: res.tx.data,
                value: res.tx.value,
                gasLimit: BigNumber(res.tx.gas).multipliedBy(1.25).decimalPlaces(0).toNumber(),
              };

              if (this.manualGasPrice) {
                const gasPriceWei = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei");
                sendingTx.type = 0;
                sendingTx.gasPrice = gasPriceWei;
              }

              const signer = await web3Service.getBrowserProvider().getSigner();
              const tx = await signer.sendTransaction(sendingTx);

              /*
              {
                  "hash": "0xb456b3e6ebac1db71e4977242158bafe48ba71d3f6517c4a3ad62fb1c63fdf56",
                  "type": 0,
                  "accessList": null,
                  "blockHash": null,
                  "blockNumber": null,
                  "transactionIndex": null,
                  "confirmations": 0,
                  "from": "0x0000000000CcC74A6F2180410D044439a40356e4",
                  "gasPrice": {
                      "type": "BigNumber",
                      "hex": "0x0737be7600"
                  },
                  "gasLimit": {
                      "type": "BigNumber",
                      "hex": "0x052be8"
                  },
                  "to": "0x1111111254fb6c44bAC0beD2854e76F90643097d",
                  "value": {
                      "type": "BigNumber",
                      "hex": "0x016345785d8a0000"
                  },
                  "nonce": 5,
                  "data": "0x7c02520000000000000000000000000013927a60c7bf4d3d00e3c1593e0ec713e35d210600000000000000",
                  "r": "0x27b45165c01139661a572cf09c0fa84189832ba6ba65c9f568c169dd8e3c6206",
                  "s": "0x774c173d408eb3468c8416f58e94bf616e26566ecc3b601a2b04564d6d27480e",
                  "v": 310,
                  "creates": null,
                  "chainId": 137
              }
               */
              this.sentTxNonce = tx.nonce;
              this.sentTxExplorerUrl = utils.getExplorerUrl(this.chainId, "tx", tx.hash);
              console.log("sent transaction", tx);
            }

          } else if (this.aggregator === "paraswap") {
            if (
              !this.paraswapPriceRoute ||
              this.swapInput.contract.toLowerCase() !== this.paraswapPriceRoute.srcToken.toLowerCase() ||
              this.swapOutput.contract.toLowerCase() !== this.paraswapPriceRoute.destToken.toLowerCase() ||
              fromTokenAmount !== this.paraswapPriceRoute.srcAmount
            ) {
              await this.refreshQuote();
            }

            if (
              this.paraswapPriceRoute &&
              this.swapInput.contract.toLowerCase() === this.paraswapPriceRoute.srcToken.toLowerCase() &&
              this.swapOutput.contract.toLowerCase() === this.paraswapPriceRoute.destToken.toLowerCase() &&
              fromTokenAmount === this.paraswapPriceRoute.srcAmount
            ) {
              const priceRoute = utils.jsonClone(this.paraswapPriceRoute);
              const swapParams: Record<string, any> = {
                network: this.chainId,
                srcToken: this.swapInput.contract,
                srcDecimals: this.swapInput.decimals,
                destToken: this.swapOutput.contract,
                destDecimals: this.swapOutput.decimals,
                srcAmount: fromTokenAmount,
                priceRoute,
                slippage: BigNumber(slippage).multipliedBy(100).toNumber(),
                userAddress: this.walletAddress,
                partnerAddress: constants.REFERRER_ADDRESS,
                partnerFeeBps: 0,
                isCapSurplus: false,
                takeSurplus: true,
                isSurplusToUser: true,
                isDirectFeeTransfer: true
              };
              if (this.destReceiver) {
                swapParams.receiver = this.destReceiver;
              }
              if (this.manualGasPrice) {
                swapParams.gasPrice = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei").toString();
              }

              const res = await paraswapService.buildTransaction(swapParams);

              const fromRealTokenAmountBN = BigNumber(priceRoute.srcAmount).shiftedBy(-priceRoute.srcDecimals);
              const toRealTokenAmountBN = BigNumber(priceRoute.destAmount).shiftedBy(-priceRoute.destDecimals);

              this.reviewInfo = {
                displayPrice: toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN).precision(6).toFixed(),
                displayInversePrice: fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN).precision(6).toFixed(),
                displayInputAmount: fromRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayOutputAmount: toRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayMinimumReceiveAmount: toRealTokenAmountBN.multipliedBy(1 - slippage / 100).decimalPlaces(8).toFixed(),
                fromTokenSymbol: this.swapInput.symbol,
                toTokenSymbol: this.swapOutput.symbol,
              };

              const sendingTx: TransactionRequest = {
                chainId: this.chainId,
                from: res.from,
                to: res.to,
                data: res.data,
                value: res.value,
                gasLimit: BigNumber(res.gas).multipliedBy(1.25).decimalPlaces(0).toNumber(),
              };

              if (this.manualGasPrice) {
                const gasPriceWei = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei");
                sendingTx.type = 0;
                sendingTx.gasPrice = gasPriceWei;
              }

              const signer = await web3Service.getBrowserProvider().getSigner();
              const tx = await signer.sendTransaction(sendingTx);

              this.sentTxNonce = tx.nonce;
              this.sentTxExplorerUrl = utils.getExplorerUrl(this.chainId, "tx", tx.hash);
              console.log("sent transaction", tx);
            }

          } else if (this.aggregator === "kyberSwap") {
            if (
              !this.kyberSwapRoute ||
              this.swapInput.contract.toLowerCase() !== this.kyberSwapRoute.routeSummary.tokenIn.toLowerCase() ||
              this.swapOutput.contract.toLowerCase() !== this.kyberSwapRoute.routeSummary.tokenOut.toLowerCase() ||
              fromTokenAmount !== this.kyberSwapRoute.routeSummary.amountIn
            ) {
              await this.refreshQuote();
            }

            if (
              this.kyberSwapRoute &&
              this.swapInput.contract.toLowerCase() === this.kyberSwapRoute.routeSummary.tokenIn.toLowerCase() &&
              this.swapOutput.contract.toLowerCase() === this.kyberSwapRoute.routeSummary.tokenOut.toLowerCase() &&
              fromTokenAmount === this.kyberSwapRoute.routeSummary.amountIn
            ) {
              const res = await kyberSwapService.buildTransaction(this.chainId, {
                routeSummary: this.kyberSwapRoute.routeSummary,
                sender: this.walletAddress,
                recipient: this.destReceiver || this.walletAddress,
                slippageTolerance: BigNumber(slippage).multipliedBy(100).toNumber(),
                referral: constants.REFERRER_ADDRESS,
                enableGasEstimation: true,
              });

              const fromRealTokenAmountBN = BigNumber(res.amountIn).shiftedBy(-this.swapInput.decimals);
              const toRealTokenAmountBN = BigNumber(res.amountOut).shiftedBy(-this.swapOutput.decimals);

              this.reviewInfo = {
                displayPrice: toRealTokenAmountBN.dividedBy(fromRealTokenAmountBN).precision(6).toFixed(),
                displayInversePrice: fromRealTokenAmountBN.dividedBy(toRealTokenAmountBN).precision(6).toFixed(),
                displayInputAmount: fromRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayOutputAmount: toRealTokenAmountBN.decimalPlaces(8).toFixed(),
                displayMinimumReceiveAmount: toRealTokenAmountBN.multipliedBy(1 - slippage / 100).decimalPlaces(8).toFixed(),
                fromTokenSymbol: this.swapInput.symbol,
                toTokenSymbol: this.swapOutput.symbol,
              };

              const sendingTx: TransactionRequest = {
                chainId: this.chainId,
                from: this.walletAddress,
                to: res.routerAddress,
                data: res.data,
                value: res.transactionValue,
                gasLimit: BigNumber(res.gas).multipliedBy(1.25).decimalPlaces(0).toNumber(),
              };

              if (this.manualGasPrice) {
                const gasPriceWei = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei");
                sendingTx.type = 0;
                sendingTx.gasPrice = gasPriceWei;
              }

              const signer = await web3Service.getBrowserProvider().getSigner();
              const tx = await signer.sendTransaction(sendingTx);

              this.sentTxNonce = tx.nonce;
              this.sentTxExplorerUrl = utils.getExplorerUrl(this.chainId, "tx", tx.hash);
              console.log("sent transaction", tx);
            }
          }

        } catch (e) {
          console.error(e);

          if (this.aggregator == "1inch") {
            this.swapErrorMsg = e.response?.data?.description || e.message;
            /*
            [
              { "type": "amount", "value": "5943459" },
              { "type": "allowance", "value": "0" }
            ]
             */
            const meta = e.response?.data?.meta;
            if (Array.isArray(meta)) {
              const metaTypes = meta.map(it => it.type);
              if (metaTypes.includes("amount") && metaTypes.includes("allowance")) {
                this.showApproveInfinite = true;
                // const amountBN = BigNumber(meta.find(it => it.type === "amount").value);
                const allowanceBN = BigNumber(meta.find(it => it.type === "allowance").value);
                this.showSetApproveToZero = allowanceBN.gt(0);
              }
            }

          } else if (this.aggregator === "paraswap") {
            this.swapErrorMsg = e.response?.data?.error || e.message;
            if (this.swapErrorMsg.includes("allowance")) {
              this.showApproveInfinite = true;
              this.showSetApproveToZero = true;
            }

          } else if (this.aggregator === "kyberSwap") {
            const errorMsg = e.response?.data?.message || e.message;
            if (errorMsg.includes("TRANSFER_FROM_FAILED")) {
              const { balance, allowance } = (await web3Service.getBalancesAndAllowances(
                this.chainId, this.walletAddress, this.kyberSwapRoute.routerAddress, [this.swapInput.contract]
              ))[this.swapInput.contract.toLowerCase()];
              console.log("balance", balance);
              console.log("allowance", allowance);
              console.log("amountIn", this.kyberSwapRoute.routeSummary.amountIn);

              const amountInBN = BigNumber(this.kyberSwapRoute.routeSummary.amountIn);
              if (amountInBN.gt(balance.toString())) {
                this.swapErrorMsg = "Not enough balance for " + this.swapInput.symbol;

              } else if (amountInBN.gt(allowance.toString())) {
                this.swapErrorMsg = "Not enough allowance for " + this.swapInput.symbol;
                this.showApproveInfinite = true;
                this.showSetApproveToZero = allowance > 0;
              }

            } else {
              this.swapErrorMsg = errorMsg;
            }
          }

        } finally {
          this.isSwapping = false;
          this.reviewInfo = null;
        }
      },

      async onClickApprove() {
        try {
          this.isApproving = true;

          const signer = await web3Service.getBrowserProvider().getSigner();

          if (this.aggregator === "1inch") {
            const res = await oneInchService.getApproveTransaction(this.chainId, this.swapInput.contract);
            const sendingTx: TransactionRequest = {
              to: res.to,
              data: res.data,
            };

            const gasPriceWei = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei");
            if (this.manualGasPrice) {
              sendingTx.type = 0;
              sendingTx.gasPrice = gasPriceWei;
            }

            const tx = await signer.sendTransaction(sendingTx);

          } else if (this.aggregator === "paraswap") {
            if (this.paraswapPriceRoute) {
              const contract = new ethers.Contract(this.swapInput.contract, erc20Abi, signer);
              await contract.approve(this.paraswapPriceRoute.tokenTransferProxy, ethers.MaxUint256);
            }

          } else if (this.aggregator === "kyberSwap") {
            if (this.kyberSwapRoute) {
              const contract = new ethers.Contract(this.swapInput.contract, erc20Abi, signer);
              await contract.approve(this.kyberSwapRoute.routerAddress, ethers.MaxUint256);
            }
          }

          this.swapErrorMsg = "";
          this.showApproveInfinite = false;
          this.showSetApproveToZero = false;

        } catch (e) {
          console.error(e);

        } finally {
          this.isApproving = false;
        }
      },
      async onClickRevoke() {
        try {
          this.isApproving = true;

          const signer = await web3Service.getBrowserProvider().getSigner();

          if (this.aggregator === "1inch") {
            const res = await oneInchService.getApproveTransaction(this.chainId, this.swapInput.contract, true);

            const sendingTx: TransactionRequest = {
              to: res.to,
              data: res.data,
            };

            const gasPriceWei = ethers.parseUnits(this.gasPriceGwei.toString(), "gwei");

            if (this.manualGasPrice) {
              sendingTx.type = 0;
              sendingTx.gasPrice = gasPriceWei;
            }

            const tx = await signer.sendTransaction(sendingTx);

          } else if (this.aggregator === "paraswap") {
            if (this.paraswapPriceRoute) {
              const contract = new ethers.Contract(this.swapInput.contract, erc20Abi, signer);
              await contract.approve(this.paraswapPriceRoute.tokenTransferProxy, 0);
            }

          } else if (this.aggregator === "kyberSwap") {
            if (this.kyberSwapRoute) {
              const contract = new ethers.Contract(this.swapInput.contract, erc20Abi, signer);
              await contract.approve(this.kyberSwapRoute.routerAddress, 0);
            }
          }

          this.swapErrorMsg = "";
          this.showApproveInfinite = false;
          this.showSetApproveToZero = false;

        } catch (e) {

        } finally {
          this.isApproving = false;
        }
      }
    },

    unmounted() {
      clearTimeout(this.reloadTimeoutHandler);
      clearTimeout(this.refreshSwapTokensAndBalancesTimeoutHandler);
      clearTimeout(this.refreshQuoteTimeoutHandler);
      clearTimeout(this.refreshWalletTokenBalancesTimeoutHandler);
      this.isDestroyed = true;
    }
  };
</script>
